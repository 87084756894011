import React from 'react';
import Tooltip from '../Dialog/ToolTip';
import PropTypes from 'prop-types';

const SharePriceTooltip = ({ children }) => {
  return (
    <Tooltip title="Share value is the number of shares you own multiplied by the market price of one share">
      {children}
    </Tooltip>
  );
};

SharePriceTooltip.propTypes = {
  children: PropTypes.any,
};

export default SharePriceTooltip;
