import {
  Add,
  CreditCard,
  FileUploadOutlined,
  Help,
  Settings,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  Unstable_Grid2 as Grid2,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeAppBar, WEProfileAppBar } from '../../components/AppBar';
import { BsUpdatesList } from '../../components/BsUpdates';
import { WEButton } from '../../components/Button';
import WELabelButton from '../../components/Button/WELabelButton';
import MembersAnsTreasuresSection from '../../components/Dashboard/MembersAnsTreasuresSection';
import MyShares from '../../components/Dashboard/MyShares';
import {
  AdminDetailModal,
  JoinModal,
  ProposalDetailModal,
  ToolTip,
  TransactionDetailModal,
} from '../../components/Dialog';
import CommentModal from '../../components/Dialog/CommentModal';
import DashboardCard from '../../components/Dialog/DashboardCard';
import { ForumList } from '../../components/Forum';
import Header from '../../components/Header';
import { useNotification } from '../../components/Notification/hook';
import ProposalContent from '../../components/UserDashboard/ProposalContent';
import { PROPOSAL_STATUS_ACTIVE } from '../../constants/ProposalStatus';
import EnterpriseABI from '../../constants/abi/enterprise.json';
import EnterpriseDevABI from '../../constants/abi/enterprise_dev.json';
import store from '../../constants/store';
import {
  EmptyProposal,
  EmptyVote,
  STEP,
  STORE_KEYS,
  TXTYPE,
} from '../../constants/store/constant';
import { useEnterprise } from '../../hook/weEnterprise';
import { useWeb3 } from '../../hook/web3';
import { useAutoConnect } from '../../utils/AutoConnect';
import { updateEnterprise } from '../../utils/enterprise';
import { convertMaticToUSD, getEtherFromWei } from '../../utils/unit';

const DashboardPage = () => {
  const { displaySuccess, displayError } = useNotification();
  const { account } = useAutoConnect();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [weBack, setWeBack] = useState();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [step, setStep] = store.useState(STORE_KEYS.id.step); //eslint-disable-line no-unused-vars
  const web3 = useWeb3();
  console.log('-- dash temp', enterprises.tempEnterprise);
  const [enterprise, setEnterprise] = useState(enterprises.tempEnterprise);
  const enterpriseHandler = useEnterprise(enterprise.address);
  const [logo, setLogo] = useState('');
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const [name, setName] = useState('');
  const [ID, setID] = useState('');
  const [description, setDescription] = useState('');
  const [treasuryUSD, setTreasuryUSD] = useState(0);
  const [treasuryMatic, setTreasuryMatic] = useState(0);
  const [myShare, setMyShare] = useState(0);
  const [sharePrice, setSharePrice] = useState(enterprise.info.price);
  const [totalShare, setTotalShare] = useState(0);
  const [totalMembers, setTotalMembers] = useState(22);
  const [pTab, setPTab] = useState(state.pTab ? state.pTab : 'shareholders');
  const [admins, setAdmins] = useState(enterprise.admins);
  const [category, setCategory] = useState(
    pTab == 'admins' ? 'admins' : 'shareholders'
  );
  const [proposals, setProposals] = useState(enterprise.proposals);
  const [numMem, setNumMem] = useState(0);
  const [dlgOpened, setDlgOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEnterprise(enterprises.tempEnterprise);
  }, [enterprises.tempEnterprise]);

  useEffect(
    () => {
      if (enterprise.admins.length === 0) {
        const res = JSON.parse(localStorage.getItem('enterprise'));
        console.log('res', res);
        setEnterprise(res);
        updateEnterprises((prev) => {
          prev.tempEnterprise = res;
        });
        setNumMem(enterprise.info.memNum);
        // setEnterprise()
      } else {
        localStorage.setItem(
          'enterprise',
          JSON.stringify(enterprises.tempEnterprise)
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      setStep(STEP.INDEX);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (state !== null) {
        setWeBack(state.weBack);
        setPTab(state?.pTab == 'admins' ? 'admins' : 'shareholders');
        setCategory(state?.pTab == 'admins' ? 'admins' : 'shareholders');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setLogo(enterprise.info.logo);
    setBackgroundUrl(enterprise.info.backgroundUrl);
    setName(enterprise.info.name);
    setDescription(enterprise.info.description);
    setProposals(enterprise.proposals);
    if (enterprise.address) {
      setID(enterprise.address.toLowerCase());
    }
    setSharePrice(enterprise.info.price);
    setAdmins(enterprise.admins);
    setTotalMembers(
      enterprise.shareholders ? enterprise.shareholders.length : 0
    );
    setTotalShare(enterprise.info.totalShare);
    setNumMem(enterprise.info.memNum);
    if (enterprise.isShareholder) {
      const _shareAmountEther = getEtherFromWei(enterprise?.numOfShare);
      setMyShare(_shareAmountEther);
    }
  }, [enterprise]);

  const isMember = useMemo(() => {
    const { admins, shareholders } = enterprise;
    let isCurrentUserMeber = false;

    admins.forEach((admin) => {
      if (admin.walletAddr.toUpperCase() === account.toUpperCase()) {
        isCurrentUserMeber = true;
      }
    });

    shareholders.forEach((shareholder) => {
      if (shareholder.walletAddr.toUpperCase() === account.toUpperCase()) {
        isCurrentUserMeber = true;
      }
    });

    return isCurrentUserMeber;
  }, [enterprise, account]);

  const isAdmin = useMemo(() => {
    const { admins } = enterprise;
    let isCurrentUserAdmin = false;

    admins.forEach((admin) => {
      if (admin.walletAddr.toUpperCase() === account.toUpperCase()) {
        isCurrentUserAdmin = true;
      }
    });

    return isCurrentUserAdmin;
  }, [enterprise, account]);

  // open enterprise modal
  const openModalJoin = () => {
    if (enterprise) {
      setDlgOpened(true);
    }
  };
  const joinWE = async (enterprise) => {
    updateEnterprises((prev) => {
      // prev.enterprises[idx] = enterprise;
      prev.tempEnterprise = enterprise;
      return prev;
    });
    navigate(`/wepID/dashboard`, {
      state: { weBack: `/user/${account}` },
    });
  };
  const requestHandler =
    () =>
    ({ comment }) => {
      // create a new proposal and add it
      // let enterprise = { ...enterprises.enterprises[idx] };
      const joinProposal = EmptyProposal({
        id: Date.now(),
        type: 'join',
        votesYes: 0,
        votesNo: 0,
        walletAddr: account || '',
        name: '',
        isApproved: false,
        comment: comment,
      });
      enterprise.proposals = [...enterprise.proposals, joinProposal];
      // join
      setTimeout(() => {
        joinWE(enterprise, false);
      }, 3000);
    };
  // end enterprise modal

  useEffect(
    () => {
      if (!enterprise.address || enterprise?.proposalFetched) {
        return;
      }
      // proposals fetched from join-world-enterprise
      setIsLoading(true);
      updateEnterprise(enterprise, account, updateEnterprises);
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enterprise.address, enterprise.orders]
  );

  useEffect(() => {
    if (!enterprise.address || !web3) {
      return;
    }
    (async () => {
      try {
        const res = await web3.eth.getBalance(enterprise.address.toLowerCase());
        const enterpriseHandler = new web3.eth.Contract(
          process.env.REACT_APP_ENV === 'prod'
            ? EnterpriseABI
            : EnterpriseDevABI,
          enterprise.address
        );
        const orderAmount = await enterpriseHandler.methods.orderBook().call();
        let treasuryMatic = getEtherFromWei(res) - getEtherFromWei(orderAmount);
        setTreasuryMatic(treasuryMatic);
        let treasuryUSD = await convertMaticToUSD(treasuryMatic);
        setTreasuryUSD(treasuryUSD);
      } catch (e) {
        console.error(e); //eslint-disable-line no-console
      }
    })();
  }, [enterprise.address, web3]);

  const backHandle = () => {
    escapeHandle();
    if (account && enterprises.registeredUser) {
      navigate(`/user/${account}`);
    }
  };

  const escapeHandle = () => {
    updateEnterprises((prev) => {
      const index = prev.enterprises.findIndex(
        (enterprise) => enterprise.info.wepID === prev.tempEnterprise.info.wepID
      );
      if (index >= 0) {
        prev.enterprises[index] = prev.tempEnterprise;
        return prev;
      }
    });
  };

  const setting = () => {
    navigate(`/wepID/setting`, {
      state: { weBack, wepID: enterprise.info.wepID },
    });
    setStep(STEP.SETTING_COMPANY_REVIEW);
  };

  const send = () => {
    navigate(`/wepID/send`, {
      state: { weBack, pTab, moneyFlag: TXTYPE.MONEY_OUT, isAdmin },
    });
  };

  const receive = () => {
    navigate(`/wepID/send`, {
      state: { weBack, pTab, moneyFlag: TXTYPE.MONEY_IN, isAdmin },
    });
  };

  const trade = (tabId) => {
    setStep(STEP.DASHBOARD_TRADE_ADD);
    navigate(`/wepID/trades`, { state: { weBack, tabId } });
  };

  const allMembers = (pTab) => {
    setStep(STEP.DASHBOARD_MEMBERS);
    navigate(`/wepID/members`, { state: { weBack, pTab } });
  };

  const businessUpdatesAll = (pTab) => {
    navigate(`/wepID/businessUpdates`, { state: { weBack, pTab } });
  };

  const forumAll = (pTab) => {
    navigate(`/wepID/forum`, { state: { weBack, pTab } });
  };

  const proposalsAll = (pTab) => () => {
    navigate(`/wepID/proposals`, { state: { weBack, pTab } });
  };

  const trxAll = (pTab) => {
    navigate(`/wepID/transactions`, { state: { weBack, pTab } });
  };

  const goChart = (pTab = 'shareholder') => {
    navigate(`/wepID/chart`, { state: { weBack, pTab } });
  };
  const trxClickHandler = (idx) => {
    setTrxIdx(idx);
    setTrxDlgOpen(true);
  };

  // Admin View Dialog
  const [adminDlgOpen, setAdminDlgOpen] = useState(false);
  const [adminIdx] = useState(-1);

  const adminReplaceHandler = (idx) => {
    updateEnterprises((prev) => {
      prev.toEditAdmin = idx;
      return prev;
    });
    setStep(STEP.DASHBOARD_ADMIN_EDIT);
    navigate(`/wepID/admins`, { state: { weBack } });
    setAdminDlgOpen(false);
  };
  const adminRemoveHandler = (idx) => () => {
    updateEnterprises((prev) => {
      prev.tempEnterprise.admins.splice(idx, 1);
      return prev;
    });
    setAdminDlgOpen(false);
  };

  // Proposal View Dialog
  const [proposalDlgOpen, setProposalDlgOpen] = useState(false);
  const [commentDlgOpen, setCommentDlgOpen] = useState(false);
  const [proposalIdx] = useState(-1);

  const proposalReject = async () => {
    if (enterprise?.proposals[proposalIdx]?.isApproved) {
      return setProposalDlgOpen(false);
    }

    if (
      enterprise.proposals[proposalIdx]?.type !== 'MINT' &&
      enterprise.proposals[proposalIdx]?.walletAddr.toLowerCase() ===
        account.toLowerCase()
    ) {
      return displayError({
        message: "Proposal owner can't vote for this proposal",
        reason: '',
        timeout: 5000,
      });
    }

    try {
      await enterpriseHandler.methods
        .vote(enterprise.proposals[proposalIdx].idx, false)
        .send({
          from: account,
          gasLimit: web3.eth.getBlock('latest').gasLimit,
        });
      setProposalDlgOpen(false);
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.address === enterprise.address
        );
        const vote = EmptyVote('NO');
        prev.enterprises[index].proposals[proposalIdx].isApproved = true;
        prev.enterprises[index].proposals[proposalIdx].votesNo += 1;
        prev.enterprises[index].proposals[proposalIdx].votes[
          account.toUpperCase()
        ] = vote;
        prev.tempEnterprise.proposals[proposalIdx].isApproved = true;
        prev.tempEnterprise.proposals[proposalIdx].votesNo += 1;
        prev.tempEnterprise.proposals[proposalIdx].votes[
          account.toUpperCase()
        ] = vote;
        console.log('temp reject', prev.tempEnterprise);
        localStorage.setItem('enterprise', JSON.stringify(prev.tempEnterprise));
        return prev;
      });
      displaySuccess({ message: 'Proposal rejected', timeout: 5000 });
    } catch (e) {
      displayError({ message: 'There is an error to reject', timeout: 5000 });
    }
  };

  const proposalAgree = async () => {
    if (enterprise?.proposals[proposalIdx]?.isApproved) {
      return;
    }
    if (
      enterprise.proposals[proposalIdx]?.type !== 'MINT' &&
      enterprise.proposals[proposalIdx]?.walletAddr.toLowerCase() ===
        account.toLowerCase()
    ) {
      return displayError({
        message: "Proposal owner can't vote for this proposal",
        reason: '',
        timeout: 5000,
      });
    }

    try {
      await enterpriseHandler.methods
        .vote(enterprise.proposals[proposalIdx].idx, true)
        .send({
          from: account,
          gasLimit: web3.eth.getBlock('latest').gasLimit,
        });
      setProposalDlgOpen(false);
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.address === enterprise.address
        );
        const vote = EmptyVote('YES');
        prev.enterprises[index].proposals[proposalIdx].isApproved = true;
        prev.enterprises[index].proposals[proposalIdx].votesYes += 1;
        prev.enterprises[index].proposals[proposalIdx].votes[
          account.toUpperCase()
        ] = vote;
        prev.tempEnterprise.proposals[proposalIdx].isApproved = true;
        prev.tempEnterprise.proposals[proposalIdx].votesYes += 1;
        prev.tempEnterprise.proposals[proposalIdx].votes[
          account.toUpperCase()
        ] = vote;
        console.log('temp agree', prev.tempEnterprise);
        localStorage.setItem('enterprise', JSON.stringify(prev.tempEnterprise));
        return prev;
      });
      displaySuccess({ message: 'Proposal accepted', timeout: 5000 });
    } catch (e) {
      displayError({ message: 'There is an error to agree', timeout: 5000 });
    }
  };

  const proposalExecute = async () => {
    try {
      await enterpriseHandler.methods
        .execute(enterprise.proposals[proposalIdx].idx)
        .send({
          from: account,
          gasLimit: web3.eth.getBlock('latest').gasLimit,
        });
      setProposalDlgOpen(false);
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.address === enterprise.address
        );
        prev.enterprises[index].proposals[proposalIdx].status = 'EXECUTED';
        return prev;
      });
      displaySuccess({ message: 'Proposal executed', timeout: 5000 });
    } catch (e) {
      displayError({ message: 'There is an error to execute', timeout: 5000 });
    }
  };

  // Transaction View Dialog
  const [trxDlgOpen, setTrxDlgOpen] = useState(false);
  const [trxIdx, setTrxIdx] = useState(-1);
  const trxReject = () => {
    setTrxDlgOpen(false);
  };
  const trxAgree = () => {
    setTrxDlgOpen(false);
  };

  const switchHandler = (category) => {
    switch (category) {
      case 'admins':
        setCategory('admins');
        setPTab('admins');
        break;
      case 'shareholders':
        setCategory('shareholders');
        setPTab('shareholders');
        break;
      default:
        break;
    }
  };

  const seeProposal = (proposalID) => {
    console.log(proposalID, pTab);
    navigate(`/wepID/proposals`, { state: { weBack, pTab, proposalID } });
  };

  return (
    <>
      <Header pageTitle={enterprise.info.name} />
      <HomeAppBar position="absolute" />
      <WEProfileAppBar
        isAdmin={isAdmin}
        logo={logo}
        backgroundUrl={backgroundUrl}
        name={name}
        description={description}
        ID={ID || ''}
        back={backHandle}
        SecondIcon={Settings}
        secondAction={setting}
      />
      {isMember ? (
        <Box component="main" sx={{ backgroundColor: '#F2F2F2' }}>
          <Grid2 p={2} component="main">
            <Grid2
              display="flex"
              container
              sx={{
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '20px',
                mb: 2,
                columnGap: '30px',
              }}
            >
              <Grid2
                onClick={() => switchHandler('shareholders')}
                sx={{ cursor: 'pointer' }}
              >
                <Typography
                  color={category === 'shareholders' ? '#28282B' : '#FF6142'}
                  sx={{
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                    textDecoration:
                      category !== 'shareholders' ? 'none' : 'underline',
                    textUnderlineOffset: '5px',
                  }}
                >
                  Shareholder
                </Typography>
              </Grid2>
              {isAdmin && (
                <Grid2
                  onClick={() => switchHandler('admins')}
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography
                    color={category === 'shareholders' ? '#FF6142' : '#28282B'}
                    sx={{
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '20px',
                      textDecoration:
                        category === 'shareholders' ? 'none' : 'underline',
                      textUnderlineOffset: '5px',
                    }}
                  >
                    Admin
                  </Typography>
                </Grid2>
              )}
            </Grid2>
            {category === 'admins' ? (
              <Grid2
                display="flex"
                flexDirection="column"
                sx={{ rowGap: '12px' }}
              >
                <DashboardCard title="Treasury">
                  <TreasuryAdmin
                    treasuryUSD={treasuryUSD}
                    treasuryMatic={treasuryMatic}
                    send={send}
                    receive={receive}
                  />
                </DashboardCard>
                {(() => {
                  let proposalsToExe = enterprise?.proposals.filter((p) => {
                    if (
                      p.status === PROPOSAL_STATUS_ACTIVE &&
                      (p.votesYes * 2 >= enterprise?.info?.memNum ||
                        p.votesNo * 2 >= enterprise?.info?.memNum)
                    ) {
                      return p;
                    }
                    if (
                      p.status === PROPOSAL_STATUS_ACTIVE &&
                      Date.now() * 0.001 >= p.startTime + 604800 // check if current time is two weeks after created date
                    )
                      return p;
                  });
                  return (
                    <DashboardCard
                      title={'Proposals to be executed'}
                      tooltip="Proposals are created by shareholders and voted on by shareholders. Approved proposals will modify the Enterprise in some way."
                      seeAll={proposalsAll(pTab)}
                      seeFlag={proposalsToExe.length ? true : false}
                    >
                      <Section //eslint-disable-line react/no-children-prop
                        key="proposals"
                        isLoading={isLoading}
                      >
                        {proposalsToExe && proposalsToExe.length ? (
                          <ProposalContent
                            proposals={proposalsToExe}
                            totalMembers={totalMembers}
                            tokenName={enterprise.info?.tokenName}
                            see={seeProposal}
                          />
                        ) : (
                          <Typography variant="secondary" color="#6F7287">
                            There are no proposals to be executed yet.
                          </Typography>
                        )}
                      </Section>
                    </DashboardCard>
                  );
                })()}
                <DashboardCard title="Recent Transactions">
                  <Section //eslint-disable-line react/no-children-prop
                    key="trx"
                    title="Recent Transactions"
                    seeAll={() => trxAll('admins')}
                    sx={{ overflow: 'auto', py: 2, backgroundColor: 'white' }}
                    isLoading={isLoading}
                  >
                    {enterprise.transactions &&
                    enterprise.transactions.length ? (
                      <TransactionList
                        transactions={enterprise.transactions}
                        onClickItem={trxClickHandler}
                        limit={5}
                        account={account}
                        enterprise={enterprise}
                      />
                    ) : (
                      <Typography variant="secondary" color="#6F7287">
                        There are no transaction yet! Check back soon.
                      </Typography>
                    )}
                  </Section>
                </DashboardCard>
                <DashboardCard
                  title="Business Updates"
                  seeAll={() => businessUpdatesAll('admins')}
                  seeFlag
                >
                  <Section
                    key="trx"
                    sx={{ overflow: 'auto', py: 2, backgroundColor: 'white' }}
                    isLoading={isLoading}
                  >
                    {enterprise.businessUpdates &&
                    enterprise.businessUpdates.length ? (
                      <BsUpdatesList isUserDash={true} />
                    ) : (
                      <Typography variant="secondary" color="#6F7287">
                        No new updates. You are up to date.
                      </Typography>
                    )}
                  </Section>
                </DashboardCard>
              </Grid2>
            ) : null}
            {category === 'shareholders' ? (
              <Grid2
                display="flex"
                flexDirection="column"
                sx={{ rowGap: '12px' }}
              >
                <DashboardCard title="My Shares">
                  <MyShares
                    myShare={myShare}
                    enterprise={enterprise}
                    trade={trade}
                    goChart={goChart}
                    isMember={isMember}
                  />
                </DashboardCard>
                <DashboardCard
                  title="Members & Treasury"
                  seeFlag
                  seeAll={allMembers}
                >
                  <MembersAnsTreasuresSection
                    enterprise={enterprise}
                    totalShare={totalShare}
                    treasuryUSD={treasuryUSD}
                  />
                </DashboardCard>
                <DashboardCard
                  title="Business Updates"
                  seeAll={() => businessUpdatesAll('shareholders')}
                  seeFlag
                >
                  <Section
                    key="trx"
                    sx={{ overflow: 'auto', py: 2, backgroundColor: 'white' }}
                    isLoading={isLoading}
                  >
                    {enterprise.businessUpdates &&
                    enterprise.businessUpdates.length ? (
                      <BsUpdatesList isUserDash={true} />
                    ) : (
                      <Typography variant="secondary" color="#6F7287">
                        No new updates. You are up to date.
                      </Typography>
                    )}
                  </Section>
                </DashboardCard>
                <DashboardCard
                  title="Forum"
                  seeAll={() => forumAll('shareholders')}
                  seeFlag
                >
                  <Section
                    key="trx"
                    sx={{ overflow: 'auto', py: 2, backgroundColor: 'white' }}
                    isLoading={isLoading}
                  >
                    {enterprise.forums && enterprise.forums.length ? (
                      <ForumList />
                    ) : (
                      <Typography variant="secondary" color="#6F7287">
                        No topics
                      </Typography>
                    )}
                  </Section>
                </DashboardCard>
                <DashboardCard
                  title={'Proposals'}
                  tooltip="Proposals are created by shareholders and voted on by shareholders. Approved proposals will modify the Enterprise in some way."
                  seeAll={proposalsAll(pTab)}
                  seeFlag={
                    enterprise?.proposals && enterprise?.proposals?.length
                      ? true
                      : false
                  }
                >
                  <Section //eslint-disable-line react/no-children-prop
                    key="proposals"
                    isLoading={isLoading}
                  >
                    {enterprise?.proposals && enterprise?.proposals?.length ? (
                      <ProposalContent
                        proposals={proposals}
                        totalMembers={totalMembers}
                        tokenName={enterprise.info?.tokenName}
                        see={seeProposal}
                      />
                    ) : (
                      <Typography variant="secondary" color="#6F7287">
                        There are no proposals yet.
                      </Typography>
                    )}
                  </Section>
                </DashboardCard>
                <DashboardCard
                  title="Recent Transactions"
                  seeFlag={
                    enterprise.transactions && enterprise.transactions.length
                      ? true
                      : false
                  }
                  seeAll={trxAll}
                >
                  <Section
                    key="trx"
                    sx={{ overflow: 'auto', py: 2, px: '11px' }}
                    isLoading={isLoading}
                  >
                    {enterprise.transactions &&
                    enterprise.transactions.length ? (
                      <TransactionList
                        transactions={enterprise.transactions}
                        onClickItem={trxClickHandler}
                        limit={5}
                        account={account}
                        enterprise={enterprise}
                      />
                    ) : (
                      <Typography variant="secondary" color="#6F7287">
                        There are no transaction yet! Check back soon.
                      </Typography>
                    )}
                  </Section>
                </DashboardCard>
              </Grid2>
            ) : null}
          </Grid2>
        </Box>
      ) : (
        <Box
          component="main"
          display="flex"
          flexDirection="column"
          sx={{ px: 2, rowGap: '16px' }}
        >
          <Grid2
            display="flex"
            flexDirection="column"
            sx={{
              backgroundColor: '#FFDB0A',
              borderRadius: '8px',
              padding: '12px',
              rowGap: '12px',
            }}
          >
            <Typography variant="h6">Join this World Enterprise!</Typography>
            <Typography
              variant="secondary"
              sx={{ color: '#28282B', mb: '4px' }}
            >
              Help to shape the future of this World Enterprise. Get voting
              rights and profit from their shares.
            </Typography>
            <WEButton
              type="primary"
              rounded="xl"
              width={224}
              startIcon={
                <Box
                  sx={{ width: '18px', height: '18px' }}
                  component="img"
                  src="/images/pie_chart_icon.svg"
                />
              }
              onClick={() => openModalJoin()}
            >
              Join & Get Shares
            </WEButton>
          </Grid2>
          <DashboardCard title="My Shares">
            <MyShares
              myShare={myShare}
              enterprise={enterprise}
              trade={trade}
              isMember={isMember}
            />
          </DashboardCard>
          <Grid2 sx={{ backgroundColor: 'transparent', pb: '100px' }}>
            <Grid2 display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
              <Typography
                sx={{ fontWeight: '700', fontSize: '20px', lineHeight: '24px' }}
              >
                {'Members & Treasury'}
              </Typography>
              <WELabelButton
                size="small"
                onClick={() => allMembers()}
                sx={{
                  margin: '0 0 0 auto',
                }}
              >
                See All
              </WELabelButton>
            </Grid2>
            <Grid2
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid2
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                sx={{ rowGap: '12px' }}
              >
                <Grid2
                  display="flex"
                  flexDirection="column"
                  sx={{ rowGap: '8px' }}
                >
                  <Typography variant="secondary">Members</Typography>
                  <Typography variant="primary">{numMem}</Typography>
                </Grid2>
                <Divider />
                <Grid2
                  display="flex"
                  flexDirection="column"
                  sx={{ rowGap: '8px' }}
                >
                  <Typography variant="secondary">Price Per Share</Typography>
                  <Typography variant="primary" sx={{ color: '#5CDD5C' }}>
                    {'$' +
                      sharePrice.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 10,
                      })}
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2 sx={{ flexGrow: '1' }}>
                <Divider />
              </Grid2>
              <Grid2
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                sx={{ rowGap: '12px' }}
              >
                <Grid2
                  display="flex"
                  flexDirection="column"
                  sx={{ rowGap: '8px' }}
                >
                  <Typography variant="secondary">Total Shares</Typography>
                  <Typography variant="primary">
                    {totalShare.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Grid2>
                <Divider />
                <Grid2
                  display="flex"
                  flexDirection="column"
                  sx={{ rowGap: '8px' }}
                >
                  <Typography variant="secondary">Treasury</Typography>
                  <Typography variant="primary">
                    {'$' +
                      treasuryUSD.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Box>
      )}
      <AdminDetailModal
        open={adminDlgOpen}
        onClose={() => {
          setAdminDlgOpen(false);
        }}
        isAdmin={
          admins.filter(
            (admin) => admin.walletAddr.toUpperCase() === account.toUpperCase()
          ).length > 0
        }
        email={(admins[adminIdx] && admins[adminIdx].email) || ''}
        phone={(admins[adminIdx] && admins[adminIdx].phone) || ''}
        walletAddr={(admins[adminIdx] && admins[adminIdx].walletAddr) || ''}
        replace={() => {
          adminReplaceHandler(adminIdx);
        }}
        remove={adminRemoveHandler(adminIdx)}
      />
      <CommentModal
        open={commentDlgOpen}
        onClose={() => setCommentDlgOpen(false)}
        id={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].id.toString()) ||
          ''
        }
        idx={proposalIdx}
        comments={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].comments) ||
          []
        }
      />
      <ProposalDetailModal
        open={proposalDlgOpen}
        handleClose={() => setProposalDlgOpen(false)}
        handleCommentOpen={() => setCommentDlgOpen(true)}
        reject={proposalReject}
        agree={proposalAgree}
        execute={proposalExecute}
        idx={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].idx) ||
          0
        }
        name={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].name) ||
          ''
        }
        walletAddr={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].walletAddr) ||
          ''
        }
        id={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].id.toString()) ||
          ''
        }
        yesNum={
          enterprise.proposals[proposalIdx] &&
          enterprise.proposals[proposalIdx].votesYes
        }
        noNum={
          enterprise.proposals[proposalIdx] &&
          enterprise.proposals[proposalIdx].votesNo
        }
        totalNum={enterprise.proposals[proposalIdx] && enterprise?.info?.memNum}
        commentUrl={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].commentUrl) ||
          ''
        }
        comments={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].comments) ||
          []
        }
        isApproved={enterprise?.proposals[proposalIdx]?.isApproved || ''}
        status={enterprise?.proposals[proposalIdx]?.status || ''}
        endTime={enterprise?.proposals[proposalIdx]?.endTime || ''}
      />
      <TransactionDetailModal
        open={trxDlgOpen}
        handleClose={() => setTrxDlgOpen(false)}
        reject={trxReject}
        agree={trxAgree}
        value={enterprise.transactions[trxIdx]}
        type={
          enterprise.transactions[trxIdx] &&
          enterprise.transactions[trxIdx].type
        }
      />

      <JoinModal
        joinWE={joinWE}
        enterprise={enterprise}
        wepID={enterprise.info.wepID}
        open={dlgOpened}
        onClose={() => {
          setDlgOpened(false);
        }}
        name={enterprise.info.name}
        memNum={enterprise.info.memNum}
        website={enterprise.info.website}
        description={enterprise.info.description}
        enterpriseAddress={enterprise.address}
        shareToBuy={150}
        offerPrice={0}
        request={requestHandler}
      />
    </>
  );
};

const Section = (props) => {
  const { children, addNew, tooltip, sx, isLoading } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  };

  return (
    <>
      {tooltip !== '' && (
        <ToolTip title={tooltip} open={showTooltip}>
          <IconButton size="small" color="primary" onClick={handleClick}>
            <Help fontSize="small" htmlColor="#020433" />
          </IconButton>
        </ToolTip>
      )}
      {addNew && (
        <IconButton
          onClick={() => {
            addNew();
          }}
          sx={{
            backgroundColor: '#FFFFFFFF',
            border: '0',
            ':hover': {
              backgroundColor: '#FFFFFFFF',
              border: '0',
            },
            color: '#3D61B0',
            p: 0,
          }}
        >
          <Add htmlColor="#3D61B0" />
        </IconButton>
      )}
      <Container
        sx={{
          m: 0,
          p: 0,
          background: '#fcfcfc',
          borderRadius: '8px',
          height: 'content-fit',
          ...sx,
        }}
      >
        {isLoading ? (
          <Typography variant="secondary" color="#6F7287">
            Loading...
          </Typography>
        ) : (
          <>{children}</>
        )}
      </Container>
    </>
  );
};

export const TransactionList = (props) => {
  const { transactions, onClickItem, limit, enterprise } = props;
  console.log(props);
  let trxs = [...transactions];
  trxs.sort((a, b) => b.created_at - a.created_at);
  if (limit) {
    trxs = trxs.slice(0, Number(limit));
  }
  trxs = trxs.map((trx) => {
    let temp = { ...trx };
    temp.text = temp.type;
    temp.color = '#241F21';
    return temp;
  });
  console.log({ transactions, trxs });

  return (
    <>
      {trxs.map((trx, i) => {
        return (
          <React.Fragment key={i}>
            <ListItemButton
              key={i}
              component="div"
              sx={{
                px: 0,
                pt: 1,
                pb: trxs.length === i ? 0 : 1,
                width: '100%',
                overflowX: 'auto',
              }}
              onClick={() => {
                onClickItem(transactions.length - (i + 1));
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    width: '40px',
                    height: '40px',
                    border: '2px solid #D9D9D9',
                    bgcolor: '#ffffff00',
                  }}
                >
                  <CreditCard htmlColor="#4B4749" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${trx.text}`}
                secondary={trx.type === TXTYPE.SHARE_OUT ? '' : trx.category}
                primaryTypographyProps={{
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: trx.color,
                  textAlign: 'left',
                }}
                secondaryTypographyProps={{
                  mt: 1,
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#4B4749',
                  textAlign: 'left',
                }}
              />
              <ListItemText
                primary={(() => {
                  const options = {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 18,
                  };
                  let sign = '';
                  let value = '';
                  if (
                    trx.type === TXTYPE.MONEY_IN ||
                    trx.type === TXTYPE.MONEY_OUT
                  ) {
                    console.log(enterprise);
                    console.log(
                      'trx.from',
                      trx.from.toUpperCase(),
                      'enterprise.address',
                      enterprise.address,
                      enterprise.info
                    );
                    if (trx.from.toUpperCase() === enterprise.address)
                      sign = '-';
                    else sign = '+';
                  }
                  if (trx.type === 'Send money') {
                    switch (trx.asset) {
                      case '':
                        value = `${sign}${trx.amount.toLocaleString(
                          undefined,
                          options
                        )} Matic`;
                        break;
                      case 'ETH':
                        value = `${sign}${trx.amount.toLocaleString(
                          undefined,
                          options
                        )} Matic`;
                        break;
                      case 'USDC':
                        value = `${sign}${trx.amount.toLocaleString(
                          undefined,
                          options
                        )} $`;
                        break;
                      default:
                        value = `${sign}${trx.amount.toLocaleString(
                          undefined,
                          options
                        )}`;
                    }
                  } else {
                    value = `${sign}${trx.amount.toLocaleString(undefined)} ${
                      trx.asset
                    }`;
                  }
                  return (
                    <span
                      style={{
                        color: (() => {
                          switch (sign) {
                            case '':
                              return '#28282B';
                            case '-':
                              return '#FF0000';
                            case '+':
                              return '#5CDD5C';
                          }
                        })(),
                      }}
                    >
                      {value}
                    </span>
                  );
                })()}
                secondary={new Date(trx.created_at).toLocaleString('en-us', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
                primaryTypographyProps={{
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '12px',
                  lineHeight: '14.6px',
                  textAlign: 'right',
                  color: '#28282B',
                }}
                secondaryTypographyProps={{
                  mt: 1,
                  fontFamily: 'Montserrat',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '10px',
                  lineHeight: '13.4px',
                  textAlign: 'right',
                  color: '#6F7287',
                }}
              />
            </ListItemButton>
            {trxs.length - 1 !== i && <Divider key={`divider${i}`} />}
          </React.Fragment>
        );
      })}
    </>
  );
};

TransactionList.propTypes = {
  transactions: PropTypes.array,
  onClickItem: PropTypes.func,
  limit: PropTypes.number,
  enterprise: PropTypes.object,
};

const TreasuryAdmin = (props) => {
  const { treasuryUSD, treasuryMatic, send, receive } = props;
  return (
    <>
      <Grid2
        display="flex"
        flexDirection="column"
        sx={{ rowGap: '12px', pb: 2 }}
      >
        <Typography variant="secondary">Treasury</Typography>
        <Typography
          sx={{ fontWeight: '700', fontSize: '20px', lineHeight: '24px' }}
        >
          {`${treasuryMatic.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })} Matic ($${treasuryUSD.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })})`}
        </Typography>
      </Grid2>
      <Grid2 display="flex" sx={{ columnGap: '9px' }}>
        <WEButton
          onClick={() => send()}
          type="success"
          width={370}
          startIcon={
            <FileUploadOutlined
              fontSize="small"
              sx={{ height: '15px', width: '15px' }}
              htmlColor="#ffffff"
            />
          }
          rounded="xl"
          sx={{
            fontSize: { xs: '14px', sm: '16px' },
            px: { xs: 0, sm: 2 },
          }}
        >
          Send Money
        </WEButton>
        <WEButton
          onClick={() => receive()}
          type="error"
          width={370}
          startIcon={
            <FileUploadOutlined
              fontSize="small"
              sx={{
                height: '15px',
                width: '15px',
                transform: 'rotate(180deg)',
              }}
              htmlColor="#ffffff"
            />
          }
          rounded="xl"
          sx={{
            fontSize: { xs: '14px', sm: '16px' },
            px: { xs: 0, sm: 2 },
          }}
        >
          Receive Money
        </WEButton>
      </Grid2>
    </>
  );
};

export default DashboardPage;

TreasuryAdmin.propTypes = {
  treasuryUSD: PropTypes.any,
  treasuryMatic: PropTypes.any,
  send: PropTypes.any,
  receive: PropTypes.any,
};

Section.propTypes = {
  children: PropTypes.any,
  addNew: PropTypes.any,
  tooltip: PropTypes.string,
  sx: PropTypes.any,
  isLoading: PropTypes.bool,
};

Section.defaultProps = {
  children: null,
  addNew: null,
  sx: null,
  tooltip: '',
};
