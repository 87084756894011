import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import ScrollToTop from '../../components/ScrollToTop';
import { NewOrder } from '../../components/Trades';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';

const Trades = () => {
  const location = useLocation();
  const state = location.state;
  const [weBack, setWeBack] = useState();
  const [stepState, setStepState] = store.useState(STORE_KEYS.id.step);

  React.useEffect(
    () => {
      if (stepState === STEP.INDEX) {
        setStepState(STEP.DASHBOARD_TRADE_ADD);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (state !== null) {
      setWeBack(state.weBack);
    }
  }, [state]);

  return (
    <>
      <ScrollToTop />
      <Header pageTitle={'Trades'} />
      {stepState === STEP.DASHBOARD_TRADE_ADD && <NewOrder weBack={weBack} />}
    </>
  );
};

export default Trades;
