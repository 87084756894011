/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Box,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getRemainTimeToExecute, isReadyToExecute } from '../../utils/date';
import { shortAddress } from '../../utils/shortAddress';
import { ModalHead } from '../AppBar';
import { WEButton } from '../Button';
import { WEModal } from './DetailDialog';
import Info from './Info';
import InfoAddr from './InfoAddr';
import InfoVote from './InfoVote';
import Transition from './Transition';
import { PROPOSAL_STATUS_ACTIVE } from '../../constants/ProposalStatus';
import PropTypes from 'prop-types';

const linkStyle = {
  textDecoration: 'underline',
  color: 'blue',
  cursor: 'pointer',
};

const ProposalDetailModal = ({
  open,
  handleClose,
  handleCommentOpen,
  reject,
  agree,
  execute,
  name,
  walletAddr,
  idx,
  commentUrl,
  comments,
  yesNum,
  noNum,
  totalNum,
  isApproved,
  status,
  endTime,
}) => {
  const [remainTime, setRemainTime] = React.useState('');
  const [comment, setComment] = useState('');
  React.useEffect(() => {
    const handler = setInterval(() => {
      if (parseInt(endTime) > 0) {
        setRemainTime(getRemainTimeToExecute(endTime));
      }
    }, 1000);

    return () => {
      clearInterval(handler);
    };
  }, [endTime]);

  useEffect(() => {
    if (commentUrl) {
      axios.get(commentUrl).then((res) => {
        setComment(res.data);
      });
    }
  }, [commentUrl]);

  return (
    <WEModal
      open={open}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        <ModalHead
          title={'Proposal Detail'}
          customClose={() => handleClose()}
          color="#FFDB0B"
        />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Info label="Name" value={name} />
        <InfoAddr
          label="Wallet"
          value={shortAddress(walletAddr)}
          // eslint-disable-next-line react/prop-types
          addr={walletAddr.toLowerCase()}
        />
        <Info label="Proposal ID" value={idx} />
        <Info label="Description" value={comment} />
        <i style={linkStyle} onClick={handleCommentOpen}>
          View Comments ({comments ? comments?.length : 0})
        </i>
        <Container
          component="span"
          sx={{
            display: 'flex',
            margin: '12px 0px',
            p: 0,
          }}
        >
          <InfoVote type="yes" num={yesNum} />
          <InfoVote type="no" num={noNum} />
        </Container>
        {status === '' && !isReadyToExecute(endTime) && (
          <DialogContentText id="alert-dialog-description">
            {remainTime}
          </DialogContentText>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            px: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {!isApproved &&
            status === PROPOSAL_STATUS_ACTIVE &&
            !isReadyToExecute(endTime) && (
              <WEButton
                type="primary"
                variant="outlined"
                width={320}
                rounded="xl"
                onClick={() => agree()}
              >
                Agree
              </WEButton>
            )}
          {!isApproved &&
            status === PROPOSAL_STATUS_ACTIVE &&
            !isReadyToExecute(endTime) && (
              <WEButton
                onClick={() => reject()}
                type="primary"
                variant="outlined"
                rounded="xl"
                size="medium"
                width={320}
              >
                Reject
              </WEButton>
            )}
          {status === PROPOSAL_STATUS_ACTIVE &&
            (isReadyToExecute(endTime) ||
              yesNum * 2 >= totalNum ||
              noNum * 2 >= totalNum) && (
              <WEButton
                onClick={() => execute()}
                type="primary"
                variant="outlined"
                rounded="xl"
                size="medium"
                width={320}
              >
                Execute
              </WEButton>
            )}
        </Box>
      </DialogActions>
    </WEModal>
  );
};

ProposalDetailModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCommentOpen: PropTypes.func,
  reject: PropTypes.func,
  agree: PropTypes.func,
  execute: PropTypes.func,
  name: PropTypes.string,
  walletAddr: PropTypes.string,
  idx: PropTypes.any,
  commentUrl: PropTypes.string,
  comments: PropTypes.array,
  yesNum: PropTypes.number,
  noNum: PropTypes.number,
  isApproved: PropTypes.bool,
  status: PropTypes.any,
  endTime: PropTypes.any,
};

export default ProposalDetailModal;
