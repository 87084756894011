import {
  PROPOSAL_TYPE_ADD,
  PROPOSAL_TYPE_MINT,
  PROPOSAL_TYPE_NEW_SHAREHOLDER,
  ProposalTypes,
} from '../constants/ProposalTypes';

export const getProposalTypeName = (type) => {
  if (type in ProposalTypes) {
    return ProposalTypes[type];
  }
  return ProposalTypes.DEFAULT;
};

export const getProposalContent = (type) => {
  switch (type) {
    case PROPOSAL_TYPE_ADD:
      return 'proposes new admin';
    case PROPOSAL_TYPE_NEW_SHAREHOLDER:
      return 'proposes new shareholder';
    case PROPOSAL_TYPE_MINT:
      return 'requested to join';
    default:
      return 'proposal';
  }
};
