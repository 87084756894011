import { MenuItem, Menu, styled } from '@mui/material';

export const fontForSize = (size) => {
  switch (size) {
    case 'small':
      return '12px';
    case 'medium':
      return '16px';
    case 'large':
      return '20px';
  }
};

export const WEMenuItem = styled(MenuItem)(({ theme, size }) => ({
  color: theme.palette.primary.main,
  padding: '12px 20px',
  fontSize: fontForSize(size),
}));

export const WEMenu = styled(Menu)(() => ({
  padding: '10px 0px',
  '& .MuiPaper-root': {
    minWidth: '150px',
  },
}));
