import { Button, Container, Grid } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import { AppBar } from '../AppBar';
import BsUpdatesList from './BsUpdatesList';

const BsUpdatesView = () => {
  const navigation = useNavigate();
  const { account } = useWeb3React();
  const [, setStep] = store.useState(STORE_KEYS.id.step);
  const { state } = useLocation();
  const { wepID, weBack, pTab } = state;
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [bsUpdates, setBsUpdates] = useState(
    enterprises.tempEnterprise.businessUpdates
  );
  const [admins, setAdmins] = useState(enterprises.tempEnterprise.admins);

  const backHandler = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack, pTab } });
  };

  const addHandler = () => {
    setStep(STEP.DASHBOARD_BSUPDATE_ADD);
  };

  useEffect(() => {
    if (enterprises.tempEnterprise.admins?.length === 0) {
      const getEnterprise = JSON.parse(localStorage.getItem('enterprise'));
      updateEnterprises((prev) => {
        prev.tempEnterprise = getEnterprise;
        return prev;
      });
      setAdmins(getEnterprise.admins);
      setBsUpdates(getEnterprise.businessUpdates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppBar
        position="absolute"
        title="Business Updates"
        back={backHandler}
        type={
          bsUpdates.length != 0 &&
          admins.filter(
            (admin) => admin.walletAddr.toUpperCase() === account.toUpperCase()
          ).length > 0
            ? 'add'
            : 'none'
        }
        handle={
          admins.filter(
            (admin) => admin.walletAddr.toUpperCase() === account.toUpperCase()
          ).length > 0
            ? addHandler
            : undefined
        }
        toolTip="Business Updates"
      />

      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 0,
          pt: bsUpdates.length === 0 ? 0 : 9, // remain for further update
        }}
      >
        {bsUpdates.length === 0 &&
        admins.filter(
          (admin) => admin.walletAddr.toUpperCase() === account.toUpperCase()
        ).length > 0 ? (
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: "center",
              alignItems: 'center',
              height: '100vh',
              m: 0,
              px: 3,
            }}
          >
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '49vh',
                px: 3,
              }}
            >
              {bsUpdates.length === 0 ? (
                <Button
                  onClick={addHandler}
                  sx={{
                    borderRadius: 24,
                    paddingX: { xs: 2, md: 4 },
                    bgcolor: '#28282B',
                    '&:hover': {
                      bgcolor: '#28282B',
                    },
                  }}
                >
                  Add a new business update
                </Button>
              ) : null}
            </Container>
          </Container>
        ) : (
          <Container sx={{ mt: 2, px: 3 }}>
            <BsUpdatesList />
          </Container>
        )}
      </Grid>
    </>
  );
};

export default BsUpdatesView;
