import HelpIcon from '@mui/icons-material/Help';
import { Box, Grid, IconButton } from '@mui/material';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { ToolTip } from '../Dialog';
import { Input, InputLabel, WalletAddrAutocomplete } from '../Input';

import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import { PROPOSAL_TYPE_NEW_SHAREHOLDER } from '../../constants/ProposalTypes';
import store from '../../constants/store';
import {
  EmptyProposal,
  EmptyShareholder,
  ProposalType,
  STEP,
  STORE_KEYS,
} from '../../constants/store/constant';
import { useEnterprise } from '../../hook/weEnterprise';
import { useWeb3 } from '../../hook/web3';
import { getEtherFromWei } from '../../utils/unit';
import { WEButton } from '../Button';
import { useNotification } from '../Notification/hook';

const ShareholderAdd = () => {
  const { account } = useWeb3React();
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const { displayError, displaySuccess } = useNotification();

  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const shareholdersState = enterprises.tempEnterprise.shareholders;
  const toEditState = enterprises.toEditShareholder;
  const enterpriseHandler = useEnterprise(enterprises.tempEnterprise.address);
  const web3 = useWeb3();

  const [shareholder, setShareholder] = useState({
    walletAddr: '',
    numOfShare: 0,
    firstName: '',
    lastName: '',
  });

  const handleInputChange = (type) => (evt) => {
    const value = evt.currentTarget.value;
    switch (type) {
      case 'walletAddr':
        setShareholder((prev) => {
          return {
            ...prev,
            walletAddr: value,
          };
        });
        break;
      case 'numOfShare':
        const temp = Number(value.replace('.', '')); //eslint-disable-line no-case-declarations
        if (!isNaN(temp) && value.length <= 19 && temp >= 0) {
          setShareholder((prev) => {
            return {
              ...prev,
              numOfShare: Web3.utils.toWei(temp.toString()),
            };
          });
        }
        break;
      case 'firstName':
        setShareholder((prev) => {
          return {
            ...prev,
            firstName: value,
          };
        });
        break;
      case 'lastName':
        setShareholder((prev) => {
          return {
            ...prev,
            lastName: value,
          };
        });
        break;
      default:
        break;
    }
  };

  const continueHandler = (info) => async () => {
    if (!info?.walletAddr) {
      return displayError({
        message: 'Please input wallet address',
        reason: '',
        timeout: 5000,
      });
    }
    if (!ethers.utils.isAddress(info.walletAddr)) {
      return displayError({
        message: 'Please input correct wallet address',
        reason: '',
        timeout: 5000,
      });
    }
    if (!(parseInt(info?.numOfShare) > 0)) {
      return displayError({
        message: 'Please input number of shares correctly',
        reason: '',
        timeout: 5000,
      });
    }

    const result = shareholdersState.find(
      (obj) => obj.walletAddr === info.walletAddr
    );
    if (result) {
      return displayError({
        message:
          'This address already exits, Please input other wallet address',
        reason: '',
        timeout: 5000,
      });
    }

    let proposal;
    switch (stepState) {
      case STEP.CREATE_SHAREHOLDER_ADD:
        updateEnterprises((prev) => {
          prev.tempEnterprise.shareholders = [
            ...prev.tempEnterprise.shareholders,
            info,
          ];
          return prev;
        });
        setStep(STEP.CREATE_SHAREHOLDERS_VIEW);
        break;
      case STEP.CREATE_SHAREHOLDER_EDIT:
        updateEnterprises((prev) => {
          prev.tempEnterprise.shareholders[prev.toEditShareholder] = info;
          return prev;
        });
        setStep(STEP.CREATE_SHAREHOLDERS_VIEW);
        break;
      case STEP.PROPOSAL_SHAREHOLDER:
        proposal = EmptyProposal();
        proposal.id = Date.now();
        proposal.type = ProposalType.SHAREHOLDER;
        proposal.walletAddr = info.walletAddr;
        proposal.name = `${info.firstName} ${info.lastName}`.trim();
        updateEnterprises((prev) => {
          prev.tempEnterprise.proposals = [
            ...prev.tempEnterprise.proposals,
            proposal,
          ];
          return prev;
        });
        setStep(STEP.INDEX);
        break;
      case STEP.DASHBOARD_MEMBERS_SHAREHOLDER_EDIT:
        updateEnterprises((prev) => {
          prev.tempEnterprise.shareholders[prev.toEditShareholder] = info;
          return prev;
        });
        setStep(STEP.DASHBOARD_MEMBERS);
        break;
      case STEP.DASHBOARD_MEMBERS_SHAREHOLDER_ADD:
        try {
          await enterpriseHandler.methods
            .proposeNewShareHolder(info.walletAddr, info?.numOfShare)
            .send({
              from: account,
              gasLimit: web3.eth.getBlock('latest').gasLimit,
            });
          displaySuccess({
            message: 'Created a new proposal successfully',
            timeout: 5000,
          });
          updateEnterprises((prev) => {
            const newProposal = EmptyProposal({
              id: Date.now().toString(),
              idx: prev.tempEnterprise.proposals.length.toString(),
              amount: info?.numOfShare,
              candidate: info?.walletAddr,
              type: PROPOSAL_TYPE_NEW_SHAREHOLDER,
              votesYes: 0,
              votesNo: 0,
              enterpriseAddr: prev.tempEnterprise.address,
              walletAddr: account,
              name: `${info.firstName} ${info.lastName}`.trim(),
              isApproved: false,
              status: 'ACTIVE',
            });
            prev.tempEnterprise.proposals = [
              ...prev.tempEnterprise.proposals,
              newProposal,
            ];
            return prev;
          });
        } catch (error) {
          console.log(`Error ${error}`); // eslint-disable-line no-console
          displayError({
            message: `Error ${error.message}`,
            mode: '',
            timeout: 5000,
          });
          break;
        }
        setStep(STEP.DASHBOARD_MEMBERS);
        break;
      default:
        break;
    }
  };

  const renderBtnLabel = (state) => {
    switch (state) {
      case STEP.CREATE_SHAREHOLDER_ADD:
        return 'Add Shareholder';
      case STEP.PROPOSAL_SHAREHOLDER:
      case STEP.DASHBOARD_MEMBERS_SHAREHOLDER_EDIT:
      case STEP.DASHBOARD_MEMBERS_SHAREHOLDER_ADD:
        return 'Propose Shareholder';
      case STEP.CREATE_SHAREHOLDER_EDIT:
        return 'Replace Shareholder';
      default:
        return 'Shareholder';
    }
  };

  useEffect(() => {
    if (stepState === STEP.CREATE_SHAREHOLDER_EDIT) {
      const original = shareholdersState.at(toEditState);
      if (original) {
        setShareholder(original);
      }
    }
  }, [setShareholder, shareholdersState, stepState, toEditState]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          px: 3,
        }}
      >
        <Box width="100%" mt={2}>
          <InputLabel shrink htmlFor="walletAddr" sx={{ color: '#6F7287' }}>
            Wallet Address
            <ToolTip
              title={
                'Enter the crypto address for the user to receive their shares'
              }
            >
              <IconButton size="small" color="primary">
                <HelpIcon fontSize="small" color="primary" />
              </IconButton>
            </ToolTip>
          </InputLabel>
          <WalletAddrAutocomplete
            value={shareholder.walletAddr}
            options={(() => {
              if (
                stepState === STEP.DASHBOARD_MEMBERS_SHAREHOLDER_ADD ||
                stepState === STEP.DASHBOARD_MEMBERS_SHAREHOLDER_EDIT
              )
                return [];
              let newSh = EmptyShareholder({});
              newSh.walletAddr = account || '';
              let name = (enterprises.name || '').split(' ');
              newSh.firstName = name[0] || '';
              newSh.lastName = name[1] || '';
              return [newSh];
            })().map((shareholder) => shareholder)}
            onChange={(event, newValue) => {
              if (newValue.walletAddr) {
                setShareholder((prev) => {
                  return {
                    ...prev,
                    walletAddr: newValue.walletAddr,
                    firstName: newValue.firstName || '',
                    lastName: newValue.lastName || '',
                  };
                });
              }
            }}
            onAddrChange={(value) => {
              setShareholder((prev) => {
                return {
                  ...prev,
                  walletAddr: value,
                };
              });
            }}
          />
        </Box>
        <Box width="100%" mt={3}>
          <InputLabel shrink htmlFor="numOfShare" sx={{ color: '#6F7287' }}>
            Number of Shares
          </InputLabel>
          <Input
            name="shares"
            autoComplete="new-shares"
            id="numOfShare"
            inputProps={{
              inputMode: 'numeric',
            }}
            value={
              getEtherFromWei(shareholder.numOfShare) === 0
                ? ''
                : getEtherFromWei(shareholder.numOfShare)
            }
            fullWidth
            sx={{
              mt: 1,
              '& input': {
                textAlign: 'right',
              },
            }}
            onChange={handleInputChange('numOfShare')}
            placeholder="0"
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} mt={3}>
            <InputLabel shrink htmlFor="firstName" sx={{ color: '#6F7287' }}>
              First Name (optional)
            </InputLabel>
            <Input
              name="weaddres"
              autoComplete="new-weaddres"
              id="firstName"
              value={shareholder.firstName}
              fullWidth
              sx={{ mt: 1 }}
              onChange={handleInputChange('firstName')}
            />
          </Grid>
          <Grid item xs={12} md={6} mt={3}>
            <InputLabel shrink htmlFor="lastName" sx={{ color: '#6F7287' }}>
              Last Name (optional)
            </InputLabel>
            <Input
              name="weaddres"
              autoComplete="new-weaddres"
              id="lastName"
              value={shareholder.lastName}
              fullWidth
              sx={{ mt: 1 }}
              onChange={handleInputChange('lastName')}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            marginTop: 5,
            mx: 'auto',
            width: { xs: '100%', sm: '370px' },
            pb: 4,
            textAlign: 'center',
          }}
        >
          <WEButton
            type="primary"
            rounded="xl"
            onClick={continueHandler(shareholder)}
          >
            {renderBtnLabel(stepState)}
          </WEButton>
        </Box>
      </Box>
    </>
  );
};

export default ShareholderAdd;
