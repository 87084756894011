import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const CarouselTitle = ({ children }) => {
  return (
    <Typography
      variant="h2"
      sx={{
        fontSize: '23px',
        lineHeight: '28px',
        fontWeight: '700',
        display: 'block',
        textAlign: 'center',
      }}
    >
      {children}
    </Typography>
  );
};

CarouselTitle.propTypes = {
  children: PropTypes.any,
};

export default CarouselTitle;
