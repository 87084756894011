import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Index = ({ pageTitle }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <title>{`${pageTitle} - World Enterprise - Launch and manage enterprises on the blockchain.`}</title>
      <link rel="icon" href="/favicon.ico" />
    </Helmet>
  );
};

Index.propTypes = {
  pageTitle: PropTypes.string,
};

export default Index;
