import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import DefaultModal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import InfoInput from './InfoInput';
import store from '../../constants/store';
import { STORE_KEYS } from '../../constants/store/constant';
import { useAutoConnect } from '../../utils/AutoConnect';
import { v4 as uuidv4 } from 'uuid';
import { upsertForum } from '../../apis';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid gray',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

export default function ForumModal({ id, idx, open, closeHandler }) {
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const { account, accountInfo } = useAutoConnect();
  const forums = enterprises.tempEnterprise.forums;
  const [comment, setComment] = React.useState({
    id: uuidv4(),
    content: '',
    creator: account,
    logo: accountInfo?.image_url,
    username: accountInfo?.username,
    created_at: Date.now(),
    updated_at: Date.now(),
    comments: [],
  });

  const handleCommentInput = (e) => {
    setComment({ ...comment, content: e.target.value });
  };

  const onSubmit = async () => {
    const insertComment = (commentId, newComment, comments) => {
      const traverseComments = (comments) => {
        return comments.map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, comments: [...comment.comments, newComment] };
          } else if (comment.comments) {
            return { ...comment, comments: traverseComments(comment.comments) };
          } else {
            return comment;
          }
        });
      };
      const newComments = traverseComments(comments);
      return newComments;
    };

    let forum = { ...forums[idx] };
    if (forum.id === id) {
      forum.comments = [
        ...forum.comments,
        {
          ...comment,
          username: accountInfo.username,
          logo: accountInfo.image_url,
          id: uuidv4(),
        },
      ];
    } else {
      forum.comments = insertComment(
        id,
        {
          ...comment,
          username: accountInfo.username,
          logo: accountInfo.image_url,
          id: uuidv4(),
        },
        forum.comments
      );
    }
    try {
      let res = await upsertForum(forum);
      if (res.data.success) {
        updateEnterprises((prev) => {
          prev.tempEnterprise.forums[idx] = forum;
          return prev;
        });
      }
    } catch (error) {
      console.log('Failed to Update or Add topic'); //eslint-disable-line no-console
    }

    closeHandler();
  };

  return (
    <div>
      <DefaultModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={closeHandler}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add a comment to a Topic
              </Typography>
              <IconButton onClick={closeHandler}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box>
              <InfoInput
                inputMode="text"
                value={comment.content}
                textAlign="left"
                multiline={true}
                lineNum={3}
                onChange={handleCommentInput}
              />
              <Button
                sx={{
                  backgroundColor: '#30D15D',
                  color: '#fff',
                  borderRadius: 2,
                  '&:hover': {
                    backgroundColor: '#30D15D',
                  },
                  float: 'right',
                }}
                onClick={onSubmit}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Fade>
      </DefaultModal>
    </div>
  );
}

ForumModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.any,
  id: PropTypes.string,
  idx: PropTypes.number,
};
