import { ContentCopy, Done } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material'; // useMediaQuery
import { Box, Container } from '@mui/system';
import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import store from '../../constants/store';
import { STORE_KEYS } from '../../constants/store/constant';
import { shortAddress } from '../../utils/shortAddress';

const QRReceiveCode = () => {
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const enterprise = enterprises.tempEnterprise;

  const [walletAddr, setWalletAddr] = useState(enterprise.address || '');
  const [copyed, setCopyed] = useState(false);

  const copyToClipboard = () => {
    try {
      copy(walletAddr || '');
      setCopyed(true);
    } catch (e) {
      alert(e.toString() + 'ddd');
    }
  };

  useEffect(() => {
    setWalletAddr(enterprise.address || '');
  }, [enterprise.address]);

  return (
    <>
      <Box
        mx="26px"
        mt={10}
        p="54px"
        component="main"
        sx={{
          borderRadius: '12px',
          border: 'solid 1px #EEEEEE',
          margin: 'auto',
        }}
      >
        <Box maxWidth={'400px'} sx={{ mx: 'auto' }}>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={walletAddr}
            viewBox={`0 0 256 256`}
          />
          <Container sx={{ mt: 3, p: 0, display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'left',
                  maxWidth: '24ch',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '16px',
                }}
              >
                {shortAddress(walletAddr)}
              </Typography>
            </Box>
            <IconButton onClick={copyToClipboard}>
              {!copyed ? (
                <ContentCopy sx={{ fontSize: '16px' }} />
              ) : (
                <Done sx={{ fontSize: '16px' }} htmlColor="green" />
              )}
            </IconButton>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default QRReceiveCode;
