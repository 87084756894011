import { InputBase, styled } from '@mui/material';
import 'react-phone-input-2/lib/style.css';

const Input = styled(InputBase)(({ theme }) => ({
  '&$disabled': {
    color: 'red',
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: '1px solid #ffffffff',
    borderRadius: '8px !important',
    backgroundColor: theme.palette.mode === 'light' ? '#FAFBFC' : '#FAFBFC',
    borderColor: 'rgba(0, 0, 0, 0.06) !important',
    color: '#4B4749',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: ['"Montserrat"'].join(','),
    lineHeight: '18px',

    padding: '12px 12px',
    '&:focus': {
      border: '1px solid #ced4da',
    },
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#4B4749',
    backgroundColor: '#D8D8D8',
  },
  '&.Mui-error .MuiInputBase-input': {
    borderColor: theme.palette.error.main + ' !important',
  },
}));

export default Input;
