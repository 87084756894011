import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const dashboardTheme = {
  primary: '#FFDB0A',
  secondary: '#3D61B0',
  black: '#241F21',
  black1: '#4B4749',
  success: '#42B03D',
  danger: '#FF6142',
};

const Info = ({ title, value, bg }) => {
  return (
    <Typography
      variant={'h3'}
      sx={{
        p: '11px 19px',
        borderRadius: '19px',
        backgroundColor: dashboardTheme[bg],
        fontFamily: 'Montserrat',
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'center',
      }}
    >
      <span
        style={{
          display: 'block',
          color: '#fff',
        }}
      >
        {title}
      </span>
      <span
        style={{
          marginTop: '5px',
          display: 'block',
          color: '#fff',
          fontSize: '18px',
          fontWeight: '600',
        }}
      >
        {value}
      </span>
    </Typography>
  );
};

Info.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  bg: PropTypes.string,
};

Info.defaultProps = {
  title: null,
  value: null,
  bg: null,
};
export default Info;
