export function shortAddress(address) {
  if (
    typeof address === 'string' &&
    (address.indexOf('0x') === 0 || address.indexOf('0X') === 0)
  ) {
    return (
      address.toLocaleLowerCase().slice(0, 5) +
      '...' +
      address.toLowerCase().slice(-4)
    );
  } else {
    return address;
  }
}
