import Web3 from 'web3';
import { getCoinPrice } from '../apis';

/**
 * @dev Puffer
 * @param wei number | string (for number)
 **/
export function getEtherFromWei(wei) {
  wei = getStrNumber(wei);
  return parseFloat(Web3.utils.fromWei(wei));
}

export function getStrNumberFromNumber(num) {
  if (typeof num === 'number' && !isNaN(num)) {
    num = num.toLocaleString();
    num = num.replace(/,/g, '');
    return num;
  }
  return '0';
}

export function getStrNumberFromString(str) {
  if (typeof str === 'string') {
    str = getStrNumberFromNumber(parseInt(str));
    return str;
  }
  return '0';
}

export function getStrNumber(data) {
  if (typeof data === 'string') {
    data = getStrNumberFromString(data);
  } else if (typeof data === 'number') {
    data = getStrNumberFromNumber(data);
  } else {
    data = '0';
  }
  return data;
}

Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split('.')[1].length || 0;
};

export const convertMaticToUSD = async (matic) => {
  let rate = await getCoinPrice();
  let price = 1 / rate;
  let decimalCounts = matic.countDecimals() + price.countDecimals();
  return (matic * price * 10 ** decimalCounts).toFixed(0) / 10 ** decimalCounts;
};

export const convertUSDToMatic = async (usd) => {
  let rate = await getCoinPrice();
  let price = 1 / rate;
  let decimalCounts = usd.countDecimals() + price.countDecimals();
  return ((usd / price) * 10 ** decimalCounts).toFixed(0) / 10 ** decimalCounts;
};
