import * as React from 'react';
import detectEthereumProvider from '@metamask/detect-provider';
import { Avatar, DialogContent, Divider } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { useAutoConnect } from '../../utils/AutoConnect';
import { ModalHead } from '../AppBar';
import { WEButton } from '../Button';
import BtnOption from './BtnOption';
import { WEModal } from './DetailDialog';
import Transition from './Transition';
import PropTypes from 'prop-types';

const SelectWalletModal = ({ isOpen, closeModal }) => {
  const { connectors, onConnect, network } = useAutoConnect();
  const [detectedProvider, setDetectedProvider] = React.useState('detect');

  React.useEffect(() => {
    detectEthereumProvider({ silent: true })
      .then((provider) => {
        const pName = !provider
          ? 'none'
          : provider.isMetaMask
          ? 'metamask'
          : provider.isCoinbaseWallet
          ? 'coinbase'
          : 'other';
        setDetectedProvider(pName);
      })
      .catch((e) => {
        console.log('detectEthereumProvider error', e); //eslint-disable-line no-console
      });
  }, []);

  const connectWallet = (e, connectorInfo) => {
    const { name, connector } = connectorInfo;
    closeModal();

    if (detectedProvider !== name) {
      console.log(`connectWallet: please install ${name}`); //eslint-disable-line no-console
      return true; // no provider -> follow the link to install
    }

    connector
      .activate(network)
      .then(() => {
        onConnect({ ...connectorInfo, chainId: network.chainId });
      })
      .catch((err) => {
        console.log(
          `connectWallet activate ${name} for ${network.chainName} failed`,
          err
        ); //eslint-disable-line no-console
      });

    e.preventDefault();
    return false;
  };

  const schema = window.location.protocol;
  const host = window.location.host;

  return (
    <WEModal
      open={isOpen}
      onClose={() => {
        closeModal();
      }}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        <ModalHead
          title={'Select wallet'}
          customClose={() => closeModal()}
          color="transparent"
        />
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ py: '22px !important' }}>
        {!connectors?.coinbase ? (
          ''
        ) : (
          <a
            href={`https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(
              schema
            )}%2F%2F${encodeURIComponent(host)}%2F`}
            onClick={(e) => connectWallet(e, connectors.coinbase)}
          >
            <BtnOption>
              <Avatar
                variant="square"
                src="/images/coinbase.svg"
                sx={{ width: 35, height: 35, mr: 0, borderRadius: '8px' }}
              />
              Coinbase Wallet
              <img alt="arrowForward" src="/images/Arrow 3.svg"></img>
            </BtnOption>
          </a>
        )}
        <Divider sx={{ my: '12px' }} />
        {!connectors?.metamask ? (
          ''
        ) : (
          <a
            href={`https://metamask.app.link/dapp/${encodeURIComponent(host)}/`}
            onClick={(e) => connectWallet(e, connectors.metamask)}
          >
            <BtnOption>
              <Avatar
                variant="square"
                src="/images/metamask.svg"
                sx={{ width: 35, height: 35, mr: 0 }}
              />
              Metamask Wallet
              <img alt="arrowForward" src="/images/Arrow 3.svg"></img>
            </BtnOption>
          </a>
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: '100%', padding: '0 18px' }}>
          <WEButton
            type="primary"
            variant="outlined"
            width={280}
            rounded="xl"
            onClick={() => closeModal()}
          >
            Close
          </WEButton>
        </Box>
      </DialogActions>
    </WEModal>
  );
};

SelectWalletModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default SelectWalletModal;
