import { Card, styled } from '@mui/material';

const WECard = styled(Card)(({ background, bRadius }) => ({
  background: background ? background : '#ffffff',
  borderRadius: bRadius ? bRadius : '8px',
  padding: '16px 13px',
  boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.12)',
}));

export default WECard;
