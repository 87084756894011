import { Box, Button, Container, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import SelectWalletModal from '../Dialog/SelectWalletModal';
import Footer from '../Footer';

const landingTheme = {
  primary: '#FFDB0A',
  secondary: '#3D61B0',
  black: '#0D141E',
  fontSize: { xs: '14px', md: '18px' },
  lineHeight: { xs: '17px', md: '180.5%' },
};

const aboutUsTheme = {
  primary: '#FFDB0A',
  secondary: '#3D61B0',
  black: '#0D141E',
  fontSize: { xs: '23px', md: '35px' },
  lineHeight: { xs: '17px', md: '180.5%' },
};

const AboutUs = () => {
  const matches = useMediaQuery('(max-width:1360px)');

  const [isOpen, setIsOpen] = useState(false);

  const connectHandler = () => {
    setIsOpen(true);
  };

  const Close = () => {
    setIsOpen(false);
  };

  return (
    <Box
      sx={{
        bgcolor: aboutUsTheme.primary,
        width: 1,
        pt: '50px',
      }}
    >
      {/* world enterprises? */}
      <Box
        sx={{
          backgroundImage: { xs: 'none', md: 'none' },
          backgroundSize: '50% 100%',
          backgroundPosition: 'right top',
          backgroundRepeat: 'no-repeat',
          mt: 10,
        }}
      >
        <Typography
          sx={{
            fontSize: aboutUsTheme.fontSize,
            lineHeight: aboutUsTheme.lineHeight,
            fontWeight: '700',
            color: aboutUsTheme.black,
            mb: { xs: '20px', md: '40px' },
            width: { xs: 'auto', md: 'auto' },
            textAlign: 'center',
            display: { xs: 'block', md: 'none' },
          }}
        >
          World Citizen
        </Typography>
        <Container
          sx={{
            textAlign: { xs: 'center', md: 'left' },
            py: { xs: '40px', md: '10px' },
            flexDirection: { xs: 'column', md: 'row' },
            display: { xs: 'block', md: 'flex' },
          }}
        >
          <Box
            component="img"
            src="/images/aboutus.png"
            alt=""
            sx={{
              width: { xs: '100%' },
              height: { xs: 'fit-content' },
              maxWidth: { xs: '100%', md: '100%' },
              display: { xs: 'block', md: 'none' },
            }}
          />
          <Container
            sx={{
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                fontSize: aboutUsTheme.fontSize,
                lineHeight: aboutUsTheme.lineHeight,
                fontWeight: '700',
                color: aboutUsTheme.black,
                mb: { xs: '20px', md: '20px' },
                width: { xs: 'auto', md: '400px' },
                display: { xs: 'none', md: 'block' },
              }}
            >
              World Citizen the company
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '13px', md: '18px' },
                lineHeight: aboutUsTheme.lineHeight,
                fontWeight: '400',
                color: aboutUsTheme.black,
                mt: { xs: '20px', md: '20px' },
                mb: { xs: '20px', md: '20px' },
                width: { xs: 'auto', md: matches ? '450px' : '505px' },
                maxWidth: '800px',
              }}
            >
              <i>World Enterprise</i> is an application developed by World
              Citizen, an organization committed to economic freedom, inclusion
              and equal opportunity for all. World Citizen is a digital
              distributed citizenship without geographic boundaries. World
              Citizen is not owned by any individual, rather it is owned and
              controlled by its participants. The community creates tools which
              allow World Citizens to organize, learn and produce without
              artificial barriers that is equitable to all.
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '13px', md: '18px' },
                lineHeight: aboutUsTheme.lineHeight,
                fontWeight: '400',
                color: aboutUsTheme.black,
                mb: { xs: '20px', md: '40px' },
                width: { xs: 'auto', md: matches ? '450px' : '505px' },
                maxWidth: '800px',
              }}
            >
              World Citizen seeks to create just a digital system where
              individuals are in control of their future for the promotion of
              their wellbeing. We believe in a global economic and political
              system controlled by the people providing security, justice and
              economic prosperity to all.
            </Typography>
          </Container>
          <Box
            component="img"
            src={'/images/aboutus.png'}
            alt=""
            sx={{
              width: { xs: 'auto', md: '100%' },
              height: { xs: 'auto', md: 'fit-content' },
              display: { xs: 'none', md: 'block' },
            }}
            style={{}}
          />
        </Container>
        <Button
          variant="outlined"
          sx={{
            m: '0 auto 20px',
            display: 'block',
            p: '10px 51px',
            borderColor: landingTheme.black,
            fontSize: landingTheme.fontSize,
            lineHeight: landingTheme.lineHeight,
            color: landingTheme.black,
            backgroundColor: 'transparent',
            fontWeight: 600,
          }}
          onClick={connectHandler}
        >
          Connect
        </Button>
      </Box>
      <Footer />
      <SelectWalletModal isOpen={isOpen} closeModal={Close} />
    </Box>
  );
};

export default AboutUs;
