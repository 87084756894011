import React from 'react';
import { Box, Container } from '@mui/material';
import PropTypes from 'prop-types';

const ListItem = (props) => {
  const { children, ...other } = props;
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      sx={{
        py: '10px',
        borderBottom: 'solid 1px #EEEEEE',
        cursor: 'pointer',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

ListItem.propTypes = {
  children: PropTypes.any,
};

export default ListItem;
