import { QrCode } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  Autocomplete as MuiAutocomplete,
  TextField,
} from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';
import { QRScanModal } from '../Dialog';
import PropTypes from 'prop-types';

const WalletAddrAutocomplete = ({ value, options, onChange, onAddrChange }) => {
  const [qrScanOpen, setQrScanOpen] = React.useState(false);
  const [walletAddr, setWalletAddr] = React.useState(value);

  const handleClickShowQR = () => {
    setQrScanOpen(true);
  };

  const closeQRScaner = () => {
    setQrScanOpen(false);
  };

  const updateWalletAddr = (addr) => {
    setWalletAddr(addr);
    onAddrChange(addr);
  };

  const handleInputChange = (evt) => {
    updateWalletAddr(evt.currentTarget.value);
  };

  return (
    <>
      <MuiAutocomplete
        value={walletAddr}
        fullWidth
        freeSolo
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: '0px 12px!important',
            borderRadius: '8px',
          },
          '& .MuiOutlinedInput-root: hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #ced4da',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            // border: "1px solid #ffffffff",
          },
          '& .MuiTextField-root': {
            borderRadius: '8px',
          },
        }}
        disableClearable
        options={options}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            name="password"
            InputProps={{
              ...params.InputProps,
              ...(params.inputProps.autoComplete = 'new-password'),
              type: 'search',
              endAdornment: (
                <IconButton onClick={handleClickShowQR} edge="end">
                  <QrCode sx={{ color: '#3D61B0' }} />
                </IconButton>
              ),
            }}
            value={walletAddr}
            onChange={handleInputChange}
            sx={{
              mt: 1,
              '& .MuiInputBase-input': {
                position: 'relative',
                // border: "1px solid #ffffffff",

                color: '#4B4749',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: 500,
                fontFamily: ['"Montserrat"'].join(','),
                lineHeight: '18px',

                padding: '12px 0px!important',
                '&:focus': {
                  // border: "1px solid #ffffffff",
                },
              },
            }}
          />
        )}
        getOptionLabel={(option) => {
          if (typeof option == 'string') return option;
          else return option.walletAddr;
        }}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.walletAddr, inputValue, {
            insideWords: true,
          });
          const parts = parse(option.walletAddr, matches);

          return (
            <>
              <Divider />
              <li
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                style={{
                  display: 'block',
                  paddingLeft: '9px',
                  paddingTop: '10px',
                }}
              >
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
                <div
                  style={{
                    display: 'block',
                    width: '100%',
                    marginTop: '5px',
                  }}
                >
                  {`${option.firstName} ${option.lastName}`}
                </div>
              </li>
            </>
          );
        }}
      />
      <QRScanModal
        open={qrScanOpen}
        onClose={closeQRScaner}
        qrHandler={updateWalletAddr}
      />
    </>
  );
};

WalletAddrAutocomplete.propTypes = {
  value: PropTypes.any,
  options: PropTypes.any,
  onChange: PropTypes.func,
  onAddrChange: PropTypes.func,
};

export default WalletAddrAutocomplete;
