import {
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useWeb3React } from '@web3-react/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import EnterpriseABI from '../../constants/abi/enterprise.json';
import EnterpriseDevABI from '../../constants/abi/enterprise_dev.json';
import store from '../../constants/store';
import { STORE_KEYS } from '../../constants/store/constant';
import { useWeb3 } from '../../hook/web3';
import { getAddress } from '../../utils/address';
import { getLowerCaseAddress } from '../../utils';
import { simpleErrorMessage } from '../../utils/errorMessage';
import { ERRORS } from '../../utils/errors';
import { shortAddress } from '../../utils/shortAddress';
import { convertMaticToUSD, getEtherFromWei } from '../../utils/unit';
import { ModalHead } from '../AppBar';
import { WELoadingButton } from '../Button';
import { useNotification } from '../Notification/hook';
import { WEModal } from './DetailDialog';
import Info from './Info';
import InfoAddr from './InfoAddr';
import Transition from './Transition';

const OrderDetailModal = ({ open, onClose, order }) => {
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const { displaySuccess, displayError } = useNotification();

  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const enterprise = enterprises.tempEnterprise;

  const [orderDetail, setOrder] = useState({
    id: -1,
    type: 'BUY',
    amount: 0,
    price: 0,
    date: new Date(),
    owner_address: '',
    taker: '',
  });

  const [loadingStatus, setLoadingStatus] = useState('');

  useEffect(() => {
    if (order !== undefined) {
      setOrder(order);
    }
  }, [order]);

  const acceptHandler = async () => {
    if (orderDetail.status !== 'ACTIVE') {
      return displayError({
        message: ERRORS.ORDER_INACTIVE,
        timeout: 5000,
      });
    }

    const enterpriseHandler = new web3.eth.Contract(
      process.env.REACT_APP_ENV === 'prod' ? EnterpriseABI : EnterpriseDevABI,
      getAddress(orderDetail.enterprise_address)
    );

    let nativePrice =
      parseFloat(orderDetail.amount) * parseFloat(orderDetail.price);
    nativePrice = web3.utils.toWei(nativePrice.toString());

    const _amount = web3.utils.toWei(orderDetail.amount.toString());

    try {
      if (orderDetail.type === 'BUY') {
        if (orderDetail.amount > enterprise.numOfShare) {
          return displayError({
            message: ERRORS.SHARE_NOT_ENOUGH,
            reason: `${orderDetail.amount} is needed, but you have ${parseFloat(
              enterprise.numOfShare
            )}.`,
            timeout: 5000,
          });
        }
        setLoadingStatus('ACCEPT');
        await enterpriseHandler.methods
          .approve(getAddress(orderDetail.enterprise_address), _amount)
          .send({
            from: account,
            gasLimit: web3.eth.getBlock('latest').gasLimit,
          });

        await enterpriseHandler.methods.closeOrder(orderDetail.order_id).send({
          from: account,
          gasLimit: web3.eth.getBlock('latest').gasLimit,
        });
        setLoadingStatus('');
        displaySuccess({ message: 'Trade completed', timeout: 5000 });

        updateEnterprises((prev) => {
          if (
            getLowerCaseAddress(orderDetail.owner_address) !==
            getLowerCaseAddress(account)
          ) {
            let _numOfShare = getEtherFromWei(prev.tempEnterprise.numOfShare);
            _numOfShare -= parseFloat(orderDetail.amount);
            prev.tempEnterprise.numOfShare = parseInt(
              web3.utils.toWei(_numOfShare.toString())
            );
          }

          prev.tempEnterprise.shareholders.forEach((sh, idx) => {
            if (sh.walletAddr?.toUpperCase() === account.toUpperCase()) {
              if (
                getLowerCaseAddress(orderDetail.owner_address) !==
                getLowerCaseAddress(account)
              ) {
                let _numOfShare = getEtherFromWei(
                  prev.tempEnterprise.shareholders[idx].numOfShare
                );
                _numOfShare -= parseFloat(orderDetail.amount);
                prev.tempEnterprise.shareholders[idx].numOfShare = parseInt(
                  web3.utils.toWei(_numOfShare.toString())
                );
              }
              if (prev.tempEnterprise.shareholders[idx].numOfShare <= 0) {
                let tshs = [...prev.tempEnterprise.shareholders];
                tshs.splice(idx, 0);
                prev.tempEnterprise.shareholders = tshs;
                prev.tempEnterprise.memNum -= 1;
              }
            }
          });
          return prev;
        });
      } else {
        let _balance = await web3.eth.getBalance(account);
        if (parseFloat(nativePrice) > parseFloat(_balance)) {
          return displayError({
            message: ERRORS.NATIVE_TOKEN_NOT_ENOUGH,
            reason: `${parseFloat(
              nativePrice
            )} is needed, but you have ${parseFloat(_balance)}.`,
            timeout: 5000,
          });
        }
        setLoadingStatus('ACCEPT');
        await enterpriseHandler.methods.closeOrder(orderDetail.order_id).send({
          from: account,
          value: nativePrice,
          gasLimit: web3.eth.getBlock('latest').gasLimit,
        });
        setLoadingStatus('');
        displaySuccess({ message: 'Trade completed', timeout: 5000 });

        updateEnterprises((prev) => {
          const index = prev.enterprises.findIndex(
            (_enterprise) => _enterprise.address === enterprise.address
          );
          console.log(
            prev.tempEnterprise.numOfShare,
            parseInt(web3.utils.toWei(orderDetail.amount.toString())),
            'details page'
          );
          let _numOfShare = getEtherFromWei(prev.enterprises[index].numOfShare);
          _numOfShare += parseFloat(orderDetail.amount);
          prev.enterprises[index].numOfShare = parseInt(
            web3.utils.toWei(_numOfShare.toString())
          );

          let _tempNumOfShare = getEtherFromWei(prev.tempEnterprise.numOfShare);
          _tempNumOfShare += parseFloat(orderDetail.amount);

          prev.tempEnterprise.numOfShare = parseInt(
            web3.utils.toWei(_tempNumOfShare.toString())
          );

          prev.tempEnterprise.shareholders.forEach((sh, idx) => {
            if (sh.walletAddr?.toUpperCase() === account.toUpperCase()) {
              let _numOfShare = getEtherFromWei(
                prev.tempEnterprise.shareholders[idx].numOfShare
              );
              _numOfShare += parseFloat(orderDetail.amount);
              prev.tempEnterprise.shareholders[idx].numOfShare = parseInt(
                web3.utils.toWei(_numOfShare.toString())
              );
            }
          });
          localStorage.setItem(
            'enterprise',
            JSON.stringify(prev.tempEnterprise)
          );
          return prev;
        });
      }
    } catch (e) {
      setLoadingStatus('');
      console.log(e); //eslint-disable-line no-console
      return displayError({
        message: ERRORS.TRADE,
        timeout: 5000,
      });
    }

    const priceUSD = await convertMaticToUSD(orderDetail.price);
    updateEnterprises((prev) => {
      const index = prev.enterprises.findIndex(
        (_enterprise) => _enterprise.address === enterprise.address
      );
      prev.enterprises[index].proposalFetched = false;
      prev.enterprises[index].orders = prev.tempEnterprise.orders.filter(
        (ele) => orderDetail.id !== ele.id
      );
      prev.tempEnterprise.proposalFetched = false;
      prev.tempEnterprise.orders = prev.tempEnterprise.orders.filter(
        (ele) => orderDetail.id !== ele.id
      );
      prev.tempEnterprise.info.price = priceUSD;
      localStorage.setItem('enterprise', JSON.stringify(prev.tempEnterprise));
      return prev;
    });
  };

  const cancelHandler = async () => {
    if (orderDetail.status !== 'ACTIVE') {
      return displayError({
        message: ERRORS.ORDER_INACTIVE,
        timeout: 5000,
      });
    }

    const enterpriseHandler = new web3.eth.Contract(
      process.env.REACT_APP_ENV === 'prod' ? EnterpriseABI : EnterpriseDevABI,
      getAddress(orderDetail.enterprise_address)
    );

    setLoadingStatus('CANCEL');
    try {
      await enterpriseHandler.methods.cancelOrder(orderDetail.order_id).send({
        from: account,
        gasLimit: web3.eth.getBlock('latest').gasLimit,
      });

      displaySuccess({ message: 'Order is cancelled', timeout: 5000 });
    } catch (e) {
      setLoadingStatus('');
      displayError({
        message: ERRORS.CANCEL,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
    }
  };

  return (
    <WEModal
      open={open}
      // onClose={() => onClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle sx={{ padding: '0 !important' }} id="alert-dialog-title">
        <ModalHead
          title={'Order Detail'}
          customClose={() => onClose()}
          color="#FF6142"
          theme="dark"
        />
      </DialogTitle>
      <DialogContent sx={{ p: '0px 16px !important' }}>
        <InfoAddr
          label="Maker"
          value={shortAddress(getAddress(orderDetail.owner_address))}
        />
        <Info label="Shares" value={`${orderDetail.amount}`} />
        <Info label="Price" value={`${orderDetail.price} Matic`} />
        <Info
          label="Total"
          value={`${orderDetail.price * orderDetail.amount} Matic`}
        />
        <Stack
          sx={{ pt: '30px', textAlign: 'center' }}
          direction="column"
          gap={2}
        >
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <WELoadingButton
              type="primary"
              width={320}
              rounded="xl"
              loadingPosition="center"
              loading={loadingStatus === 'ACCEPT'}
              loadingIndicator={<CircularProgress color="default" size={20} />}
              onClick={async () => {
                await acceptHandler();
                onClose();
              }}
            >
              Accept
            </WELoadingButton>
          </Box>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <WELoadingButton
              type="primary"
              width={320}
              variant="outlined"
              rounded="xl"
              loadingPosition="center"
              disabled={orderDetail.type === 'BUY'}
              loading={loadingStatus === 'CANCEL'}
              loadingIndicator={<CircularProgress color="primary" size={20} />}
              onClick={async () => {
                await cancelHandler();
                onClose();
              }}
            >
              Cancel
            </WELoadingButton>
          </Box>
        </Stack>
      </DialogContent>
    </WEModal>
  );
};

OrderDetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default OrderDetailModal;
