import React from 'react';
import { BusinessCenter, People, TrendingUp } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HomeAppBar, ModalHead } from '../components/AppBar';
import { WEButton } from '../components/Button';
import Header from '../components/Header';
import LandingInfo from '../components/LandingInfo';
import store from '../constants/store';
import {
  EmptyEnterpriseObject,
  PAGE_NAME,
  STORE_KEYS,
} from '../constants/store/constant';
import { useAutoConnect } from '../utils/AutoConnect';

const CreateEnterpriseSplash = () => {
  const navigate = useNavigate();
  const { account } = useAutoConnect();
  const [, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);

  const newHandler = () => {
    updateEnterprises((prev) => {
      prev.pageName = PAGE_NAME.id.create;
      prev.tempEnterprise = EmptyEnterpriseObject;
      return prev;
    });
    navigate('/create');
  };

  const closeHandler = () => {
    navigate(`/user/${account}`);
  };
  return (
    <>
      <Header pageTitle="Create Enterprise" />
      <HomeAppBar />
      <ModalHead
        title="Create Enterprise"
        close={closeHandler}
        theme="dark"
        // helpEnabled={false}
        color="#28282B"
      />
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: '#F4F4F4',
          padding: '0px 27px',
          // marginTop: { md: "80px" }
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '25px',
              textAlign: 'center',
              py: 5,
              mx: 'auto',
              maxWidth: { xs: '250px', md: 'unset' },
            }}
          >
            Transform your company into World Enterprise
          </Typography>
        </Box>
        <Divider flexItem />
        <Grid
          sx={{
            marginTop: '30px',
          }}
        >
          <LandingInfo
            Icon={BusinessCenter}
            title="Add a Company"
            content={(() => (
              <>
                Create a new World Enterprise or Transform your existing company
                into a <i>World Enterprise</i>
              </>
            ))()}
            sx={{}}
            color="#04C9F2"
          />
          <LandingInfo
            Icon={People}
            title="Invite Shareholders"
            content={(() => (
              <>
                Invite shareholders to become member of the{' '}
                <i>World Enterprise</i>
              </>
            ))()}
            sx={{ marginTop: { xs: '26px', md: '36px' } }}
            color="#FF6142"
          />
          <LandingInfo
            Icon={TrendingUp}
            title="Manage Shares"
            content="Issue or transfer your custom Enterprise ERC20 tokens to other shareholders"
            sx={{ marginTop: { xs: '26px', md: '36px' } }}
            color="#3D61B0"
          />
        </Grid>
        <Box
          sx={{
            marginTop: '54px',
            mx: 'auto',
            width: { xs: '100%', sm: '370px' },
            pb: 4,
            textAlign: 'center',
          }}
        >
          <WEButton type="primary" rounded="xl" onClick={newHandler}>
            Create a World Enterprise
          </WEButton>
        </Box>
      </Grid>
    </>
  );
};

export default CreateEnterpriseSplash;
