/* eslint-disable react/prop-types */
import { HelpOutlineOutlined } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HelpIcon from '@mui/icons-material/Help';
import {
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { ToolTip } from '../Dialog';

const AppBar = ({
  title,
  handle,
  type,
  position,
  toolTipTitle,
  toolTip = '',
  bgColor = '#FFFFFF',
}) => {
  return (
    <Container
      sx={{
        boxShadow: 'none',
        backgroundColor: bgColor,
        position: position === 'absolute' ? 'absolute' : 'relative',
      }}
    >
      <Stack sx={{ justifyContent: 'center', px: 4, py: 3 }}>
        <Typography
          variant="h2"
          component="div"
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            margin: '0 auto',
            flexGrow: 1,
            pr: type === 'none' ? '0px' : '0px',
          }}
        >
          {title}
          {toolTip !== '' && (
            <ToolTip title={toolTip}>
              <IconButton size="small" color="primary">
                <HelpIcon fontSize="medium" color="primary" />
              </IconButton>
            </ToolTip>
          )}
        </Typography>
        {(type === 'add' || type === 'help') && (
          <ToolTip title={toolTipTitle}>
            <IconButton
              sx={{
                position: 'absolute',
                right: '0px',
              }}
              onClick={() => {
                if (handle !== undefined) handle();
              }}
            >
              {type === 'add' && (
                <AddCircleIcon sx={{ color: 'black', fontSize: '30px' }} />
              )}
              {type === 'help' && <HelpOutlineOutlined />}
            </IconButton>
          </ToolTip>
        )}
      </Stack>
      <Divider />
    </Container>
  );
};

export default AppBar;
