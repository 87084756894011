import { ArrowForward } from '@mui/icons-material';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminAdd } from '../../components/Admins';
import { HomeAppBar, ModalHead } from '../../components/AppBar';
import {
  BtnCancel,
  BtnOption,
  ProposalDetailModal,
  Transition,
  WEModal,
} from '../../components/Dialog';
import CommentModal from '../../components/Dialog/CommentModal';
import Header from '../../components/Header';
import { useNotification } from '../../components/Notification/hook';
import ScrollToTop from '../../components/ScrollToTop';
import { ShareholderAdd } from '../../components/Shareholders';
import { ProposalItem } from '../../components/UserDashboard';
import { PROPOSAL_STATUS_ACTIVE } from '../../constants/ProposalStatus';
import EnterpriseABI from '../../constants/abi/enterprise.json';
import EnterpriseDevABI from '../../constants/abi/enterprise_dev.json';
import store from '../../constants/store';
import { EmptyVote, STEP, STORE_KEYS } from '../../constants/store/constant';
import { useWeb3 } from '../../hook/web3';
import { useAutoConnect } from '../../utils/AutoConnect';
import { simpleErrorMessage } from '../../utils/errorMessage';
import { ERRORS } from '../../utils/errors';

const Proposals = () => {
  const { displaySuccess, displayError } = useNotification();
  const { account } = useAutoConnect();
  const location = useLocation();
  const state = location.state;
  const navigation = useNavigate();
  const { wepID, weBack, pTab, proposalID } = state;

  const web3 = useWeb3();

  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [enterprise, setEnterprise] = useState(enterprises.tempEnterprise);

  const initIdx = enterprise?.proposals.findIndex((e) => e.id === proposalID);
  let initDetailOpen = false;
  if (initIdx !== -1) {
    initDetailOpen = true;
  }
  const [proposals, setProposals] = useState([]);
  const [newOpen, setNewOpen] = useState(false);
  const [idx, setIdx] = useState(initIdx);
  const [detailOpen, setDetailOpen] = useState(initDetailOpen);
  const [commentDlgOpen, setCommentDlgOpen] = useState(false);

  const backHandler = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack } });
  };
  // add dialog
  // const addHandler = () => {
  //   setNewOpen(true)
  // }

  const newOpenClose = () => {
    setNewOpen(false);
  };

  const adminHandler = () => {
    setNewOpen(false);
    setStep(STEP.PROPOSAL_ADMIN);
  };
  const shareholderHandler = () => {
    setNewOpen(false);
    setStep(STEP.PROPOSAL_SHAREHOLDER);
  };

  // Detail Dialog
  const onClickItem = (one) => {
    const index = enterprise?.proposals.findIndex((e) => e.id === one.id);
    if (index !== -1) {
      setDetailOpen(true);
      setIdx(index);
    }
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
  };

  const handleReject = async () => {
    if (enterprise?.proposals[idx]?.isApproved) {
      return handleDetailClose();
    }

    if (
      enterprise.proposals[idx]?.type !== 'MINT' &&
      enterprise.proposals[idx]?.walletAddr.toLowerCase() ===
        account.toLowerCase()
    ) {
      return displayError({
        message: "Proposal owner can't vote for this proposal",
        reason: '',
        timeout: 5000,
      });
    }

    try {
      const enterpriseHandler = new web3.eth.Contract(
        process.env.REACT_APP_ENV === 'prod' ? EnterpriseABI : EnterpriseDevABI,
        enterprise.address
      );
      await enterpriseHandler.methods
        .vote(parseInt(enterprise.proposals[idx].idx), false)
        .send({
          from: account,
          gasLimit: web3.eth.getBlock('latest').gasLimit,
        });
      handleDetailClose();
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.address === enterprise.address
        );
        prev.enterprises[index].proposals[idx].isApproved = true;
        prev.enterprises[index].proposals[idx].votesNo += 1;
        prev.tempEnterprise.proposals[idx].isApproved = true;
        prev.tempEnterprise.proposals[idx].votesNo += 1;
        return prev;
      });
      displaySuccess({ message: 'Proposal rejected', timeout: 5000 });
    } catch (e) {
      displayError({
        message: ERRORS.TO_REJECT,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
    }
  };

  const handleAgree = async () => {
    if (enterprise?.proposals[idx]?.isApproved) {
      return;
    }

    if (
      enterprise.proposals[idx]?.type !== 'MINT' &&
      enterprise.proposals[idx]?.walletAddr.toLowerCase() ===
        account.toLowerCase()
    ) {
      return displayError({
        message: "Proposal owner can't vote for this proposal",
        reason: '',
        timeout: 5000,
      });
    }

    try {
      const enterpriseHandler = new web3.eth.Contract(
        process.env.REACT_APP_ENV === 'prod' ? EnterpriseABI : EnterpriseDevABI,
        enterprise.address
      );
      await enterpriseHandler.methods
        .vote(parseInt(enterprise.proposals[idx].idx), true)
        .send({
          from: account,
          gasLimit: web3.eth.getBlock('latest').gasLimit,
        });
      handleDetailClose();
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.address === enterprise.address
        );
        console.log(prev.enterprises[index].proposals, idx);
        const vote = EmptyVote('YES');
        prev.tempEnterprise.proposals[idx].isApproved = true;
        prev.tempEnterprise.proposals[idx].votesYes += 1;
        prev.tempEnterprise.proposals[idx].votes[account.toUpperCase()] = vote;
        localStorage.setItem('enterprise', JSON.stringify(prev.tempEnterprise));
        return prev;
      });
      displaySuccess({ message: 'Proposal accepted', timeout: 5000 });
    } catch (e) {
      console.log(e);
      displayError({
        message: ERRORS.TO_AGREE,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
    }
  };

  const handleExecute = async () => {
    try {
      const enterpriseHandler = new web3.eth.Contract(
        process.env.REACT_APP_ENV === 'prod' ? EnterpriseABI : EnterpriseDevABI,
        enterprise.address
      );
      await enterpriseHandler.methods
        .execute(parseInt(enterprise.proposals[idx].idx))
        .send({
          from: account,
          gasLimit: web3.eth.getBlock('latest').gasLimit,
        });
      handleDetailClose();
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.address === enterprise.address
        );
        prev.enterprises[index].proposals[idx].status = 'EXECUTED';
        return prev;
      });
      displaySuccess({ message: 'Proposal executed', timeout: 5000 });
    } catch (e) {
      displayError({
        message: ERRORS.TO_EXECUTE,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
    }
  };

  // useEffect(() => {
  //   if (!enterprise.address) {
  //     return;
  //   }
  //   // proposals fetched from join-world-enterprise

  //   getProposalsForEnterprise(enterprise.address)
  //     .then((res) => {
  //       let proposals = [];
  //       let origins = res.data?.exists ? res.data?.data : [];
  //       origins.forEach((proposal) => {
  //         let votesYes = 0;
  //         let votesNo = 0;
  //         let isApproved = false;
  //         if (proposal.votes) {
  //           let votes = JSON.parse(proposal.votes);
  //           let vote_addresses = Object.keys(votes);
  //           vote_addresses.forEach((address) => {
  //             // eslint-disable-line no-loop-func
  //             if (address.toUpperCase() === account.toUpperCase()) {
  //               isApproved = true;
  //             }
  //             let content = votes[address];
  //             if (content.approve === 'YES') {
  //               votesYes++;
  //             }

  //             if (content.approve === 'NO') {
  //               votesNo++;
  //             }
  //           });
  //         }
  //         proposals = [
  //           ...proposals,
  //           {
  //             type: proposal.type ? proposal.type : 'join',
  //             walletAddr: proposal.proposer,
  //             enterpriseAddr: enterprise.address,
  //             state: proposal.isApproved ? proposal.isApproved : false,
  //             votesYes: votesYes,
  //             votesNo: votesNo,
  //             amount: proposal.amount,
  //             commentUrl: proposal?.commentUrl,
  //             status: proposal?.status,
  //             startTime: Number(proposal?.startTime),
  //             endTime: Number(proposal?.endTime),
  //             id: proposal?.id,
  //             isApproved: isApproved,
  //           },
  //         ];
  //       });
  //       setProposals(proposals);
  //     })
  //     .catch((reason) => {
  //       setProposals([]);
  //       console.error(
  //         //eslint-disable-line no-console
  //         `Failed to load proposals for account: ${enterprise.address}`,
  //         reason
  //       );
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [enterprise]);

  useEffect(() => {
    setEnterprise(enterprises.tempEnterprise);
    console.log(enterprises.tempEnterprise.proposals);
    // const prs = enterprises.tempEnterprise.proposals.map((proposal) => {
    //   return {
    //     type: proposal.type ? proposal.type : 'join',
    //     walletAddr: proposal.proposer,
    //     enterpriseAddr: enterprise.address,
    //     state: proposal.isApproved ? proposal.isApproved : false,
    //     votesYes: proposal.votesYes,
    //     votesNo: proposal.votesNo,
    //     amount: proposal.amount,
    //     commentUrl: proposal?.commentUrl,
    //     status: proposal?.status,
    //     startTime: Number(proposal?.startTime),
    //     endTime: Number(proposal?.endTime),
    //     id: proposal?.id,
    //     isApproved: proposal.isApproved,
    //   };
    // });
    setProposals(enterprises.tempEnterprise.proposals);
  }, [enterprises]);

  useEffect(() => {
    setStep(STEP.INDEX);
    if (enterprise.address) {
      localStorage.setItem(
        'enterprise',
        JSON.stringify(enterprises.tempEnterprise)
      );
    } else {
      const twe = JSON.parse(localStorage.getItem('enterprise'));
      updateEnterprises((prev) => {
        prev.tempEnterprise = twe;
        return prev;
      });
      setEnterprise(twe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ScrollToTop />
      <Header pageTitle={'Proposals'} />
      <HomeAppBar position="absolute" />
      <Box sx={{ bgcolor: '#E5E5E5', minHeight: 'calc(100vh - 66px)' }}>
        <ModalHead
          title={enterprise.info.name}
          close={() => backHandler()}
          color="#FFDB0B"
        />
        {stepState === STEP.PROPOSAL_ADMIN && <AdminAdd />}
        {stepState === STEP.PROPOSAL_SHAREHOLDER && <ShareholderAdd />}
        {stepState === STEP.INDEX && (
          <>
            <Box sx={{ px: 4, py: 4.3 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{ fontSize: '16px', fontWeight: '600', margin: '0 auto' }}
                >
                  {(() => {
                    switch (pTab) {
                      case 'shareholders':
                        return 'Proposals';
                      case 'admins':
                        return 'Proposals to be executed';
                      default:
                        return '';
                    }
                  })()}
                </Typography>
              </Stack>
            </Box>
            <Divider />

            <Box sx={{ padding: '5px 16px' }}>
              <Grid
                container
                component="main"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  px: 0,
                  mt: proposals.length === 0 ? 0 : 0, // remain for further update
                }}
              >
                <List dense={true} sx={{ width: '100%', pt: 2, px: 0 }}>
                  {proposals.map((proposal, index) => {
                    if (pTab === 'shareholders') {
                      return (
                        <ProposalItem
                          key={index}
                          proposal={proposal}
                          index={index}
                          isMie={proposal.isApproved}
                          openModal={onClickItem}
                        />
                      );
                    } else if (
                      pTab === 'admins' &&
                      proposal.status === PROPOSAL_STATUS_ACTIVE &&
                      (proposal.votesYes * 2 >= enterprise?.info.memNum ||
                        proposal.votesNo * 2 >= enterprise?.info.memNum) &&
                      Date.now() * 0.001 >= proposal.startTime + 604800 // check if current time is two weeks after created date
                    ) {
                      return (
                        <ProposalItem
                          key={index}
                          proposal={proposal}
                          index={index}
                          isMie={proposal.isApproved}
                          openModal={onClickItem}
                        />
                      );
                    }
                  })}
                </List>
              </Grid>
            </Box>
            <CommentModal
              open={commentDlgOpen}
              onClose={() => setCommentDlgOpen(false)}
              id={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].id.toString()) ||
                ''
              }
              idx={idx}
              comments={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].comments) ||
                []
              }
            />
            <ProposalDetailModal
              open={detailOpen}
              handleClose={handleDetailClose}
              handleCommentOpen={() => setCommentDlgOpen(true)}
              reject={handleReject}
              agree={handleAgree}
              execute={handleExecute}
              name={
                (enterprise.proposals[idx] && enterprise.proposals[idx].name) ||
                ''
              }
              walletAddr={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].walletAddr) ||
                ''
              }
              idx={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].idx.toString()) ||
                ''
              }
              id={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].id.toString()) ||
                ''
              }
              yesNum={
                enterprise.proposals[idx] && enterprise.proposals[idx].votesYes
              }
              noNum={
                enterprise.proposals[idx] && enterprise.proposals[idx].votesNo
              }
              totalNum={enterprise.proposals[idx] && enterprise?.info?.memNum}
              commentUrl={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].commentUrl) ||
                ''
              }
              comments={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].comments) ||
                []
              }
              isApproved={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].isApproved) ||
                ''
              }
              status={enterprise?.proposals[idx]?.status || ''}
              endTime={enterprise?.proposals[idx]?.endTime || ''}
            />
            <WEModal
              open={newOpen}
              onClose={newOpenClose}
              TransitionComponent={Transition}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth="xl"
            >
              <DialogTitle id="alert-dialog-title">
                <span>{'New Proposal'}</span>
              </DialogTitle>
              <Divider />
              <DialogContent>
                <BtnOption onClick={adminHandler}>
                  <span>Propose Admin</span>
                  <ArrowForward htmlColor="#BCC0C4" />
                </BtnOption>
                <BtnOption onClick={shareholderHandler}>
                  <span>Propose Shareholder</span>
                  <ArrowForward htmlColor="#BCC0C4" />
                </BtnOption>
              </DialogContent>
              <Divider />
              <DialogActions>
                <BtnCancel onClick={newOpenClose}>Cancel</BtnCancel>
              </DialogActions>
            </WEModal>
          </>
        )}
      </Box>
    </>
  );
};

export default Proposals;
