import { Box, CircularProgress, FormHelperText, Grid } from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { upsertForum } from '../../apis';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import { useAutoConnect } from '../../utils/AutoConnect';
import { AppBar } from '../AppBar';
import { WELoadingButton } from '../Button';
import { Input, InputLabel } from '../Input';

const ForumEdit = () => {
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [step, setStep] = store.useState(STORE_KEYS.id.step);
  const [isAdding, setIsAdding] = React.useState(false);
  const [valid, setValid] = React.useState({
    title: true,
    content: true,
  });
  const { account, accountInfo } = useAutoConnect();
  const enterprise = enterprises.tempEnterprise;
  // const eforum =
  //   enterprise.forums[enterprise.updateableForumId];

  const [forum, setforum] = React.useState({
    id: uuidv4(),
    title: '',
    content: '',
    comments: [],
    enterprise_address: enterprise.address,
    creator: account,
    logo: accountInfo.image_url,
    username: accountInfo.username,
    created_at: Date.now() / 10 ** 3,
    updated_at: Date.now() / 10 ** 3,
  });

  // validation
  const handleValidation = () => {
    setValid({
      title: !!forum.title && !!forum.title.trim(),
      content: !!forum.content && !!forum.content.trim(),
    });
  };
  // input handler
  const handleInputChange = (type) => (evt) => {
    const value = evt.currentTarget.value;
    switch (type) {
      case 'title':
        setValid((prev) => {
          return {
            ...prev,
            title: !!value && !!value.trim(),
          };
        });
        setforum((prev) => {
          return {
            ...prev,
            title: value,
          };
        });
        break;
      case 'content':
        setValid((prev) => {
          return {
            ...prev,
            content: !!value && !!value.trim(),
          };
        });
        setforum((prev) => {
          return {
            ...prev,
            content: value,
          };
        });
        break;
      default:
        break;
    }
  };

  const backHandler = () => {
    setStep(STEP.DASHBOARD_FORUM);
  };
  const continueHandler = async () => {
    handleValidation();
    if (forum.title.trim() && forum.content.trim()) {
      setIsAdding(true);
      try {
        let res = await upsertForum(forum);
        if (res.data.success) {
          switch (step) {
            case STEP.DASHBOARD_FORUM_ADD:
              updateEnterprises((prev) => {
                prev.tempEnterprise.forums = [
                  forum,
                  ...prev.tempEnterprise.forums,
                ];
                return prev;
              });
              break;
            case STEP.DASHBOARD_FORUM_EDIT:
              updateEnterprises((prev) => {
                const index = prev.tempEnterprise.forums.findIndex(
                  (bs) => bs.id === forum.id
                );
                prev.tempEnterprise.forums[index] = forum;
                prev.tempEnterprise.updateableForumId = -1;
                return prev;
              });
              break;
            default:
              break;
          }
          setStep(STEP.DASHBOARD_FORUM);
        }
      } catch {
        console.log('Failed to Update or Add topic'); //eslint-disable-line no-console
      }
      setIsAdding(false);
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        title={(() => {
          switch (step) {
            case STEP.DASHBOARD_FORUM_ADD:
              return 'Add a new topic';
            case STEP.DASHBOARD_FORUM_EDIT:
              return 'Edit a topic';
            default:
              return '';
          }
        })()}
        back={backHandler}
        handle={() => {}}
        type="none"
        toolTipTitle={(() => {
          switch (step) {
            case STEP.DASHBOARD_FORUM_ADD:
              return 'Add a new topic';
            case STEP.DASHBOARD_FORUM_EDIT:
              return 'Edit a topic';
            default:
              return '';
          }
        })()}
      />
      <Grid
        container
        component="main"
        spacing={2}
        direction="row"
        sx={{
          px: 3,
          mt: 5,
        }}
      >
        <Grid item xs={12}>
          <InputLabel shrink htmlFor="title">
            Title
          </InputLabel>
          <Input
            id="title"
            value={forum.title}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('title')}
            error={!valid.title}
          />
          {!valid.title && (
            <FormHelperText id="helper-title" error>
              Please enter title.
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} mt={2}>
          <InputLabel shrink htmlFor="content">
            Content
          </InputLabel>
          <Input
            id="content"
            value={forum.content}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('content')}
            multiline={true}
            rows={10}
            error={!valid.content}
          />
          {!valid.content && (
            <FormHelperText id="helper-content" error>
              Please enter content.
            </FormHelperText>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              mx: 'auto',
              mt: 2,
              mb: 3,
              width: '90%',
              textAlign: 'center',
            }}
          >
            <WELoadingButton
              loading={isAdding}
              onClick={continueHandler}
              type="primary"
              width={320}
              size="medium"
              rounded="xl"
              loadingPosition="center"
              loadingIndicator={<CircularProgress color="default" size={20} />}
            >
              Submit
            </WELoadingButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ForumEdit;
