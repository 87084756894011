import { Box, CircularProgress, FormHelperText, Grid } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { upsertBusinessUpdate } from '../../apis';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import { useAutoConnect } from '../../utils/AutoConnect';
import { AppBar } from '../AppBar';
import { WELoadingButton } from '../Button';
import { Input, InputLabel } from '../Input';

const BsUpdateEdit = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const state = location.state;
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [step, setStep] = store.useState(STORE_KEYS.id.step);
  const [isAdding, setIsAdding] = React.useState(false);
  const [valid, setValid] = React.useState({
    title: true,
    content: true,
  });
  const { account } = useAutoConnect();
  const enterprise = enterprises.tempEnterprise;
  const eBsUpdate =
    enterprise.businessUpdates[enterprise.updateableBusinessUpdateId];

  const [bsUpdate, setBsUpdate] = React.useState({
    id: eBsUpdate ? eBsUpdate.id : uuidv4(),
    title: eBsUpdate ? eBsUpdate.title : '',
    content: eBsUpdate ? eBsUpdate.content : '',
    enterprise_address: enterprise.address,
    creator: account,
    created_at: eBsUpdate ? eBsUpdate.created_at : Date.now() / 10 ** 3,
    updated_at: Date.now() / 10 ** 3,
  });

  // validation
  const handleValidation = () => {
    setValid({
      title: !!bsUpdate.title && !!bsUpdate.title.trim(),
      content: !!bsUpdate.content && !!bsUpdate.content.trim(),
    });
  };
  // input handler
  const handleInputChange = (type) => (evt) => {
    const value = evt.currentTarget.value;
    switch (type) {
      case 'title':
        setValid((prev) => {
          return {
            ...prev,
            title: !!value && !!value.trim(),
          };
        });
        setBsUpdate((prev) => {
          return {
            ...prev,
            title: value,
          };
        });
        break;
      case 'content':
        setValid((prev) => {
          return {
            ...prev,
            content: !!value && !!value.trim(),
          };
        });
        setBsUpdate((prev) => {
          return {
            ...prev,
            content: value,
          };
        });
        break;
      default:
        break;
    }
  };

  const backHandler = () => {
    setStep(STEP.DASHBOARD_BSUPDATE);
  };
  const continueHandler = async () => {
    handleValidation();
    if (bsUpdate.title.trim() && bsUpdate.content.trim()) {
      setIsAdding(true);
      try {
        let res = await upsertBusinessUpdate(bsUpdate);
        if (res.data.success) {
          switch (step) {
            case STEP.DASHBOARD_BSUPDATE_ADD:
              updateEnterprises((prev) => {
                prev.tempEnterprise.businessUpdates = [
                  bsUpdate,
                  ...prev.tempEnterprise.businessUpdates,
                ];
                return prev;
              });
              break;
            case STEP.DASHBOARD_BSUPDATE_DIRECT_EDIT:
            case STEP.DASHBOARD_BSUPDATE_EDIT:
              updateEnterprises((prev) => {
                const index = prev.tempEnterprise.businessUpdates.findIndex(
                  (bs) => bs.id === bsUpdate.id
                );
                prev.tempEnterprise.businessUpdates[index] = bsUpdate;
                prev.tempEnterprise.updateableBusinessUpdateId = -1;
                return prev;
              });
              break;
            default:
              break;
          }
          if (STEP.DASHBOARD_BSUPDATE_DIRECT_EDIT) {
            if (state.pTab) {
              navigation('/wepID/dashboard', { state: { pTab: state.pTab } });
            } else {
              navigation(-1);
            }
          }
          setStep(STEP.DASHBOARD_BSUPDATE);
        }
      } catch {
        console.log('Failed to Update or Add business update'); //eslint-disable-line no-console
      }
      setIsAdding(false);
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        title={(() => {
          switch (step) {
            case STEP.DASHBOARD_BSUPDATE_ADD:
              return 'Add a new business update';
            case STEP.DASHBOARD_BSUPDATE_EDIT:
              return 'Edit a business update';
            default:
              return '';
          }
        })()}
        back={backHandler}
        handle={() => {}}
        type="none"
        toolTipTitle={(() => {
          switch (step) {
            case STEP.DASHBOARD_BSUPDATE_ADD:
              return 'Add a new business update';
            case STEP.DASHBOARD_BSUPDATE_EDIT:
              return 'Edit a business update';
            default:
              return '';
          }
        })()}
      />
      <Grid
        container
        component="main"
        spacing={2}
        direction="row"
        sx={{
          px: 3,
          mt: 5,
        }}
      >
        <Grid item xs={12}>
          <InputLabel shrink htmlFor="title">
            Title
          </InputLabel>
          <Input
            id="title"
            value={bsUpdate.title}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('title')}
            error={!valid.title}
          />
          {!valid.title && (
            <FormHelperText id="helper-title" error>
              Please enter title.
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} mt={2}>
          <InputLabel shrink htmlFor="content">
            Content
          </InputLabel>
          <Input
            id="content"
            value={bsUpdate.content}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('content')}
            multiline={true}
            rows={10}
            error={!valid.content}
          />
          {!valid.content && (
            <FormHelperText id="helper-content" error>
              Please enter content.
            </FormHelperText>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              mx: 'auto',
              mt: 2,
              mb: 3,
              width: '90%',
              textAlign: 'center',
            }}
          >
            <WELoadingButton
              loading={isAdding}
              onClick={continueHandler}
              type="primary"
              width={320}
              size="medium"
              rounded="xl"
              loadingPosition="center"
              loadingIndicator={<CircularProgress color="default" size={20} />}
            >
              Submit
            </WELoadingButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BsUpdateEdit;
