import {
  ArrowForwardIos,
  CreditCard,
  EditNotificationsOutlined,
  WorkOutline,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WEProfileAppBar } from '../../components/AppBar';
import {
  EnterpriseCreate,
  EnterpriseReview,
} from '../../components/Enterprise';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import PropTypes from 'prop-types';

const Settings = () => {
  const [step, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises] = store.useState(STORE_KEYS.id.enterprises);
  const navigation = useNavigate();
  const enterprise = enterprises.tempEnterprise;

  const back = () => {
    navigation(-1);
  };

  const companyProfile = () => {
    setStep(STEP.SETTING_COMPANY_REVIEW);
  };
  const notificationSetting = () => {};
  const currency = () => {};

  useEffect(() => {
    if (step === STEP.INDEX) {
      setStep(STEP.SETTING_COMPANY_REVIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <>
      {step === STEP.INDEX && (
        <>
          <WEProfileAppBar
            background={enterprise.info.background}
            logo={enterprise.info.logo}
            name={enterprise.info.name}
            ID={enterprise.info.wepID || ''}
            back={back}
            isAdmin={true}
          />
          <Box mt={-6} px={2} py={3} sx={{ backgroundColor: '#FFFFFF' }}>
            <Typography variant="h5">Enterprise setting</Typography>
            <Divider sx={{ mt: 1 }} />
            <List dense={true} sx={{ width: '100%', mt: 0, p: 0 }}>
              <Item
                Icon={WorkOutline}
                title="Enterprise Details"
                onClick={companyProfile}
              />
              <Item
                Icon={EditNotificationsOutlined}
                title="Notification Settings"
                onClick={notificationSetting}
              />
              <Item
                Icon={CreditCard}
                title="Currency Settings"
                optional="USD($)"
                onClick={currency}
              />
            </List>
          </Box>
        </>
      )}
      {step === STEP.SETTING_COMPANY_REVIEW && (
        <EnterpriseReview isSetting={true} />
      )}
      {step === STEP.SETTING_COMPANY_EDIT && <EnterpriseCreate />}
    </>
  );
};

export const Item = ({ Icon, title, optional, onClick }) => {
  return (
    <>
      <ListItemButton
        component="div"
        sx={{ px: 0, py: 1 }}
        onClick={() => {
          onClick();
        }}
      >
        <ListItemAvatar sx={{ minWidth: '0px', mr: 2 }}>
          <Icon htmlColor="#241F21" />
        </ListItemAvatar>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '15px',
            lineHeight: '18px',
            color: '#241F21',
          }}
        />
        {optional && (
          <ListItemText
            primary={optional}
            primaryTypographyProps={{
              textAlign: 'right',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '15px',
              lineHeight: '18px',
              color: '#3D61B0',
            }}
          />
        )}
        <IconButton edge="end" sx={{ mr: 0 }}>
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </ListItemButton>
      <Divider />
    </>
  );
};

Item.propTypes = {
  Icon: PropTypes.any,
  title: PropTypes.string,
  optional: PropTypes.string,
  onClick: PropTypes.func,
};

Item.defaultProps = {
  title: null,
  value: null,
};

export default Settings;
