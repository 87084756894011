import React, { useState } from 'react';
import {
  AccountCircle,
  ArrowBack,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { Avatar, Box, IconButton, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import store from '../../constants/store';
import { PAGE_NAME, STORE_KEYS } from '../../constants/store/constant';
import { useAutoConnect } from '../../utils/AutoConnect';
import SelectWalletModal from '../Dialog/SelectWalletModal';
import { useNotification } from '../Notification/hook';
import MainMenu from './MainMenu';
import NavLink from './NavLink';
import UserMenu from './UserMenu';
import WEAppBar from './WEAppBar';
import PropTypes from 'prop-types';

const HomeAppBar = ({ back, isEnterpriseEdited = false }) => {
  const { account, onDisconnect } = useAutoConnect();
  const { displayConfirm } = useNotification();
  const navigate = useNavigate();
  const [anchorMainMenu, setanchorMainMenu] = useState();
  const [anchorUserMenu, setanchorUserMenu] = useState();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleLogin = () => {
    if (account && enterprises.registeredUser) {
      navigate(`/user/${account}`);
    } else {
      setIsOpen(true);
    }
  };

  const Close = () => {
    setIsOpen(false);
  };

  const handleMainMenu = (event) => {
    setanchorMainMenu(event.currentTarget);
  };

  const handleMainClose = () => {
    setanchorMainMenu(null);
  };

  const handleUserMenu = (event) => {
    setanchorUserMenu(event.currentTarget);
  };

  const handleUserClose = () => {
    setanchorUserMenu(null);
  };

  const handleNav = (typee) => () => {
    if (isEnterpriseEdited) {
      displayConfirm({
        message: 'Please Update or edits won`t be saved.',
        timeout: 5000,
        onOk: () => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        },
        onExit: () => {
          handleNavBar(typee);
        },
      });
    } else {
      handleNavBar(typee);
    }
  };

  const handleNavBar = (typee) => {
    switch (typee) {
      case 'login':
        handleLogin();
        break;
      case 'home':
        updateEnterprises((prev) => {
          prev.pageName = PAGE_NAME.id.home;
          return prev;
        });
        navigate('/');
        break;

      case 'forum':
        window.location.href = 'https://discourse.worldenterprise.io/';
        break;

      case 'setting':
        updateEnterprises((prev) => {
          prev.pageName = PAGE_NAME.id.setting;
          return prev;
        });
        navigate('/setting');
        break;

      case 'about':
        updateEnterprises((prev) => {
          prev.pageName = PAGE_NAME.id.about;
          return prev;
        });
        navigate('/about');
        break;

      case 'dashboard':
        updateEnterprises((prev) => {
          const index = prev.enterprises?.findIndex(
            (enterprise) =>
              enterprise.info.wepID === prev.tempEnterprise.info.wepID
          );

          if (index >= 0) {
            prev.enterprises[index] = prev.tempEnterprise;
            return prev;
          }
        });
        navigate(`/user/${account}`);
        break;

      case 'disconnect':
        onDisconnect();
        break;

      case 'faq':
        navigate('/faq');
        break;

      default:
        break;
    }
    handleMainClose();
    handleUserClose();
  };

  const handleLogo = () => {
    if (account) {
      handleNavBar('dashboard');
    } else {
      updateEnterprises((prev) => {
        prev.pageName = PAGE_NAME.id.home;
        return prev;
      });
      navigate('/');
    }
  };

  return (
    <>
      <Box
        component={'header'}
        sx={{
          // position: 'absolute',
          width: '100%',
          m: 0,
          maxWidth: '5000px!important',
          backgroundColor: '#E5E5E5',
          top: '0px',
        }}
      >
        <WEAppBar component="div" position={'relative'}>
          <Toolbar
            sx={{
              display: 'flex',
              px: '0!important',
              alignItems: 'center',
              backgroundColor: 'transparent',
              justifyContent: 'space-between',
              minHeight: 'unset !important',
              height: '34px',
            }}
          >
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="small"
                edge="start"
                color="primary"
                aria-label="menu"
                sx={{ p: 0 }}
                onClick={handleMainMenu}
              >
                <MenuIcon fontSize="small" />
              </IconButton>
              <MainMenu
                anchorEl={anchorMainMenu}
                handleClose={handleMainClose}
                handleNav={handleNav}
              />
            </Box>
            {!back && (
              <Box
                component="img"
                src="/images/World_Enterprise_OneLine.svg"
                alt=""
                onClick={handleLogo}
                sx={{
                  width: {
                    xs: '200px',
                  },
                  mb: { xs: '0', md: '0' },
                  cursor: 'pointer',
                }}
              />
            )}
            {back && (
              <IconButton
                sx={{
                  ml: '-10px',
                }}
                onClick={() => {
                  back();
                }}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {account && enterprises.registeredUser && (
                <NavLink page="Dashboard" onClick={handleNav('dashboard')} />
              )}
              <NavLink page="Forum" onClick={handleNav('forum')} />
              <NavLink page="About" onClick={handleNav('about')} />
              <NavLink page="FAQ" onClick={handleNav('faq')} />
            </Box>
            <Box>
              <Avatar
                onClick={handleUserMenu}
                sx={{
                  width: '32px',
                  height: '32px',
                }}
              >
                {account && enterprises.registeredUser ? (
                  <>
                    {enterprises.photo !== '' &&
                    enterprises.photo !== undefined ? (
                      <img
                        alt=""
                        src={enterprises.photo}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      <AccountCircle fontSize="medium" />
                    )}
                  </>
                ) : (
                  <AccountCircle
                    sx={{
                      width: '32px',
                      height: '32px',
                    }}
                  />
                )}
              </Avatar>
            </Box>
            <UserMenu
              anchorEl={anchorUserMenu}
              handleClose={handleUserClose}
              handleNav={handleNav}
            />
          </Toolbar>
        </WEAppBar>
      </Box>
      <SelectWalletModal isOpen={isOpen} closeModal={Close} />
    </>
  );
};

HomeAppBar.propTypes = {
  back: PropTypes.func,
  isEnterpriseEdited: PropTypes.bool,
};

HomeAppBar.defaultProps = {
  back: null,
  isEnterpriseEdited: false,
};

export default HomeAppBar;
