import { PROPOSAL_TYPE_MINT } from '../ProposalTypes';

export const STORE_KEYS = {
  id: {
    enterprises: 'enterprises',
    step: 'step',
    sstep: 'sstep', // Steps for Setting page
    notification: 'notification',
    shares: 'shares',
    activities: 'activities',
    tabValue: 0,
    secondTabValue: 3,
  },
};

export const STEP = {
  INDEX: 'INDEX',

  // Enterprise Create
  CREATE_ENTERPRISE_INPUT: 'CREATE_ENTERPRISE_INPUT',
  CREATE_SHAREHOLDERS_VIEW: 'CREATE_SHAREHOLDERS_VIEW',
  CREATE_SHAREHOLDER_ADD: 'CREATE_SHAREHOLDER_ADD',
  CREATE_SHAREHOLDER_EDIT: 'CREATE_SHAREHOLDER_EDIT',
  CREATE_ADMINS_VIEW: 'CREATE_ADMINS_VIEW',
  CREATE_ADMIN_ADD: 'CREATE_ADMIN_ADD',
  CREATE_ADMIN_EDIT: 'CREATE_ADMIN_EDIT',
  CREATE_PROCESSING: 'CREATE_PROCESSING',
  CREATE_SUCCESSED: 'CREATE_SUCCESSED',

  SHAREHOLDER_ADD: 'SHAREHOLDER_ADD',

  ADMIN_ADD: 'ADMIN_ADD',
  ADMIN_EDIT: 'ADMIN_EDIT',

  SETTING_COMPANY_REVIEW: 'SETTING_COMPANY_REVIEW',
  SETTING_COMPANY_EDIT: 'SETTING_COMPANY_EDIT',

  SETTING_USER_REVIEW: 'SETTING_USER_REVIEW',
  SETTING_USER_EDIT: 'SETTING_USER_EDIT',

  PROPOSAL_ADMIN: 'PROPOSAL_ADMIN',
  PROPOSAL_SHAREHOLDER: 'PROPOSAL_SHAREHOLDER',

  DASHBOARD_ADMIN_EDIT: 'DASHBOARD_ADMIN_EDIT',
  DASHBOARD_MEMBERS: 'DASHBOARD_MEMBERS',
  DASHBOARD_MEMBERS_ADMIN_ADD: 'DASHBOARD_MEMBERS_ADMIN_ADD',
  DASHBOARD_MEMBERS_ADMIN_EDIT: 'DASHBOARD_MEMBERS_ADMIN_EDIT',
  DASHBOARD_MEMBERS_SHAREHOLDER_EDIT: 'DASHBOARD_MEMBERS_SHAREHOLDER_EDIT',
  DASHBOARD_MEMBERS_SHAREHOLDER_ADD: 'DASHBOARD_MEMBERS_SHAREHOLDER_ADD',
  DASHBOARD_TRADE_ADD: 'DASHBOARD_TRADE_ADD',
  DASHBOARD_TRADE_ORDERBOOK: 'DASHBOARD_TRADE_ORDERBOOK',

  DASHBOARD_BSUPDATE: 'DASHBOARD_BSUPDATE',
  DASHBOARD_BSUPDATE_ADD: 'DASHBOARD_BSUPDATE_ADD',
  DASHBOARD_BSUPDATE_EDIT: 'DASHBOARD_BSUPDATE_EDIT',
  DASHBOARD_BSUPDATE_DIRECT_EDIT: 'DASHBOARD_BSUPDATE_DIRECT_EDIT',

  DASHBOARD_FORUM: 'DASHBOARD_FORUM',
  DASHBOARD_FORUM_ADD: 'DASHBOARD_FORUM_ADD',
  DASHBOARD_FORUM_EDIT: 'DASHBOARD_FORUM_EDIT',

  DASHBOARD_COMMENT: 'DASHBOARD_COMMENT',
  DASHBOARD_COMMENT_ADD: 'DASHBOARD_COMMENT_ADD',
};

export const TxAssets = ['USDC', 'MATIC', 'TOKEN'];
export const MoneyType = {
  MATIC: 'MATIC',
};
export const MoneyTypes = [MoneyType.MATIC];
export const TXTYPE = {
  MONEY_IN: 'Receive money',
  MONEY_OUT: 'Send money',
  SHARE_OUT: 'Share Transfer',
};
export const TxTypes = [TXTYPE.SHARE_OUT, TXTYPE.MONEY_IN, TXTYPE.MONEY_OUT];
export const TxCategories = [
  'Expense',
  'Office Rent',
  'Salary',
  'Food',
  'Travel',
];
export const TransactionType = ['All', 'Sent', 'Received'];
export const TransactionCategory = [
  'Send money',
  'Receive money',
  'Share transfer',
];
export const isTxAsset = (val) => TxAssets.includes(val);
export const isTxType = (val) => TxTypes.includes(val);
export const isTxCategory = (val) => TxCategories.includes(val);

export const PAGE_NAME = {
  id: {
    welcome: 'Welcome Page',
    join: 'Join Page',
    create: 'Create Page',
    about: 'About us',
    home: 'Home',
  },
};

export const EmptyEnterpriseObject = {
  info: {
    name: '',
    logo: '',
    tokenName: '',
    description: '',
    isRegisterd: false,
    type: '',
    website: '',
    wepID: '',
    price: 0,
    memNum: 0,
    address: '',
    metaUrl: '',
    totalShare: 0,
    created_at: 0,
  },
  numOfShare: 0,
  businessUpdates: [],
  admins: [],
  shareholders: [],
  proposals: [],
  transactions: [],
  orders: [],
  mine: false,
  joined: false,
  isShareholder: false,
  fetched: false,
};

export const EmptyEnterprise = () => {
  return {
    info: {
      name: '',
      logo: '',
      tokenName: '',
      description: '',
      isRegisterd: false,
      type: '',
      website: '',
      wepID: '',
      price: 0,
      memNum: 0,
      address: '',
      metaUrl: '',
      created_at: 0,
    },
    businessUpdates: [],
    forums: [],
    admins: [],
    shareholders: [],
    proposals: [],
    transactions: [],
    orders: [],
    mine: false,
    joined: false,
    isShareholder: false,
    fetched: false,
  };
};

export const EmptyShareholder = ({
  walletAddr = '',
  numOfShare = 0,
  firstName = '',
  lastName = '',
}) => {
  return {
    walletAddr,
    numOfShare,
    firstName,
    lastName,
  };
};

export const EmptyAdmin = () => {
  return {
    walletAddr: '',
    isActive: false,
    fullName: '',
    email: '',
    phone: '',
  };
};

export const EmptyShare = () => {
  return {
    img: '',
    name: '',
    price: 0,
    amount: 0,
    tokenName: '',
    wepID: '',
    mine: false,
    isShareholder: false,
  };
};

export const EmptyTransaction = () => {
  return {
    id: 0,
    amount: 0,
    asset: '',
    category: TxCategories[0],
    created_at: 0,
    enterprise_address: '',
    from: '',
    note: '',
    to: '',
    token: '',
    type: TXTYPE.MONEY_IN,
  };
};

export const ProposalType = {
  ADMIN: 'admin',
  SHAREHOLDER: 'shareholder',
};

export const EmptyProposal = ({
  idx = '',
  id = 0,
  name = '',
  type = PROPOSAL_TYPE_MINT,
  votesYes = 0,
  votesNo = 0,
  votes = {},
  walletAddr = '',
  enterpriseAddr = '',
  isApproved = false,
  comments = [],
  comment = '',
  state = '',
  amount = '',
  commentUrl = '',
  status = '',
  startTime = 0,
  endTime = 0,
  logoImg = '',
  price = 0,
  candidate = '',
  proposer = '',
  token_name = '',
  admin = '',
  edited = false, // edited in frontend
}) => {
  return {
    idx,
    id,
    name,
    type,
    votesYes,
    votesNo,
    votes,
    walletAddr,
    enterpriseAddr,
    isApproved,
    comments,
    comment,
    state,
    amount,
    commentUrl,
    status,
    startTime,
    endTime,
    logoImg,
    price,
    candidate,
    proposer,
    token_name,
    admin,
    edited,
  };
};

export const EmptyVote = (approve) => {
  return {
    approve: approve,
    voted_at: Date.now() * 0.001,
  };
};

export const OrderCategory = {
  BUY: 'BUY',
  SELL: 'SELL',
};

export const OrderStatus = {
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
};

export const EmptyOrder = () => {
  return {
    id: 0,
    type: OrderCategory.BUY,
    amount: 0,
    price: 0,
    date: 0,
    owner_address: '',
    created_at: 0,
    status: OrderStatus.ACTIVE,
    enterprise_address: '',
  };
};

export const EmptyActivity = () => {
  return {
    name: '',
    description: '',
    percent1: 0,
    percent2: 0,
    votesYes: 0,
    votesNo: 0,
    wepID: '',
  };
};

export const cookiesConstants = {
  IS_LOGGED_IN: 'isLoggedIn',
};

export const phoneVerifyStatus = {
  INIT: 'INIT',
  SHOW: 'SHOW',
  HIDE: 'HIDE',
};

export const DefaultActivity = {
  name: 'Enterprise name',
  type: 'Proposal type',
  walletAddr: '0x7237Bc33CffD232017D36260F63cF3e7c86DAeF8',
  percent1: 52,
  percent2: 9.0,
  votesYes: 0,
  votesNo: 0,
  wepID: '',
  isApproved: false,
};
