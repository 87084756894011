import React from 'react';
import { Close, HelpOutlineOutlined } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const CreateAppBar = ({ title, close, helpEnabled, bgColor = '#FFFFFF' }) => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: bgColor,
        boxShadow: 'none',
        borderBottom: '2px solid #E3E8EB',
      }}
    >
      <Toolbar sx={{ px: 3, marginTop: { xs: 0 } }}>
        <Typography
          variant="h2"
          component="div"
          sx={{
            flexGrow: 1,
            pr: { sm: !helpEnabled ? '36px!important' : '0px', xs: '0px' },
            fontSize: {
              xs: '15px',
              sm: '20px',
            },
          }}
        >
          {title}
        </Typography>
        {helpEnabled && (
          <IconButton>
            <HelpOutlineOutlined />
          </IconButton>
        )}

        <IconButton
          size="large"
          edge="end"
          color="primary"
          aria-label="menu"
          onClick={() => {
            close();
          }}
        >
          <Close fontSize="medium" htmlColor="#4B4749" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

CreateAppBar.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func,
  helpEnabled: PropTypes.bool,
  bgColor: PropTypes.string,
};

CreateAppBar.defaultProps = {
  title: null,
  close: null,
  helpEnabled: false,
  bgColor: '#FFFFFF',
};

export default CreateAppBar;
