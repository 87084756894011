import { AddCircleOutline, ArrowBack } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import { Container, IconButton, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { ToolTip } from '../Dialog';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const CreateEnterpriseToolBar = ({
  title,
  back,
  handle,
  type,
  position,
  toolTipTitle,
  toolTip = '',
  bgColor = 'transparent',
}) => {
  const location = useLocation();

  return (
    <Container
      sx={{
        boxShadow: 'none',
        backgroundColor: bgColor,
        position: position === 'absolute' ? 'absolute' : 'relative',
      }}
    >
      <Toolbar
        sx={{ px: 3, py: 3, bgcolor: 'transparent', minHeight: 'unset' }}
      >
        {location.pathname === '/create' ? null : (
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="menu"
            onClick={() => {
              back();
            }}
          >
            <ArrowBack fontSize="medium" htmlColor="#4B4749" />
          </IconButton>
        )}

        {location.pathname === '/create' ? (
          <Typography
            variant="h2"
            component="div"
            sx={{
              flexGrow: 1,
              pr: type === 'none' ? '36px!important' : '0px',
            }}
          >
            {title}
            {toolTip !== '' && (
              <ToolTip title={toolTip}>
                <IconButton size="small" color="primary">
                  <HelpIcon fontSize="medium" color="primary" />
                </IconButton>
              </ToolTip>
            )}
          </Typography>
        ) : (
          <Typography
            variant="h2"
            component="div"
            sx={{
              flexGrow: 1,
              pr: type === 'none' ? '36px!important' : '0px',
            }}
          >
            {title}
            {toolTip !== '' && (
              <ToolTip title={toolTip}>
                <IconButton size="small" color="primary">
                  <HelpIcon fontSize="medium" color="primary" />
                </IconButton>
              </ToolTip>
            )}
          </Typography>
        )}
        {(type === 'add' || type === 'help') && (
          <ToolTip title={toolTipTitle}>
            <IconButton
              onClick={() => {
                if (handle !== undefined) handle();
              }}
            >
              {type === 'add' && <AddCircleOutline sx={{ color: '#00C9F2' }} />}
              {type === 'help' && <HelpIcon />}
            </IconButton>
          </ToolTip>
        )}
      </Toolbar>
    </Container>
  );
};

CreateEnterpriseToolBar.propTypes = {
  title: PropTypes.string,
  handle: PropTypes.func,
  back: PropTypes.func,
  type: PropTypes.string,
  position: PropTypes.string,
  toolTipTitle: PropTypes.string,
  bgColor: PropTypes.string,
  toolTip: PropTypes.string,
};

CreateEnterpriseToolBar.defaultProps = {
  title: null,
  handle: null,
  back: null,
  type: null,
  position: null,
  toolTipTitle: null,
  bgColor: 'transparent',
  toolTip: '',
};

export default CreateEnterpriseToolBar;
