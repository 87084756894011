export const ProposalTypes = {
  ADD: 'Add admin proposal',
  REMOVE: 'Remove admin proposal',
  REPLACE: 'Replace admin proposal',
  MINT: 'Mint new shares proposal',
  NEW_SHAREHOLDER: 'New shareholder proposal',
  DEFAULT: 'Proposal',
};

export const PROPOSAL_TYPE_ADD = 'ADD';
export const PROPOSAL_TYPE_REMOVE = 'REMOVE';
export const PROPOSAL_TYPE_REPLACE = 'REPLACE';
export const PROPOSAL_TYPE_MINT = 'MINT';
export const PROPOSAL_TYPE_NEW_SHAREHOLDER = 'NEW_SHAREHOLDER';
