import React from 'react';
import { WEMenu, WEMenuItem } from '../Menu/WEMenu';
import PropTypes from 'prop-types';

const MainMenu = ({ anchorEl, handleClose, handleNav }) => {
  return (
    <WEMenu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <WEMenuItem onClick={handleNav('home')}>Home</WEMenuItem>
      <WEMenuItem onClick={handleNav('forum')}>Forum</WEMenuItem>
      <WEMenuItem onClick={handleNav('about')}>About us</WEMenuItem>
      <WEMenuItem onClick={handleNav('faq')}>FAQ</WEMenuItem>
    </WEMenu>
  );
};

MainMenu.propTypes = {
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func,
  handleNav: PropTypes.func,
};

MainMenu.defaultProps = {
  anchorEl: null,
  handleClose: null,
  handleNav: null,
};

export default MainMenu;
