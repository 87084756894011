import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateAppBar } from '../../components/AppBar';
import Header from '../../components/Header/index';
import QRReceiveCode from '../../components/Transfer/QRReceiveCode';

const Receive = () => {
  const location = useLocation();
  const state = location.state;
  const { wepID, weBack, pTab } = state;
  const navigation = useNavigate();

  const closeHandler = () => [
    navigation(`/wepID/dashboard`, { state: { wepID, weBack, pTab } }),
  ];

  return (
    <>
      <Header pageTitle={'Receive'} />
      <CreateAppBar title="Receive" close={closeHandler} helpEnabled={false} />
      <div
        style={{
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <QRReceiveCode />
      </div>
    </>
  );
};

export default Receive;
