import { QrCode } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useWeb3React } from '@web3-react/core';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeAppBar, ModalHead } from '../../components/AppBar';
import { WELoadingButton } from '../../components/Button';
import { QRScanModal } from '../../components/Dialog';
import Header from '../../components/Header/index';
import { InputLabel, Select } from '../../components/Input';
import { useNotification } from '../../components/Notification/hook';
import ScrollToTop from '../../components/ScrollToTop';
import QRReceiveCode from '../../components/Transfer/QRReceiveCode';
import EnterpriseABI from '../../constants/abi/enterprise.json';
import EnterpriseDevABI from '../../constants/abi/enterprise_dev.json';
import store from '../../constants/store';
import {
  EmptyShareholder,
  EmptyTransaction,
  MoneyType,
  MoneyTypes,
  STORE_KEYS,
  TXTYPE,
  TxCategories,
  TxTypes,
  isTxCategory,
  isTxType,
} from '../../constants/store/constant';
import { useWeb3 } from '../../hook/web3';
import { convertMaticToUSD, getEtherFromWei } from '../../utils/unit';

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

const Send = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [enterprise, setEnterprise] = useState(enterprises.tempEnterprise);
  const { displaySuccess, displayError } = useNotification();

  const location = useLocation();
  const state = location.state;
  const { wepID, weBack, pTab, isAdmin } = state;

  const navigation = useNavigate();

  const [toAddr, setToAddr] = useState('');
  const [amount, setAmount] = useState('');
  const [amountUSD, setAmountUSD] = useState(0);
  const [amountType, setAmountType] = useState(MoneyType.MATIC);

  const [price, setPrice] = useState(0);
  const [balance, setBalance] = useState(
    getEtherFromWei(enterprise?.numOfShare)
  );
  const [treasuryUSD, setTreasuryUSD] = useState(0);
  const [moneyUnit, setMoneyUnit] = useState('MATIC');
  const [moneyLst] = useState(['MATIC']);
  const [type, setType] = useState(state.moneyFlag);
  const [categori, setCategory] = useState(TxCategories[0]);
  const [note, setNote] = useState('');
  const [qrScanOpen, setQrScanOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const handleInputChange = (typee) => (evt) => {
    let value = evt.currentTarget.value;
    let regExp;
    switch (typee) {
      case 'walletAddr':
        setToAddr(value);
        break;
      case 'amount':
        regExp = /[0-9]+/;
        switch (type) {
          case TXTYPE.MONEY_IN:
          case TXTYPE.MONEY_OUT:
            regExp = /[0-9]*([.])?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?/;
            break;
          case TXTYPE.SHARE_OUT:
            regExp = /[0-9]+/;
            break;
          default:
            break;
        }

        if (regExp.exec(value)?.[0] === value || value === '') {
          if (/[0][0-9]+[.]?[0-9]*/.exec(value)?.[0] === value)
            value = value.slice(1, value.length);
          if (/[.][0-9]*/.exec(value)?.[0] === value) value = `0${value}`;

          setAmount(value);
          getAmountUSD(value, type).then((val) => {
            setAmountUSD(val);
          });
        }

        break;
      case 'note':
        setNote(value);
        break;
      default:
        break;
    }
  };

  const getAmountUSD = async (value, type) => {
    let usd = 0;
    if (type === TXTYPE.MONEY_OUT) {
      usd = await convertMaticToUSD(Number(value));
    } else if (type === TXTYPE.SHARE_OUT) {
      usd = Number(value) * Number(enterprise?.info?.price);
    }
    console.log(usd);
    return usd;
  };

  const back = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack, pTab } });
  };

  const getBalance = async (contract, addr) => {
    if (contract === ZERO_ADDRESS) {
      try {
        return await web3.eth.getBalance(addr);
      } catch (e) {
        return 0;
      }
    }
  };

  React.useEffect(() => {
    console.log(enterprise);
    convertMaticToUSD(1).then((res) => {
      console.log(price);
      setPrice(res);
    });
    if (enterprise.address) {
      localStorage.setItem(
        'enterprise',
        JSON.stringify(enterprises.tempEnterprise)
      );
    } else {
      const twe = JSON.parse(localStorage.getItem('enterprise'));
      setEnterprise(twe);
      updateEnterprises((prev) => {
        prev.tempEnterprise = twe;
      });
      setBalance(getEtherFromWei(twe?.numOfShare));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Check if the TXTYPE is send or receive
  React.useEffect(() => {
    if (state.moneyFlag === 'receive') {
      setType(TXTYPE.MONEY_IN);
    }
  }, [state.moneyFlag]);

  React.useEffect(() => {
    (async () => {
      if (!enterprise.address || !web3) {
        return;
      }
      if (type === 'Share Transfer') {
        setBalance(getEtherFromWei(enterprise?.numOfShare));
      } else {
        const _balance = await getBalance(
          ZERO_ADDRESS,
          enterprise?.address?.toLowerCase()
        );

        const enterpriseHandler = new web3.eth.Contract(
          process.env.REACT_APP_ENV === 'prod'
            ? EnterpriseABI
            : EnterpriseDevABI,
          enterprise.address
        );

        const _orderBook = await enterpriseHandler.methods.orderBook().call();

        const _balanceInEth = getEtherFromWei(_balance);
        const _orderBookInEth = getEtherFromWei(_orderBook);

        const _availableBalance = _balanceInEth - _orderBookInEth;

        setBalance(_availableBalance);

        const treasuryInUSD = await convertMaticToUSD(_availableBalance);
        setTreasuryUSD(treasuryInUSD);
      }
    })();
  }, [type, web3]); // eslint-disable-line

  const handleSelectChange = (type, amount) => async (evt) => {
    const value = evt.target.value;
    switch (type) {
      case 'type':
        if (isTxType(value)) {
          setType(value);
          getAmountUSD(amount, value).then((val) => {
            console.log(type, value, amount);
            setAmountUSD(val);
          });
        }
        break;
      case 'moneyType':
        setAmountType(value);
        break;

      case 'category':
        if (isTxCategory(value)) {
          setCategory(value);
        }
        break;

      default:
        break;
    }
  };

  const handleClickShowQR = () => {
    setQrScanOpen(true);
  };

  const closeQRScaner = () => {
    setQrScanOpen(false);
  };

  const qrHandler = (addr) => {
    setToAddr(addr);
  };

  const transferHandler = async () => {
    setIsSending(true);
    let asset = 'Money';
    let category = categori;
    let notee = note;
    if (!web3.utils.isAddress(toAddr)) {
      setIsSending(false);
      return displayError({
        message: 'To address is not valid',
        timeout: 5000,
      });
    }

    if (!(parseFloat(amount) > 0)) {
      setIsSending(false);
      return displayError({
        message: 'Amount should be greater than the zero',
        timeout: 5000,
      });
    }
    if (parseFloat(amount) > parseFloat(balance)) {
      setIsSending(false);
      return displayError({
        message: 'Amount exceed your share token balance',
        timeout: 5000,
      });
    }
    if (type === TXTYPE.MONEY_OUT) {
      if (note === '') {
        setIsSending(false);
        return displayError({
          message: 'Note is empty. You have to fill it',
          timeout: 5000,
        });
      }
    } else {
      asset = 'TOKEN';
      category = 'None';
      notee = '';
    }

    const enterpriseHandler = new web3.eth.Contract(
      process.env.REACT_APP_ENV === 'prod' ? EnterpriseABI : EnterpriseDevABI,
      enterprise.address
    );

    switch (type) {
      case TXTYPE.MONEY_IN:
      case TXTYPE.MONEY_OUT:
        asset = 'ETH';
        category = categori;
        notee = note;
        try {
          await enterpriseHandler.methods
            .withdrawAdmin(toAddr.toLowerCase(), web3.utils.toWei(amount))
            .send({
              from: account,
              gasLimit: web3.eth.getBlock('latest').gasLimit,
            });

          const _balance = await getBalance(
            ZERO_ADDRESS,
            enterprise?.info?.address
          );

          const _orderBook = await enterpriseHandler.methods.orderBook.call();

          const _balanceInEth = getEtherFromWei(_balance);
          const _orderBookInEth = getEtherFromWei(_orderBook);

          const _availableBalance =
            parseFloat(_balanceInEth) - parseFloat(_orderBookInEth);

          setBalance(_availableBalance);

          displaySuccess({
            message: 'Sent token successfully',
            timeout: 5000,
          });
        } catch (e) {
          console.error('withdraw native token error', e); //eslint-disable-line no-console
          setIsSending(false);
          return displayError({
            message: 'Error is occurred to send native token',
            timeout: 5000,
          });
        }
        break;
      case TXTYPE.SHARE_OUT: // share transfer handler
        try {
          await enterpriseHandler.methods
            .transfer(toAddr.toLowerCase(), web3.utils.toWei(amount))
            .send({
              from: account,
              gasLimit: web3.eth.getBlock('latest').gasLimit,
            });
          displaySuccess({
            message: 'Shares have been successfully transferred.',
            timeout: 5000,
          });
        } catch (e) {
          console.error('transfer share token error', e); //eslint-disable-line no-console
          setIsSending(false);
          return displayError({
            message: 'Error is occurred to transfer share token',
            timeout: 5000,
          });
        }
        asset = enterprise.info.tokenName;
        category = 'None';
        notee = '';
        break;
      default:
        break;
    }

    const transaction = EmptyTransaction();
    transaction.id = Date.now();
    transaction.amount = amount;
    transaction.asset = asset;
    transaction.category = category;
    transaction.created_at = Date.now();
    transaction.enterprise_address = enterprise.address;
    transaction.from = account;
    transaction.note = notee;
    transaction.to = toAddr;
    transaction.token = '';
    transaction.type = type;

    updateEnterprises((prev) => {
      if (type === TXTYPE.SHARE_OUT) {
        const index = prev.enterprises?.findIndex(
          (enterprise) => enterprise.address === prev.tempEnterprise.address
        );
        if (index >= 0) {
          prev.enterprises[index].numOfShare -= parseInt(
            web3.utils.toWei(amount)
          );
          prev.enterprises[index].transactions = [
            ...prev.enterprises[index].transactions,
            transaction,
          ];
        }
        prev.tempEnterprise.numOfShare -= parseInt(web3.utils.toWei(amount));
        prev.tempEnterprise.shareholders.forEach((sh, idx) => {
          if (sh.walletAddr?.toUpperCase() === account.toUpperCase()) {
            const mnt = parseInt(web3.utils.toWei(amount));
            if (prev.tempEnterprise.shareholders[idx].numOfShare > mnt) {
              prev.tempEnterprise.shareholders[idx].numOfShare -= mnt;
            } else {
              let temp = [...enterprise.shareholders];
              temp.splice(idx, 1);
              prev.tempEnterprise.shareholders = temp;
              prev.tempEnterprise.isShareholder = false;
              prev.tempEnterprise.info.memNum = temp.length;
            }
          }
        });
        const shIdx = prev.tempEnterprise.shareholders?.findIndex(
          (sh) => sh.walletAddr.toUpperCase() === toAddr.toUpperCase()
        );
        if (shIdx >= 0) {
          prev.tempEnterprise.shareholders[shIdx].numOfShare += parseInt(
            web3.utils.toWei(amount)
          );
        } else {
          let tsh = EmptyShareholder({});
          tsh.walletAddr = toAddr.toUpperCase();
          tsh.numOfShare = parseInt(web3.utils.toWei(amount));
          prev.tempEnterprise.shareholders = [
            ...prev.tempEnterprise.shareholders,
            tsh,
          ];
          prev.tempEnterprise.info.memNum += 1;
        }
      } else if (type === TXTYPE.MONEY_OUT) {
        transaction.from = enterprise.address;
      }

      const index = prev.enterprises?.findIndex(
        (enterprise) => enterprise.address === prev.tempEnterprise.address
      );
      prev.enterprises[index].transactions = [
        ...prev.enterprises[index].transactions,
        transaction,
      ];
      prev.tempEnterprise.transactions = [
        ...prev.tempEnterprise.transactions,
        transaction,
      ];
      return prev;
    });

    navigation(`/wepID/dashboard`, { state: { pTab, wepID, weBack } });
    setIsSending(false);
  };

  return (
    <>
      <ScrollToTop />
      <Header pageTitle={'Send'} />
      <HomeAppBar position="absolute" />
      <Box sx={{ bgcolor: '#E5E5E5', minHeight: 'calc(100vh - 66px)' }}>
        <ModalHead
          title={type}
          close={() => back()}
          color="#ff6142"
          theme="dark"
        />
        <Box sx={{ px: 4, pt: 3 }}>
          <Box sx={{ mb: 2 }}>
            <InputLabel sx={{ mb: 0.75 }}>Type</InputLabel>
            <Select
              id="category"
              value={type}
              onChange={handleSelectChange('type', amount)}
              IconComponent={KeyboardArrowDownIcon}
              sx={{
                px: 1,
                py: 0.75,
                fontSize: 14,
                width: '100%',
                fontWeight: 500,
                color: '#FFFFFF',
                bgcolor: '#28282B',
                borderRadius: '6px',
                '& svg': {
                  color: '#ffffff',
                },
              }}
            >
              {(() => {
                let types = [];
                if (isAdmin) types = [...TxTypes];
                else types = [TXTYPE.SHARE_OUT];
                return types.map((txtype, key) => {
                  if (!isAdmin && txtype !== TXTYPE.SHARE_OUT) return <></>;
                  else
                    return (
                      <MenuItem key={key} value={txtype}>
                        {txtype}
                      </MenuItem>
                    );
                });
              })()}
            </Select>
          </Box>
          {type === 'Money Transfer' && (
            <Stack>
              <Box>
                <InputLabel shrink htmlFor="moneyType">
                  Money Type
                </InputLabel>
                <Select
                  id="moneyType"
                  value={moneyUnit}
                  onChange={() => {
                    handleSelectChange('moneyType');
                  }}
                  sx={{
                    mt: 1,
                    backgroundColor: '#3D61B0',
                    color: '#FFFFFF',
                    '& .MuiSvgIcon-root': {
                      color: '#FFFFFF',
                    },
                  }}
                >
                  {moneyLst.map((moneyType, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        value={moneyType}
                        onClick={() => setMoneyUnit(moneyType)}
                      >
                        {moneyType}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Stack>
          )}

          {(type === TXTYPE.MONEY_OUT || type === TXTYPE.SHARE_OUT) && (
            <Stack>
              <Box>
                <InputLabel sx={{ mb: 1.75 }}>Send to</InputLabel>
                <TextField
                  id="sendTo"
                  value={toAddr}
                  fullWidth
                  sx={{
                    mt: 1,
                    width: '100%',
                    borderWidth: 0,
                    overflow: 'hidden',
                    borderRadius: '6px',
                    '& fieldset': {
                      borderWidth: 0,
                    },
                    '& input': {
                      borderWidth: '0px !important',
                      fontSize: 20,
                      py: 1.75,
                    },
                  }}
                  onChange={handleInputChange('walletAddr')}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleClickShowQR} edge="end">
                        <QrCode htmlColor="#3D61B0" />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: 14,
                  fontWeight: 500,
                  color: '#28282B',
                  mt: 2.6,
                }}
              >
                {type === TXTYPE.MONEY_OUT ? 'Treasury Balance' : 'My Shares'}:{' '}
                {Number(balance - Number(amount)).toFixed(4)}{' '}
                {type === TXTYPE.MONEY_OUT &&
                  `${
                    moneyUnit === 'ETH' ? `Matic` : moneyUnit
                  } ($${treasuryUSD})`}
                {type === TXTYPE.SHARE_OUT && enterprise.info.tokenName}
              </Typography>
              <Box sx={{ mt: 2.6 }}>
                <TextField
                  id="amount"
                  onChange={handleInputChange('amount')}
                  value={amount}
                  placeholder={type === 'Share Transfer' ? '0' : '0.00'}
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  InputProps={{
                    endAdornment: type === TXTYPE.MONEY_OUT && (
                      <InputAdornment position="end">
                        <Select
                          onChange={handleSelectChange('moneyType')}
                          value={amountType}
                        >
                          {MoneyTypes.map((item, idx) => (
                            <option key={idx} value={item}>
                              {item}
                            </option>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  sx={{
                    mt: 1,
                    width: '100%',
                    borderWidth: 0,
                    overflow: 'hidden',
                    borderRadius: '6px',
                    '& fieldset': {
                      borderWidth: 0,
                    },
                    '& input': {
                      borderWidth: '0px !important',
                      fontSize: 20,
                      py: 1.75,
                    },
                  }}
                  helperText={`$${amountUSD.toFixed(4)}`}
                />
              </Box>
              {type === TXTYPE.MONEY_OUT && (
                <>
                  <Box sx={{ mt: 2.5 }}>
                    <InputLabel sx={{ mb: 1.75 }}>Category</InputLabel>
                    <Select
                      id="category"
                      value={categori}
                      onChange={handleSelectChange('category')}
                      IconComponent={KeyboardArrowDownIcon}
                      sx={{
                        px: 1,
                        py: 0.75,
                        fontSize: 14,
                        width: '100%',
                        fontWeight: 500,
                        color: '#28282B',
                        bgcolor: '#ffffff',
                        borderWidth: '0px !important',
                        borderRadius: '6px',
                        '& svg': {
                          color: '#28282B',
                        },
                        '& fieldset': {
                          borderWidth: '0px !important',
                        },
                      }}
                    >
                      {TxCategories.map((cate, idx) => {
                        return (
                          <MenuItem key={idx} value={cate}>
                            {cate}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                  <Box sx={{ mt: 2.5 }}>
                    <InputLabel shrink htmlFor="sendTo">
                      Note
                    </InputLabel>
                    <TextField
                      value={note}
                      rows={4}
                      onChange={handleInputChange('note')}
                      fullWidth
                      multiline
                      sx={{
                        mt: 1,
                        width: '100%',
                        borderWidth: 0,
                        overflow: 'hidden',
                        borderRadius: '6px',
                        '& fieldset': {
                          borderWidth: 0,
                        },
                        '& textarea': {
                          borderWidth: '0px !important',
                          fontSize: 20,
                          py: 1.75,
                        },
                      }}
                    />
                  </Box>
                </>
              )}
              {type === TXTYPE.SHARE_OUT && (
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: 14,
                    fontWeight: 500,
                    color: '#28282B',
                    mt: 2.6,
                    lineHeight: '25px',
                  }}
                >
                  My Shares: {balance - Number(amount)}{' '}
                  {enterprise.info.tokenName}
                  <br />
                  Total Enterprise Shares: {enterprise.info.totalShare}{' '}
                  {enterprise.info.tokenName}
                </Typography>
              )}
              <Box sx={{ mt: 6, mb: 5, textAlign: 'center' }}>
                <WELoadingButton
                  loading={isSending}
                  onClick={transferHandler}
                  width={320}
                  rounded="xl"
                  type="primary"
                  loadingPosition="center"
                  loadingIndicator={
                    <CircularProgress color="default" size={20} />
                  }
                >
                  Transfer
                </WELoadingButton>
              </Box>
            </Stack>
          )}
          {type === TXTYPE.MONEY_IN && <QRReceiveCode />}
        </Box>
      </Box>
      <QRScanModal
        open={qrScanOpen}
        onClose={closeQRScaner}
        qrHandler={qrHandler}
      />
    </>
  );
};

export default Send;
