import { Box } from '@mui/material';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';

const PhoneNumInput = (props) => {
  const { value, onChange } = props;

  return (
    <Box
      sx={{
        border: '1px solid #aaa',
        borderRadius: '8px',
        ':hover': {
          border: '1px solid #ced4da',
        },
      }}
    >
      <PhoneInput
        country="us"
        value={value}
        placeholder=""
        onChange={(phone) => onChange(phone)}
        containerStyle={{
          width: '100%',
          height: '48px',
          borderRadius: '8px',
        }}
        inputStyle={{
          width: '100%',
          height: '48px',
          borderRadius: '8px',
          paddingTop: '12px',
          paddingBottom: '12px',
          borderWidth: '0',
          backgroundColor: '#FAFBFC',
          color: '#4B4749',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 500,
          fontFamily: ['"Montserrat"'].join(','),
        }}
        buttonStyle={{
          borderRadius: '8px',
          paddingTop: '12px',
          paddingBottom: '12px',
          borderWidth: '0',
        }}
      />
    </Box>
  );
};

PhoneNumInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default PhoneNumInput;
