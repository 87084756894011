import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { upsertUserAccount } from '../apis';
import { CreateAppBar } from '../components/AppBar';
import { JoinProcess } from '../components/Diagram';
import Header from '../components/Header/index';
import store from '../constants/store';
import { PAGE_NAME, STORE_KEYS } from '../constants/store/constant';
import { useAutoConnect } from '../utils/AutoConnect';

const JoinSplash = () => {
  const navigate = useNavigate();
  const [, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);
  const { account } = useAutoConnect();
  const goToJoinPage = () => {
    updateEnterprises((prev) => {
      prev.pageName = PAGE_NAME.id.join;
      return prev;
    });
    upsertUserAccount({ address: account, join_splash: true });
    navigate('/start');
  };

  return (
    <>
      <Header pageTitle={'Join'} />
      <CreateAppBar
        title={'Search Enterprises'}
        close={goToJoinPage}
        helpEnabled={false}
        bgColor="#F4F8FF"
      />
      <Box
        component={'main'}
        sx={{
          backgroundColor: '#F4F8FF',
          minHeight: '100vh',
          px: '28px',
          py: '20px',
        }}
      >
        <Typography
          sx={{
            color: '#4B4749',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            mt: '19px',
            textAlign: 'center',
          }}
        >
          Browse all existing <i>World Enterprises</i> and request to join any
          that you’d like to be a member of. You can request to join any{' '}
          <i>World Enterprise</i> for free or offer to buy shares from the
          treasury of the <i>World Enterprise</i>.
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontSize: '25px',
            lineHeight: '30px',
            pt: '19px',
          }}
        >
          Join Process
        </Typography>
        <Box
          sx={{
            pt: '25px',
            px: '20px',
          }}
        ></Box>
        <JoinProcess />
      </Box>
    </>
  );
};

export default JoinSplash;
