export function getRemainTimeToExecute(endTime) {
  const curTime = Math.floor(Date.now() / 1000);
  endTime = parseInt(endTime);
  let deltaTime = endTime - curTime;
  if (deltaTime <= 0) {
    return true;
  }
  const oneDay = 60 * 60 * 24;
  const oneHour = 60 * 60;
  const oneMinute = 60;

  const date = Math.floor(deltaTime / oneDay);
  deltaTime -= date * oneDay;

  const hour = Math.floor(deltaTime / oneHour);
  deltaTime -= hour * oneHour;

  const minute = Math.floor(deltaTime / oneMinute);
  deltaTime -= minute * oneMinute;

  const second = deltaTime;

  let resultStr = 'Remain ';
  if (date === 1) {
    resultStr += `${date} day `;
  } else if (date > 1) {
    resultStr += `${date} days `;
  }

  if (minute === 1) {
    resultStr += `${minute} minute `;
  } else if (minute > 1) {
    resultStr += `${minute} minutes `;
  }

  if (second === 1) {
    resultStr += `${second} second `;
  } else if (second > 1) {
    resultStr += `${second} seconds `;
  }
  resultStr += 'to execute';
  return resultStr;
}

export function isReadyToExecute(endTime) {
  const curTime = Math.floor(Date.now() / 1000);
  if (parseInt(endTime) <= curTime) {
    return true;
  }
  return false;
}

export function getDaysAndHours(endTime) {
  const curTime = Math.floor(Date.now() / 1000);
  endTime = parseInt(endTime) + 567405;
  let deltaTime = endTime - curTime;

  const oneDay = 60 * 60 * 24;
  const oneHour = 60 * 60;

  const date = Math.floor(deltaTime / oneDay);
  deltaTime -= date * oneDay;

  const hour = Math.floor(deltaTime / oneHour);
  deltaTime -= hour * oneHour;

  let resultStr = '';

  if (date === 1) {
    resultStr += `${date} Day `;
  } else if (date > 1) {
    resultStr += `${date} Days `;
  }

  if (hour === 1) {
    resultStr += `${hour} Hours`;
  } else if (hour > 1) {
    resultStr += `${hour} Hours`;
  }

  return resultStr;
}
