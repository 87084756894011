import { Chip as MuiChip, styled } from '@mui/material';
import 'react-phone-input-2/lib/style.css';

const Chip = styled(MuiChip)(() => ({
  backgroundColor: '#FAFBFC',
  color: '#241F21',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '17px',
  letterSpacing: '0px',
  textAlign: 'center',
  borderRadius: '8px',

  ':hover': {
    background: '#00C9F2',
    color: '#fff',
  },
}));

export default Chip;
