import { Link } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const NavLink = ({ page, onClick }) => {
  return (
    <Link
      underline="none"
      key={page}
      sx={{
        my: 2,
        lineHeight: {
          md: '28.88px',
        },
        mr: '59px',
        display: 'block',
        width: 'fit-content',
      }}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {page}
    </Link>
  );
};

NavLink.propTypes = {
  page: PropTypes.string,
  onClick: PropTypes.func,
};

NavLink.defaultProps = {
  page: null,
  onClick: null,
};

export default NavLink;
