import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';

const Footer = () => {
  return (
    <Box
      sx={{
        py: { xs: '37px', md: '58px' },
      }}
    >
      <Container>
        <Box
          sx={{
            display: { xs: 'block', md: 'flex' },
            textAlign: { xs: 'center', md: 'left' },
            justifyContent: 'space-between ',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src="/images/World_Enterprise_OneLine.svg"
            alt=""
            sx={{
              width: {
                xs: '200px',
                md: '296px',
              },
              mb: { xs: '20px', md: '0' },
            }}
          />
          <Box
            sx={{
              mb: { xs: '20px', md: '0' },
            }}
          >
            <Button
              sx={{
                background: 'transparent',
                border: {
                  xs: '1.5px solid #000000',
                  md: '3px solid #000000',
                },
                width: { xs: '20px', md: '48px' },
                height: { xs: '20px', md: '48px' },
                mr: { xs: '17px', md: '40px' },
                // p: {xs: "5px", md: "11px !important"},
                minWidth: 0,
              }}
            >
              <Box
                component="img"
                src="/images/facebook.svg"
                sx={{
                  height: { xs: '11px', md: 'auto' },
                }}
              />
            </Button>
            <Button
              sx={{
                background: 'transparent',
                // border: "3px solid #000000",
                border: {
                  xs: '1.5px solid #000000',
                  md: '3px solid #000000',
                },
                width: { xs: '20px', md: '48px' },
                height: { xs: '20px', md: '48px' },
                minWidth: 0,
              }}
            >
              <Box
                component="img"
                src="/images/twitter.svg"
                sx={{
                  height: { xs: '11px', md: 'auto' },
                }}
              />
            </Button>
          </Box>
          <Box
            sx={{
              mb: { xs: '20px', md: '0' },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                lineHeight: { xs: '17px', md: '20px' },
                fontWeight: '500',
                color: 'black',
              }}
            >
              ©2023 | All rights reserved by <i>World Enterprise</i>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
