import { ShowChart } from '@mui/icons-material';
import { Badge, Box, Typography, styled, useMediaQuery } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';
import { OrderCategory } from '../../constants/store/constant';
import { WEButton } from '../Button';

const OrderBadge = styled(Badge)(() => ({
  //eslint-disable-line no-unused-vars
  '& .MuiBadge-badge': {
    right: '12px!important',
    top: '2px!important',
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: '#FFDB0A',
    color: '#28282B',
    fontFamily: 'Montserrat',
  },
}));

const MyShares = ({ myShare, enterprise, trade, isMember, goChart }) => {
  const isSmallMobile = useMediaQuery('(max-width:350px)');
  return (
    <>
      <Grid2 display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
        <Grid2
          display="flex"
          justifyContent="space-around"
          sx={{ columnGap: 3, width: '100%' }}
        >
          <Grid2
            display="flex"
            flexDirection="column"
            sx={{
              rowGap: '4px',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="secondary">My Shares</Typography>
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '20px',
                color: '#28282B',
              }}
            >
              {Math.trunc(myShare)}
            </Typography>
          </Grid2>
          <Grid2
            display="flex"
            flexDirection="column"
            sx={{
              rowGap: '4px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{ display: 'flex', alignItems: 'center' }}
              variant="secondary"
            >
              Price Per Share
              <ShowChart
                fontSize="36px"
                htmlColor="#00C9F2"
                sx={{ height: 17, ml: 1, cursor: 'pointer' }}
                onClick={() => goChart('shareholder')}
              />
            </Typography>
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '20px',
                color: '#5CDD5C',
              }}
            >
              {'$' +
                (enterprise?.info?.price
                  ? enterprise.info.price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 10,
                    })
                  : '0.00')}
            </Typography>
          </Grid2>
          <Grid2
            display="flex"
            flexDirection="column"
            sx={{
              rowGap: '4px',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{}} variant="secondary">
              Shares Value
            </Typography>
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '20px',
                color: '#28282B',
              }}
            >
              {'$' + parseFloat(enterprise?.info?.price) > 0
                ? (parseFloat(enterprise.info.price) * myShare).toLocaleString(
                    undefined,
                    { minimumFractionDigits: 2 }
                  )
                : '0.00'}
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>

      <Stack
        direction={isSmallMobile ? 'column' : 'row'}
        justifyContent="space-between"
        gap={1}
      >
        <Box
          sx={{
            width: { xs: '240px', sm: '350px' },
          }}
        >
          <WEButton
            onClick={() => trade(OrderCategory.BUY)}
            rounded="xl"
            type="success"
            startIcon={
              <Box
                sx={{ width: '18px', height: '18px' }}
                component="img"
                src="/images/pie_chart_icon.svg"
              ></Box>
            }
            size="small"
            sx={{
              fontSize: { xs: '13px', sm: '16px' },
              px: { xs: 0, sm: 2 },
              width: '100%',
            }}
            disabled={!isMember}
          >
            Buy
          </WEButton>
        </Box>
        <Box
          sx={{
            width: { xs: '240px', sm: '350px' },
          }}
        >
          <WEButton
            onClick={() => trade(OrderCategory.SELL)}
            rounded="xl"
            type="error"
            startIcon={
              <Box
                sx={{ width: '18px', height: '18px' }}
                component="img"
                src="/images/chart_icon.svg"
              ></Box>
            }
            size="small"
            sx={{
              fontSize: { xs: '13px', sm: '16px' },
              px: { xs: 0, sm: 2 },
              width: '100%',
            }}
            disabled={!isMember}
          >
            Sell
          </WEButton>
        </Box>
        <Box
          sx={{
            width: { xs: '320px', sm: '350px' },
          }}
        >
          <OrderBadge
            badgeContent={
              enterprise.orders?.filter((order) => order.status === 'ACTIVE')
                .length
            }
            sx={{
              width: '100%',
            }}
          >
            <WEButton
              onClick={() => trade('Orders')}
              rounded="xl"
              type="primary"
              startIcon={
                <Box
                  sx={{ height: '18px', width: '18px' }}
                  component="img"
                  src="/images/Mask group.svg"
                ></Box>
              }
              size="small"
              sx={{
                fontSize: { xs: '13px', sm: '16px' },
                px: { xs: 0, sm: 2 },
                width: '100%',
              }}
            >
              Order Book
            </WEButton>
          </OrderBadge>
        </Box>
      </Stack>
    </>
  );
};

MyShares.propTypes = {
  myShare: PropTypes.number,
  enterprise: PropTypes.object,
  trade: PropTypes.func,
  goChart: PropTypes.func,
  isMember: PropTypes.bool,
};

export default MyShares;
