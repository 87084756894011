import React from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { QrReader } from 'react-qr-reader';
import PropTypes from 'prop-types';

const QRScanModal = ({ open, onClose, qrHandler }) => {
  const closeHandler = () => {
    onClose();
  };

  const detectHandler = (result) => {
    qrHandler(result);
    closeHandler();
  };

  const getAddress = (text) => {
    return text.replace('ethereum:', '');
  };

  return (
    <Dialog open={open} onClose={closeHandler}>
      <DialogTitle>
        <IconButton
          size="small"
          sx={{ position: 'absolute', right: '12px' }}
          onClick={() => onClose()}
        >
          <Close htmlColor="#99A7C7" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <QrReader
          constraints={{
            facingMode: 'environment',
          }}
          onResult={(result) => {
            if (result) {
              let content = result.getText();
              let address = getAddress(content);
              detectHandler(address);
            }
          }}
          videoStyle={{ width: '200px' }}
          containerStyle={{ width: '200px' }}
        />
      </DialogContent>
    </Dialog>
  );
};

QRScanModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  qrHandler: PropTypes.func,
};

export default QRScanModal;
