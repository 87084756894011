import { Container, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import { AppBar } from '../AppBar';
import ForumList from './ForumList';

const ForumView = () => {
  const navigation = useNavigate();
  const [, setStep] = store.useState(STORE_KEYS.id.step);
  const { state } = useLocation();
  const { wepID, weBack, pTab } = state;
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [forums, setForums] = useState(enterprises.tempEnterprise.forums);

  const backHandler = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack, pTab } });
  };

  const addHandler = () => {
    setStep(STEP.DASHBOARD_FORUM_ADD);
  };

  useEffect(() => {
    if (enterprises.tempEnterprise.admins?.length === 0) {
      const getEnterprise = JSON.parse(localStorage.getItem('enterprise'));
      updateEnterprises((prev) => {
        prev.tempEnterprise = getEnterprise;
        return prev;
      });
      setForums(getEnterprise.forums);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppBar
        position="absolute"
        title="Forum"
        back={backHandler}
        type={'add'}
        handle={addHandler}
        toolTip="Forum"
      />

      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 0,
          pt: forums?.length === 0 ? 0 : 9, // remain for further update
        }}
      >
        <Container sx={{ mt: 2, px: 3 }}>
          <ForumList />
        </Container>
      </Grid>
    </>
  );
};

export default ForumView;
