import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  IconButton,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { PropagateLoader } from 'react-spinners';
import { uploadFile } from '../../utils/couldfront';
import { simpleErrorMessage } from '../../utils/errorMessage';
import { ERRORS } from '../../utils/errors';
import { useNotification } from '../Notification/hook';
import CloseIcon from '@mui/icons-material/Close';
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';

const ImageEditorModal = ({ open, onClose, image, onOK }) => {
  const editorRef = useRef();
  const [isMobile] = useState(window.innerWidth < 767);
  const { displaySuccess, displayError } = useNotification();
  const [loading, setLoading] = useState(false);

  const okHandler = async () => {
    setLoading(true);
    if (typeof editorRef.current?.cropper !== 'undefined') {
      const canvas = editorRef.current?.cropper.getCroppedCanvas().toDataURL();
      if (canvas) {
        await fetch(canvas)
          .then((res) => res.blob())
          .then(async (blob) => {
            setLoading(true);
            try {
              displaySuccess({
                message: 'Uploading image to IPFS server',
                timeout: 5000,
              });
              const ipfsUrl = await uploadFile(blob);
              onOK(ipfsUrl, window.URL.createObjectURL(blob));
              setLoading(false);
              onClose();
              displaySuccess({
                message: 'Upload image to IPFS successfully',
                timeout: 5000,
              });
              setLoading(false);
            } catch (e) {
              setLoading(false);
              console.error('ipfs upload error', e); //eslint-disable-line no-console
              setLoading(false);
              onClose();
              displayError({
                message: ERRORS.UPLOAD_IMAGE,
                reason: simpleErrorMessage(e.message),
                timeout: 5000,
              });
            }
          });
      } else {
        setLoading(false);
        onClose();
      }
    } else {
      displayError({
        message: ERRORS.UPLOAD_IMAGE,
        reason: 'Image crop failed! Please try again.',
        timeout: 5000,
      });
    }
  };

  return (
    <Dialog open={open} fullWidth onClose={() => onClose()}>
      <DialogContent
        sx={{
          position: 'relative',
          marginTop: '33px',
          width: { sm: '552px', xs: `${window?.innerWidth - 112}px` },
          overflow: 'hidden',
        }}
      >
        {image && (
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
            }}
          >
            <Cropper
              ref={editorRef}
              zoomTo={0}
              zoomable
              initialAspectRatio={1}
              src={image.toString()}
              minCropBoxHeight={50}
              minCropBoxWidth={isMobile ? window?.innerWidth - 112 : 552}
              minContainerWidth={isMobile ? window?.innerWidth - 112 : 300}
              background={false}
              autoCropArea={0.55}
              checkOrientation={false}
              dragMode="move"
            />
          </Box>
        )}
      </DialogContent>
      {loading ? (
        <PropagateLoader
          color="#FFDB0A"
          loading
          style={{
            display: 'block',
            height: '10px',
            width: '14px',
            margin: 'auto auto 50px',
            paddingLeft: '-20px',
          }}
        />
      ) : (
        <DialogActions sx={{ px: 3, pb: 3, flexDirection: 'column' }}>
          <Button
            onClick={okHandler}
            sx={{
              mx: 'auto',
              width: '100%',
              backgroundColor: '#28282B',
              borderRadius: '50px',
              '&:hover': { background: '#28282B' },
            }}
          >
            OK
          </Button>
        </DialogActions>
      )}
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '9px',
          right: '12px',
          '&:hover': { background: 'none' },
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

ImageEditorModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  image: PropTypes.any,
  onOK: PropTypes.func,
};

ImageEditorModal.defaultProps = {
  open: true,
  onClose: null,
  image: true,
  onOK: null,
};

export default ImageEditorModal;
