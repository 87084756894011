import { Typography, styled } from '@mui/material';

const landingTheme = {
  primary: '#FFDB0A',
  secondary: '#3D61B0',
  black: '#0D141E',
  fontSize: { xs: '14px', md: '18px' },
  lineHeight: { xs: '17px', md: '180.5%' },
};

const PButton = styled(Typography)(({ theme }) => ({
  display: 'inline-flex',
  '-webkit-box-align': 'center',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  borderRadius: '4px',
  cursor: 'pointer',
  background: landingTheme.secondary,
  color: 'white',
  width: '100%',
  textAlign: 'center',
  padding: '14px 9px',
  fontFamily: 'Montserrat',
  fontWeight: 500,
  letterSpacing: '-0.01em',
  zIndex: 2,
  pointerEvents: 'none',
  [theme.breakpoints.between('xs', 'md')]: {
    lineHeight: '17px',
    fontSize: `${landingTheme.fontSize.xs}`,
  },
  [theme.breakpoints.up('md')]: {
    lineHeight: '22px',
    fontSize: `${landingTheme.fontSize.md}`,
  },
  boxShadow: 'none!important',
  ':hover': {
    boxShadow: 'none!important',
  },
}));

export default PButton;
