import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';

import AdminsList from './AdminsList';

import { useWeb3React } from '@web3-react/core';
import { upsertDraft } from '../../apis';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import { useWeb3 } from '../../hook/web3';
import { simpleErrorMessage } from '../../utils/errorMessage';
import { ERRORS } from '../../utils/errors';
import { generateEnterpriseInfo } from '../../utils/generateEnterpriseInfo';
import { getEtherFromWei } from '../../utils/unit';
import { WELoadingButton } from '../Button';
import { useNotification } from '../Notification/hook';
import FactoryABI from '../../constants/abi/factory.json';
import FactoryDevABI from '../../constants/abi/factory_dev.json';

const AdminsView = () => {
  const { account } = useWeb3React();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const admins = enterprises.tempEnterprise.admins;
  const draftId = enterprises.tempEnterprise.draftId;
  const { displaySuccess, displayError } = useNotification();

  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [isCreating, setIsCreating] = React.useState(false);
  const web3 = useWeb3();

  const addHandler = () => {
    if (stepState === STEP.CREATE_ADMINS_VIEW) {
      setStep(STEP.CREATE_ADMIN_ADD);
    } else {
      setStep(STEP.ADMIN_ADD);
    }
  };

  const continueHandler = async () => {
    // Check account balance of user

    let _balance = await web3.eth.getBalance(account);
    if (!(parseFloat(_balance) > 0)) {
      return displayError({
        message: 'You need >0 MATIC to pay for gas fees',
        timeout: 5000,
      });
    }

    const weFactory = new web3.eth.Contract(
      process.env.REACT_APP_ENV === 'prod' ? FactoryABI : FactoryDevABI,
      process.env.REACT_APP_CONTRACT_MUMBAI_FACTORY
    );

    // End check
    setIsCreating(true);
    try {
      const _enterprise = enterprises.tempEnterprise;
      const TOKEN_NAME = _enterprise.info.name;
      const TOKEN_SYMBOL = _enterprise.info.tokenName;

      let ENTERPRISE_INFO = await generateEnterpriseInfo(_enterprise);
      const admins = [];
      _enterprise.admins.map((admin) => {
        admins.push(admin.walletAddr);
      });

      const keys = Object.keys(ENTERPRISE_INFO);
      for (let i = 0; i < keys.length; i++) {
        if (
          ENTERPRISE_INFO[keys[i]] === undefined ||
          ENTERPRISE_INFO[keys[i]] === null
        ) {
          ENTERPRISE_INFO[keys[i]] = '';
        }
      }

      let SHARE_HOLDERS_ADDRESSES = [];
      let SHARE_HOLDERS_AMOUNTS = [];
      for (var i = 0; i < _enterprise.shareholders.length; i++) {
        SHARE_HOLDERS_ADDRESSES[i] = _enterprise.shareholders[i].walletAddr;
        SHARE_HOLDERS_AMOUNTS[i] = _enterprise.shareholders[i].numOfShare;
      }

      if (stepState === STEP.CREATE_ADMINS_VIEW) {
        setStep(STEP.CREATE_PROCESSING);
      }
      await weFactory.methods
        .createWorldEnterprise(
          admins,
          SHARE_HOLDERS_ADDRESSES,
          SHARE_HOLDERS_AMOUNTS,
          TOKEN_NAME,
          TOKEN_SYMBOL,
          ENTERPRISE_INFO
        )
        .send(
          { from: account, gasLimit: web3.eth.getBlock('latest').gasLimit },
          (err, res) => {
            //eslint-disable-line no-unused-vars
            if (err) {
              console.error(err, res); //eslint-disable-line no-console
              setStep(STEP.CREATE_ENTERPRISE_INPUT);
              setIsCreating(false);
              return displayError({
                message: ERRORS.WE_CREATE,
                reason: simpleErrorMessage(err.message),
                timeout: 5000,
              });
            }
            if (stepState === STEP.CREATE_ADMINS_VIEW) {
              setStep(STEP.CREATE_PROCESSING);
            }
          }
        );
      const eIndex = await weFactory.methods.index().call();
      let eaddress = await weFactory.methods
        .worldEnterprises(eIndex - 1)
        .call();
      displaySuccess({
        message: 'Created a new world enterprise successfully',
        timeout: 5000,
      });
      // Format totalShare and myShare value
      let sh_addresses = Object.keys(_enterprise.shareholders);
      let totalShare = 0;
      let myShare = 0;
      sh_addresses.forEach((address) => {
        // eslint-disable-line no-loop-func
        totalShare =
          totalShare +
          getEtherFromWei(Number(_enterprise.shareholders[address].numOfShare));
        if (_enterprise.shareholders[address].walletAddr === account) {
          myShare = myShare + _enterprise.shareholders[address].numOfShare;
        }
      });
      updateEnterprises((prev) => {
        prev.tempEnterprise.address = eaddress;
        prev.tempEnterprise.info.totalShare = totalShare;
        prev.tempEnterprise.numOfShare = myShare;
        prev.tempEnterprise.info.memNum = sh_addresses.length;
        prev.tempEnterprise.proposals = [];
        prev.tempEnterprise.transactions = [];
        prev.tempEnterprise.orders = [];
        return prev;
      });
      // End Format
      try {
        await upsertDraft(draftId, {
          admin_address: account,
          status: 'FINISH',
          admins: JSON.stringify(admins),
        });
      } catch (error) {
        console.log('Draft Save ERROR'); //eslint-disable-line no-console
      }

      setStep(STEP.CREATE_SUCCESSED);
    } catch (e) {
      console.log(e); //eslint-disable-line no-console

      setStep(STEP.CREATE_ENTERPRISE_INPUT);
      if (e.code === 4001) {
        displayError({
          message: 'Transaction Cancelled. Please try again',
          reason: '',
          timeout: 5000,
        });
      } else {
        displayError({
          message: ERRORS.WE_CREATE,
          reason: simpleErrorMessage(e.message),
          timeout: 5000,
        });
      }
    }
    setIsCreating(false);
  };

  return (
    <>
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 0,
          mt: 0,
        }}
      >
        <Divider flexItem />
        {admins.length === 0 ? (
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              m: 0,
              px: 3,
            }}
          >
            <Box
              component="img"
              src="/images/we_admin.svg"
              alt={'splash'}
              sx={{
                right: '0',
                mt: 5,
                width: 'min(28.23vh, 53.99vw)',
                height: 'min(26.06vh, 49.83vw)',
              }}
            />
            {/* <Typography variant="h2" sx={{ mt: "min(6.63vh, 12.67vw)" }}>
              Add Admins
            </Typography> */}
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                maxWidth: '800px',
                mt: 5,
              }}
            >
              The Admin is responsible for the day-to-day operations. Admins can
              send money from the treasury to pay expenses and send invoices to
              collect revenue. Admins can also post business updates and inform
              shareholders of important matters.
            </Typography>
            <Box
              sx={{
                marginTop: 5,
                mx: 'auto',
                width: { xs: '100%', sm: '370px' },
                pb: 4,
                textAlign: 'center',
              }}
            >
              <WELoadingButton
                type="primary"
                loading={isCreating}
                rounded="xl"
                loadingPosition="center"
                width={260}
                loadingIndicator={
                  <CircularProgress color="default" size={20} />
                }
                onClick={addHandler}
              >
                Add Admins
              </WELoadingButton>
            </Box>
          </Container>
        ) : (
          <AdminsList />
        )}
      </Grid>
      {admins.length !== 0 && stepState === STEP.CREATE_ADMINS_VIEW && (
        <Box
          sx={{
            mt: 4,
            px: 2,
          }}
        >
          <Typography
            sx={{
              color: '#4B4749',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              mt: 3,
              textAlign: 'center',
              maxWidth: '800px',
              mx: 'auto',
            }}
          >
            Please confirm the transaction in next popup screen. This will
            launch you World Enterprise and your Tokens on the blockchain.
          </Typography>
          <Box
            sx={{
              marginTop: 5,
              mx: 'auto',
              width: { xs: '100%', sm: '370px' },
              pb: 4,
              textAlign: 'center',
            }}
          >
            <WELoadingButton
              type="primary"
              loading={isCreating}
              rounded="xl"
              loadingPosition="center"
              loadingIndicator={<CircularProgress color="default" size={20} />}
              onClick={continueHandler}
            >
              Finish & Create World Enterprise
            </WELoadingButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AdminsView;
