import {
  PROPOSAL_STATUS_ACTIVE,
  PROPOSAL_STATUS_FAILED,
  PROPOSAL_STATUS_CANCELLED,
  PROPOSAL_STATUS_PASSED,
} from '../constants/ProposalStatus';

export const getProposalStatusColor = (status) => {
  let color = 'default';
  switch (status) {
    case PROPOSAL_STATUS_ACTIVE:
      color = 'secondary';
      break;
    case PROPOSAL_STATUS_FAILED:
      color = 'warning';
      break;
    case PROPOSAL_STATUS_CANCELLED:
      color = 'error';
      break;
    case PROPOSAL_STATUS_PASSED:
      color = 'success';
      break;
    default:
      break;
  }
  return color;
};
