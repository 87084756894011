import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

const CarouselItem = ({ children }) => {
  return (
    <Grid
      container
      key="content"
      rowSpacing={'16px'}
      sx={{
        px: '27px',
        mt: '0px',
      }}
    >
      {children}
    </Grid>
  );
};

CarouselItem.propTypes = {
  children: PropTypes.any,
};

export default CarouselItem;
