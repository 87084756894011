/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Snackbar, Box } from '@mui/material';
import React, { useMemo } from 'react';
import store from '../../constants/store';
import { STORE_KEYS } from '../../constants/store/constant';
import { useNotification } from './hook';

export const Notification = () => {
  const [notification] = store.useState(STORE_KEYS.id.notification);
  const { clearNotification } = useNotification();

  const bgcolor = useMemo(() => {
    if (notification.type === 'success') return '#269026';
    if (notification.type === 'error') return '#FF6142';
    if (notification.type === 'warning') return '#dca31f';
    if (notification.type === 'confirm') return '#dca31f';
    else return '#FFFFFF';
  }, [notification]);

  const handleClose = () => clearNotification();

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={notification.timeout}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: { sm: '480px', xs: '100%' },
        }}
      >
        <Box
          sx={{
            width: '80px',
            backgroundImage: `url(/images/notification-${notification.type}.svg)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></Box>
        <Box
          sx={{
            bgcolor: bgcolor,
            width: 'calc(100% - 80px)',
            color: '#FFFFFF',
            p: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <div>
            <p
              style={{
                margin: '0 0 4px 0',
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              {notification.message}
            </p>
            <p
              style={{
                margin: '0 0 4px 0',
                fontSize: '14px',
                fontWeight: '500',
              }}
            >
              {notification.detail ? notification.detail : ''}
            </p>
            {notification.type !== 'confirm' && (
              <span
                style={{
                  fontSize: '14px',
                  textDecoration: 'underline',
                  marginTop: '12px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={handleClose}
              >
                Dismiss
              </span>
            )}
            {notification.type === 'confirm' && (
              <>
                <span
                  style={{
                    fontSize: '14px',
                    textDecoration: 'underline',
                    marginTop: '12px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={notification.onOk}
                >
                  Ok
                </span>
                <span
                  style={{
                    fontSize: '14px',
                    textDecoration: 'underline',
                    marginTop: '12px',
                    marginLeft: '24px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={notification.onExit}
                >
                  Exit
                </span>
              </>
            )}
          </div>
        </Box>
      </Box>
    </Snackbar>
  );
};
