import React from 'react';
import { AdminAdd, AdminsView } from '../../components/Admins';
import Header from '../../components/Header';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import store from '../../constants/store';

const Admins = () => {
  const [stepState] = store.useState(STORE_KEYS.id.step);

  return (
    <>
      <Header pageTitle="Admins" />
      {stepState === STEP.ADMIN_ADD ||
      stepState === STEP.ADMIN_EDIT ||
      stepState === STEP.DASHBOARD_ADMIN_EDIT ? (
        <AdminAdd />
      ) : (
        <AdminsView />
      )}
    </>
  );
};

export default Admins;
