import {
  PROPOSAL_TYPE_ADD,
  PROPOSAL_TYPE_REMOVE,
  PROPOSAL_TYPE_REPLACE,
  PROPOSAL_TYPE_MINT,
  PROPOSAL_TYPE_NEW_SHAREHOLDER,
} from '../constants/ProposalTypes';
import { getLowerCaseAddress } from './index';
import { getEtherFromWei } from './unit';
import { shortAddress } from './shortAddress';

export const getProposalDescription = (proposal, account, tokenName) => {
  let str = '';
  const _account = getLowerCaseAddress(account);
  const _owner = getLowerCaseAddress(proposal?.walletAddr);
  const _candidate = getLowerCaseAddress(proposal?.candidate);
  const _admin = getLowerCaseAddress(proposal?.admin);
  const _amount = getEtherFromWei(proposal?.amount);

  let subject = shortAddress(_owner);

  if (_owner === _account) subject = 'You';

  switch (proposal?.type) {
    case PROPOSAL_TYPE_ADD:
      str = `<b>${subject}</b> created a new proposal to add <b>${shortAddress(
        _admin
      )}</b> as administrator`;
      break;
    case PROPOSAL_TYPE_REMOVE:
      str = `<b>${subject}</b> created a new proposal to remove <b>${shortAddress(
        _admin
      )}</b> from admin list`;
      break;
    case PROPOSAL_TYPE_REPLACE:
      str = `<b>${subject}</b> created a new proposal to replace <b>${shortAddress(
        _admin
      )}</b> to <b>${shortAddress(_candidate)}</b>`;
      break;
    case PROPOSAL_TYPE_MINT:
      str = `<b>${subject}</b> created a new proposal to mint <b>${
        _amount || 0
      } ${tokenName}</b>`;
      break;
    case PROPOSAL_TYPE_NEW_SHAREHOLDER:
      str = `<b>${subject}</b> created a new proposal to mint <b>${
        _amount || 0
      } ${tokenName}</b> to <b>${shortAddress(_candidate)}</b>`;
      break;
    default:
      str = 'New proposal';
      break;
  }
  return str;
};
