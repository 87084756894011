import { ChevronRight, Help } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import WELabelButton from '../Button/WELabelButton';
import WECard from '../Card/WECard';

const DashboardCard = (props) => {
  const { children, title, seeFlag = false, seeAll, tooltip = '' } = props;
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleClick = () => {
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  };

  return (
    <WECard display="flex" flexDirection="column" gap={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: '100%',
          height: '28px',
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#28282B',
          }}
        >
          {title}
          {tooltip !== '' && (
            <Tooltip
              title={tooltip}
              open={showTooltip}
              TransitionComponent={Zoom}
            >
              <IconButton size="small" color="primary" onClick={handleClick}>
                <Help fontSize="small" htmlColor="#020433" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
        {seeFlag && (
          <WELabelButton
            size="small"
            endIcon={<ChevronRight htmlColor="#28282B" />}
            onClick={() => seeAll('shareholders')}
            sx={{
              margin: '0 0 0 auto',
            }}
          >
            See All
          </WELabelButton>
        )}
      </Stack>
      {children}
    </WECard>
  );
};

DashboardCard.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  seeFlag: PropTypes.bool,
  seeAll: PropTypes.func,
  tooltip: PropTypes.string,
};

DashboardCard.defaultProps = {
  children: null,
  title: null,
  seeFlag: false,
  seeAll: null,
  tooltip: '',
};

export default DashboardCard;
