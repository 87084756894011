import { Button, DialogContent, DialogTitle, Divider } from '@mui/material';
import React from 'react';
import { WEModal } from './DetailDialog';
import Info from './Info';
import Transition from './Transition';
import { ModalHead } from '../AppBar';
import PropTypes from 'prop-types';

const BusinessUpdateModal = ({
  idx,
  id,
  isAdmin,
  open,
  onClose,
  editHandler,
  deleteHandler,
  title,
  content,
}) => {
  return (
    <WEModal
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        <ModalHead
          title={'Business Update'}
          color={'#FFDB0B'}
          customClose={onClose}
        />
      </DialogTitle>
      <DialogContent>
        <Info label="Title" value={title} textAlign="right" multiline={false} />
        <Info
          sx={{ display: 'flex' }}
          label="Content"
          value={content}
          textAlign="left"
          multiline={true}
        />
        {isAdmin && (
          <Button
            sx={{ float: 'right', width: '120px', mr: '10px' }}
            onClick={() => {
              deleteHandler(id, idx);
              onClose();
            }}
          >
            Delete
          </Button>
        )}
        {isAdmin && (
          <Button
            sx={{ float: 'right', width: '120px', mr: '10px' }}
            onClick={() => editHandler(idx)}
          >
            Edit
          </Button>
        )}
      </DialogContent>
      <Divider />
    </WEModal>
  );
};

BusinessUpdateModal.propTypes = {
  idx: PropTypes.any,
  id: PropTypes.any,
  isAdmin: PropTypes.bool,
  open: PropTypes.func,
  onClose: PropTypes.func,
  editHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.any,
};

BusinessUpdateModal.defaultProps = {
  idx: PropTypes.any,
  id: PropTypes.any,
  isAdmin: PropTypes.bool,
  open: PropTypes.func,
  onClose: PropTypes.func,
  editHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.any,
};

export default BusinessUpdateModal;
