import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Container } from '@mui/system';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';

import SwipeableViews from 'react-swipeable-views';

import Stack from '@mui/material/Stack';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';

import { HomeAppBar, ModalHead } from '../../components/AppBar';
import { TransactionDetailModal } from '../../components/Dialog';
import Header from '../../components/Header';
import { DatePicker, Select } from '../../components/Input';
import { useNotification } from '../../components/Notification/hook';
import { TabPanel } from '../../components/Tab';

import { Input, InputLabel } from '../../components/Input';

import { sendMails } from '../../apis';
import { downloadFile } from '../../utils/downloadFile';
import { mobileCheck } from '../../utils/mobileCheck';
import { TransactionList } from './Dashboard';

import { useWeb3React } from '@web3-react/core';
import ScrollToTop from '../../components/ScrollToTop';
import store from '../../constants/store';
import {
  STORE_KEYS,
  TransactionType,
  TxAssets,
  TxCategories,
} from '../../constants/store/constant';

const SearchCategories = ['All', ...TxCategories];
const isSearchCategory = (val) => SearchCategories.includes(val);

const SearchAssets = ['All', ...TxAssets];
const isSearchAsset = (val) => SearchAssets.includes(val);

const DateType = {
  Start: 0,
  End: 1,
};

const Transactions = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { wepID, weBack, pTab } = state;
  const { account } = useWeb3React();
  const { displaySuccess, displayWarning } = useNotification();

  const [enterprises] = store.useState(STORE_KEYS.id.enterprises);
  const [enterprise, setEnterprise] = React.useState(
    enterprises.tempEnterprise
  );

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [category, setCategory] = useState('All');
  const [asset, setAsset] = useState('All');
  const [startDate, setStartDate] = useState(dayjs('2000-01-01'));
  const [endDate, setEndDate] = useState(dayjs());

  const [toAddress, setToAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleDateChange = (type, startDate, endDate) => (date) => {
    let newStartDate;
    let newEndDate;
    switch (type) {
      case DateType.Start:
        newStartDate = date
          .clone()
          .set('hour', 0)
          .set('second', 0)
          .set('millisecond', 0);
        if (newStartDate.isAfter(endDate)) {
          displayWarning({
            message: 'Invalid start date. Start date is after end date.',
            timeout: 5000,
          });
        } else {
          setStartDate(newStartDate);
        }
        break;
      case DateType.End:
        newEndDate = date
          .clone()
          .set('hour', 23)
          .set('second', 59)
          .set('millisecond', 999);
        if (newEndDate.isBefore(startDate)) {
          displayWarning({
            message: 'Invalid end date. End date is before start date.',
            timeout: 5000,
          });
        } else {
          setEndDate(newEndDate);
        }
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (type) => (evt) => {
    const temp = evt.target.value;
    switch (type) {
      case 'asset':
        if (isSearchAsset(temp)) setAsset(temp);
        break;
      case 'category':
        if (isSearchCategory(temp)) setCategory(temp);
        break;
      default:
        break;
    }
  };

  const [transactions, setTrnasactions] = useState([]);

  useEffect(() => {
    setTrnasactions(enterprise.transactions);
  }, [enterprise]);

  const back = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack, pTab } });
  };

  // Tramsaction View Dialog
  const [trxDlgOpen, setTrxDlgOpen] = useState(false);
  const [trxIdx, setTrxIdx] = useState(-1);
  const trxReject = () => {
    setTrxDlgOpen(false);
  };
  const trxAgree = () => {
    setTrxDlgOpen(false);
  };

  const trxClickHandler = (idx) => {
    setTrxDlgOpen(true);
    setTrxIdx(idx);
  };

  const validateEmail = (email) => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const exportToCsv = async (e) => {
    e.preventDefault();
    let data = transactions.filter((trx) => {
      const trxDate = dayjs(trx.created_at);
      return startDate.isBefore(trxDate) && endDate.isAfter(trxDate);
    });
    if (category !== 'All')
      data = data.filter((trx) => trx.category === category);
    if (asset !== 'All') data = data.filter((trx) => trx.asset === asset);
    switch (value) {
      case 0:
        break;
      case 1:
        data = data.filter((trx) => trx.isSend);
        break;
      case 2:
        data = data.filter((trx) => !trx.isSend);
        break;
      default:
        break;
    }

    let headers = ['id,asset,type,category,amount,note,date,to,from'];
    const date = new Date(); // create a new date object with the current date/time
    const year = date.getFullYear(); // get the 4 digit year (e.g. 2022)
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // get the month (added 1 because the month array is zero-indexed, then padded with a leading zero if necessary)
    const day = ('0' + date.getDate()).slice(-2); // get the day (padded with a leading zero if necessary)
    const dateString = `${year}${month}${day}`;
    let filename = `${enterprise.info.name.replace(
      ' ',
      '_'
    )}_Transactions_${dateString}.csv`;
    // switch (value) {
    //   case 0:
    //     filename = 'transactions.csv';
    //     break;
    //   case 1:
    //     filename = 'transactions_sent.csv';
    //     break;
    //   case 2:
    //     filename = 'transactions_received.csv';
    //     break;
    //   default:
    //     filename = 'transactions.csv';
    // }

    // Convert users data to a csv
    let usersCsv = data.reduce((acc, trx) => {
      const {
        id,
        asset,
        type,
        category,
        amount,
        note,
        created_at,
        // isSend,
        to,
        from,
      } = trx;
      const createdAt_Date = new Date(created_at).toLocaleString('en-us', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
      acc.push(
        [
          id,
          asset,
          type,
          category,
          amount,
          note,
          '"' + createdAt_Date + '"',
          to,
          from,
        ].join(',')
      );
      return acc;
    }, []);

    try {
      if (mobileCheck()) {
        if (!toAddress || !validateEmail(toAddress)) return;

        setLoading(true);
        const res = await sendMails({
          fromAddress: 'admin@worldenterprise.io',
          toAddresses: [toAddress],
          subject: 'WE',
          content: 'Transactions CSV',
          filename: filename,
          attachment: [...headers, ...usersCsv].join('\n'),
          type: 'text/csv',
        });

        if (res.data) {
          if (res.data.success) {
            displaySuccess({
              message: 'Success',
              detail: 'CSV file was sent in email!',
              timeout: 5000,
            });
            setToAddress('');
          } else {
            console.log(res.data.err);
          }
        } else {
          console.log(res.err);
        }
        setLoading(false);
      } else {
        downloadFile({
          data: [...headers, ...usersCsv].join('\n'),
          fileName: filename,
          fileType: 'text/csv',
        });
        displaySuccess({
          message: 'Success',
          detail: 'CSV file was exported!',
          timeout: 5000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetFilter = () => {
    setAsset(SearchAssets[0]);
    setCategory(SearchCategories[0]);
    if (typeof enterprise.info.created_at === 'number') {
      let startDate = dayjs(enterprise.info.created_at * 1000);
      setStartDate(startDate);
    } else {
      setStartDate(dayjs('2000-01-01'));
    }
    let endDate = dayjs()
      .set('hour', 23)
      .set('second', 59)
      .set('millisecond', 999);
    setEndDate(endDate);
  };

  useEffect(() => {
    setEnterprise(enterprises.tempEnterprise);
  }, [enterprises.tempEnterprise]);

  useEffect(
    () => {
      if (enterprise.admins.length === 0) {
        const res = JSON.parse(localStorage.getItem('enterprise'));
        setEnterprise(res);
      } else {
        localStorage.setItem('enterprise', JSON.stringify(enterprise));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    resetFilter();
  }, [enterprise]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ScrollToTop />
      <Header pageTitle={'Transactions'} />
      <HomeAppBar position="absolute" />
      <Box sx={{ bgcolor: '#E5E5E5', width: '100%' }}>
        <ModalHead
          title={enterprise.info.name}
          customClose={back}
          color="#FFDB0B"
        />
        <Box sx={{ px: 4 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ position: 'relative', py: 4.3 }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              Transactions
            </Typography>
            <IconButton
              sx={{ position: 'absolute', right: -8 }}
              onClick={(e) =>
                mobileCheck() ? setOpenEmailModal(true) : exportToCsv(e)
              }
            >
              <VerticalAlignBottomIcon
                sx={{ color: '#111827', fontSize: 24 }}
              />
            </IconButton>
          </Stack>
          <Select
            id="category"
            value={value}
            onChange={handleChange}
            IconComponent={KeyboardArrowDownIcon}
            sx={{
              px: 1,
              fontSize: 14,
              width: '100%',
              fontWeight: 500,
              color: '#FFFFFF',
              bgcolor: '#28282B',
              borderRadius: '6px',
              '& svg': {
                color: '#ffffff',
              },
            }}
          >
            {TransactionType.map((type, idx) => {
              return (
                <MenuItem key={idx} value={idx} sx={{ height: 25 }}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Divider sx={{ mx: 2, mt: 4 }} />

        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {[0, 1, 2].map((index, key) => (
            <TabPanel
              key={key}
              value={value}
              index={index}
              dir={theme.direction}
            >
              <Box height="calc(100vh - 374px)">
                <Stack spacing={1.25}>
                  <Select
                    id="category"
                    value={category}
                    onChange={handleSelectChange('category')}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      width: '100%',
                      borderWidth: 0,
                      borderRadius: '6px',
                      '& fieldset': {
                        borderWidth: 0,
                      },
                    }}
                  >
                    {SearchCategories.map((cate, idx) => {
                      return (
                        <MenuItem key={idx} value={cate}>
                          {cate === 'All' ? 'All Category' : cate}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Select
                    id="asset"
                    value={asset}
                    onChange={handleSelectChange('asset')}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      width: '100%',
                      borderWidth: 0,
                      borderRadius: '6px',
                      '& fieldset': {
                        borderWidth: 0,
                      },
                    }}
                  >
                    {SearchAssets.map((ast, idx) => {
                      //possible solution is to remove what is after colon
                      return (
                        <MenuItem key={idx} value={ast}>
                          {(() => {
                            switch (ast) {
                              case 'All':
                                return 'All Assets';
                              case 'TOKEN':
                                return enterprise.info.tokenName;
                              default:
                                return ast;
                            }
                          })()}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Stack direction="row" spacing={1.25} sx={{ width: '100%' }}>
                    <Box sx={{ width: '50%' }}>
                      <DatePicker
                        sx={{
                          width: '100%',
                          borderWidth: 0,
                          overflow: 'hidden',
                          borderRadius: '6px',
                          '& fieldset': {
                            borderWidth: 0,
                          },
                          '& input': {
                            borderWidth: '0px !important',
                          },
                        }}
                        onChange={handleDateChange(
                          DateType.Start,
                          startDate,
                          endDate
                        )}
                        value={startDate}
                      />
                    </Box>
                    <Box sx={{ width: '50%' }}>
                      <DatePicker
                        sx={{
                          width: '100%',
                          borderWidth: 0,
                          overflow: 'hidden',
                          borderRadius: '6px',
                          '& fieldset': {
                            borderWidth: 0,
                          },
                          '& input': {
                            borderWidth: '0px !important',
                          },
                        }}
                        onChange={handleDateChange(
                          DateType.End,
                          startDate,
                          endDate
                        )}
                        value={endDate}
                      />
                    </Box>
                  </Stack>
                  <Button
                    variant="outlined"
                    onClick={resetFilter}
                    sx={{
                      my: '24px !important',
                      borderWidth: 2,
                      color: '#28282B',
                      borderRadius: 25,
                      borderColor: '#28282B',
                      bgcolor: 'transparent',
                      '&:hover': {
                        borderWidth: 2,
                        color: '#28282B',
                        borderColor: '#28282B',
                        bgcolor: 'transparent',
                      },
                    }}
                  >
                    Reset filter
                  </Button>
                </Stack>
                <Container sx={{ p: 0 }}>
                  <TransactionList
                    transactions={(() => {
                      let temp = transactions.filter((trx) => {
                        const trxDate = dayjs(trx.created_at);
                        return (
                          startDate.isBefore(trxDate) &&
                          endDate.isAfter(trxDate)
                        );
                      });
                      if (category !== 'All')
                        temp = temp.filter((trx) => trx.category === category);
                      if (asset !== 'All')
                        temp = temp.filter((trx) => trx.asset === asset);
                      console.log({ temp, category });
                      switch (index) {
                        case 0:
                          return temp;
                        case 1:
                          return temp.filter(
                            (trx) =>
                              trx.from.toUpperCase() === account.toUpperCase()
                          );
                        case 2:
                          return temp.filter(
                            (trx) =>
                              trx.to.toUpperCase() === account.toUpperCase()
                          );
                        default:
                          return temp;
                      }
                    })()}
                    onClickItem={trxClickHandler}
                    account={account}
                    enterprise={enterprise}
                  />
                </Container>
              </Box>
            </TabPanel>
          ))}
        </SwipeableViews>
      </Box>

      <TransactionDetailModal
        open={trxDlgOpen}
        handleClose={() => setTrxDlgOpen(false)}
        reject={trxReject}
        agree={trxAgree}
        value={transactions[trxIdx]}
        type={
          enterprise.transactions[trxIdx] &&
          enterprise.transactions[trxIdx].type
        }
      />
      <Dialog
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        sx={{ width: '100%' }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ textAlign: 'center', paddingTop: 6 }}
        >
          Enter your email to receive a csv of the transactions you’ve selected
        </DialogTitle>
        <DialogContent>
          <Box width="100%">
            <InputLabel shrink htmlFor="useremail">
              Email address
            </InputLabel>
            <Input
              id="useremail"
              value={toAddress}
              fullWidth
              sx={{ mt: 1 }}
              onChange={(e) => setToAddress(e.target.value)}
            />
          </Box>
        </DialogContent>
        {loading ? (
          <PropagateLoader
            color="#FFDB0A"
            loading
            style={{
              display: 'block',
              height: '10px',
              width: '14px',
              margin: 'auto auto 50px',
              paddingLeft: '-20px',
            }}
          />
        ) : (
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button
              onClick={exportToCsv}
              sx={{
                mx: 'auto',
                width: '100%',
                backgroundColor: '#28282B',
                borderRadius: '50px',
                '&:hover': { background: '#28282B' },
              }}
            >
              Send
            </Button>
          </DialogActions>
        )}
        <IconButton
          onClick={() => setOpenEmailModal(false)}
          sx={{
            position: 'absolute',
            top: '9px',
            right: '12px',
            '&:hover': { background: 'none' },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </>
  );
};

export default Transactions;
