import { CameraAlt, Person } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ImageEditorModal, PhotoEditorModal, ToolTip } from '../Dialog';
import { AddressInput, Input, InputLabel, Select } from '../Input';
import { useNotification } from '../Notification/hook';

import { useTheme } from '@mui/material/styles';
import { upsertDraft, upsertEnterprise } from '../../apis';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import { useEnterprise } from '../../hook/weEnterprise';
import { useWeb3 } from '../../hook/web3';
import { useAutoConnect } from '../../utils/AutoConnect';
import { simpleErrorMessage } from '../../utils/errorMessage';
import { ERRORS } from '../../utils/errors';
import { generateEnterpriseInfo } from '../../utils/generateEnterpriseInfo';
import { CreateAppBar } from '../AppBar';
import { WEButton } from '../Button';
import ScrollToTop from '../ScrollToTop';

const DetailCreate = () => {
  const theme = useTheme();
  const { displaySuccess, displayError } = useNotification();
  const navigation = useNavigate();
  const { account } = useAutoConnect();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const enterprise = enterprises.tempEnterprise;
  const [step, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterpriseInfo, setEnterpriseInfo] = useState({
    ...enterprises.tempEnterprise.info,
  });
  const [address, setAddress] = useState(enterprises.tempEnterprise.info.addr);
  const [editorOpen, setEditorOpen] = useState(false);
  const [image, setImage] = useState(undefined);
  const [backgroundImage, setBackgroundImage] = useState(undefined);
  const [imageEditorOpen, setImageEditorOpen] = useState(false);
  const enterpriseHandler = useEnterprise(enterprise.address);
  const web3 = useWeb3();

  const handleFileChange = async (event, type) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) return;
    if (type === 'background') {
      setImageEditorOpen(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setBackgroundImage(reader.result?.toString() || '');
      });
      reader.readAsDataURL(fileObj);
    } else {
      setEditorOpen(true);
      setImage(fileObj);
    }
  };

  const editorClose = () => {
    // setImage(undefined)
    setEditorOpen(false);
    setImageEditorOpen(false);
  };

  const imageEditorOK = (ipfsURL) => {
    setEnterpriseInfo((prev) => {
      return {
        ...prev,
        backgroundUrl: ipfsURL,
      };
    });
  };

  const editorOK = (ipfsURL) => {
    setEnterpriseInfo((prev) => {
      return {
        ...prev,
        logo: ipfsURL,
      };
    });
  };

  const handleInputChange = (type) => (evt) => {
    const value = evt.currentTarget.value;
    switch (type) {
      case 'name':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            name: value,
          };
        });
        break;
      case 'tokenName':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            tokenName: value,
          };
        });
        break;
      case 'description':
        setEnterpriseInfo((prev) => {
          // length check for description for WE
          if (value?.length >= 100) return prev;
          else
            return {
              ...prev,
              description: value,
            };
        });
        break;
      case 'website':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            website: value,
          };
        });
        break;
      case 'zip':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            zip: value,
          };
        });
        break;
      case 'address2':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            address2: value,
          };
        });
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (type) => (evt) => {
    const value = evt.target.value;

    switch (type) {
      case 'isRegisterd':
        let isRegistered = false; //eslint-disable-line no-case-declarations
        if (value === 'Yes') {
          isRegistered = true;
        } else isRegistered = false;
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            isRegisterd: isRegistered,
          };
        });
        break;
      case 'type':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            type: value,
          };
        });
        break;
      case 'country':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            country: value,
          };
        });
        break;
      case 'state':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            state: value,
          };
        });
        break;
      case 'city':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            city: value,
          };
        });
        break;
      default:
        break;
    }
  };

  const handleAddressChange = (type) => (addr) => {
    switch (type) {
      case 'address1':
        if (!addr || !addr?.value?.place_id) {
          return setAddress({
            label: '',
            value: {
              description: '',
              matched_substrings: [],
              place_id: '',
              reference: '',
              structured_formatting: {
                main_text: '',
                main_text_matched_substrings: [],
                secondary_text: '',
              },
              terms: [],
              types: [],
            },
          });
        }
        const place_id = addr?.value.place_id || ''; //eslint-disable-line no-case-declarations
        // const key = process.env.REACT_APP_GOOGLEMAP_APIKEY;
        geocodeByPlaceId(place_id).then((result) => {
          let address1 = '';
          let postcode = '';
          let city = '';
          let state = '';
          let country = '';
          result[0].address_components.map((component) => {
            const componentType = component.types[0];
            switch (componentType) {
              case 'street_number': {
                address1 = `${component.long_name} ${address1}`;
                break;
              }

              case 'route': {
                address1 += component.short_name;
                break;
              }

              case 'postal_code': {
                postcode = `${component.long_name}${postcode}`;
                break;
              }

              case 'postal_code_suffix': {
                postcode = `${postcode}-${component.long_name}`;
                break;
              }

              case 'locality':
                city = component.long_name;
                break;

              case 'administrative_area_level_1': {
                state = component.short_name;
                break;
              }

              case 'country':
                country = component.long_name;
                break;
              default:
                break;
            }
            return true;
          });
          setAddress(addr);

          setEnterpriseInfo((prev) => {
            return {
              ...prev,
              addr: addr,
              address1: addr.label,
              city: city,
              state: state,
              country: country,
              zip: postcode,
            };
          });
        });
        break;
      default:
        break;
    }
  };

  const continueHandler = async (info) => {
    if (!info?.logo) {
      return displayError({
        message: 'Please upload your enterprise profile picture',
        reason: '',
        timeout: 5000,
      });
    }
    if (!info?.name) {
      return displayError({
        message: 'Please input enterprise name',
        reason: '',
        timeout: 5000,
      });
    }
    if (!info?.tokenName) {
      return displayError({
        message: 'Please input token name',
        reason: '',
        timeout: 5000,
      });
    }
    if (!info?.description) {
      return displayError({
        message: 'Please input enterprise description',
        reason: '',
        timeout: 5000,
      });
    }

    updateEnterprises((prev) => {
      prev.tempEnterprise.info = info;
      return prev;
    });
    let tenterprise = { ...enterprise, info };
    switch (step) {
      case STEP.CREATE_ENTERPRISE_INPUT:
        let _infoStr = JSON.stringify(info); //eslint-disable-line no-case-declarations

        let id = uuidv4(); //eslint-disable-line no-case-declarations
        updateEnterprises((prev) => {
          prev.tempEnterprise.draftId = id;
          return prev;
        });
        try {
          await upsertDraft(id, {
            admin_address: account,
            status: 'PROCESSING',
            metadata: _infoStr,
          });
          setStep(STEP.CREATE_SHAREHOLDERS_VIEW);
        } catch (error) {
          console.log('Draft Save ERROR'); //eslint-disable-line no-console
          setStep(STEP.CREATE_SHAREHOLDERS_VIEW);
        }
        break;
      case STEP.SETTING_COMPANY_EDIT:
        try {
          let ENTERPRISE_INFO = await generateEnterpriseInfo(tenterprise);

          await enterpriseHandler.methods.updateInfo(ENTERPRISE_INFO).send({
            from: account,
            gasLimit: web3.eth.getBlock('latest').gasLimit,
          });

          console.log('create we and calling upsert api');

          await upsertEnterprise(
            tenterprise.address,
            ENTERPRISE_INFO.ipfs,
            tenterprise.info.name,
            tenterprise.info.tokenName
          );
          setStep(STEP.SETTING_COMPANY_REVIEW);
          displaySuccess({ message: 'Company updated', timeout: 5000 });
        } catch (e) {
          console.log(e); //eslint-disable-line no-console
          displayError({
            message: ERRORS.TO_REJECT,
            reason: simpleErrorMessage(e.message),
            timeout: 5000,
          });
          return;
        }
        break;
      default:
        break;
    }
  };

  const closeHandler = () => {
    switch (step) {
      case STEP.CREATE_ENTERPRISE_INPUT:
        setStep(STEP.CREATE_ENTERPRISE_INPUT);
        navigation(-2);
        break;
      case STEP.SETTING_COMPANY_EDIT:
        setStep(STEP.SETTING_COMPANY_REVIEW);
        break;

      default:
        break;
    }
  };

  let address2Ref = useRef(null);

  return (
    <>
      <ScrollToTop />
      {step === STEP.SETTING_COMPANY_EDIT && (
        <CreateAppBar
          title="Edit Enterprise Details"
          close={closeHandler}
          helpEnabled={false}
        />
      )}
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          px: 3,
        }}
      >
        <IconButton
          // color="primary"
          aria-label="upload picture"
          component="label"
          sx={{
            width: '100%',
            borderRadius: 0,
          }}
        >
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={(e) => {
              handleFileChange(e, 'background');
            }}
            value={''}
          />
          <Badge
            overlap="circular"
            sx={{
              width: '100%',
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={
              <Avatar
                sx={{
                  width: '24px',
                  height: '24px',
                  backgroundColor: theme.palette.primary.dark,
                }}
              >
                <CameraAlt sx={{ width: '16px', color: '#FFFFFF' }} />
              </Avatar>
            }
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              position="relative"
              sx={{
                height: '203px',
              }}
            >
              {enterpriseInfo.backgroundUrl ? (
                <img
                  alt="logo"
                  src={enterpriseInfo.backgroundUrl}
                  style={{
                    width: '100%',
                    position: 'absolute',
                    height: '100%',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                  }}
                />
              ) : null}

              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={(e) => {
                    handleFileChange(e, 'logo');
                  }}
                  value={''}
                />
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  badgeContent={
                    <Avatar
                      sx={{
                        width: '24px',
                        height: '24px',
                        backgroundColor: theme.palette.primary.dark,
                      }}
                    >
                      <CameraAlt sx={{ width: '16px', color: '#FFFFFF' }} />
                    </Avatar>
                  }
                >
                  <Avatar sx={{ width: '111px', height: '111px' }}>
                    {enterpriseInfo.logo !== '' ? (
                      <img
                        alt="logo"
                        src={enterpriseInfo.logo}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      <Person
                        sx={{ width: '70px', height: '70px' }}
                        htmlColor="#4B4749"
                      />
                    )}
                  </Avatar>
                </Badge>
              </IconButton>
            </Box>
          </Badge>
        </IconButton>
        <Divider flexItem />
        <Box width="100%" mt={6}>
          <InputLabel shrink htmlFor="name" sx={{ color: '#6F7287' }}>
            Enterprise name
          </InputLabel>
          <Input
            name="enterprise-name"
            id="name"
            autoComplete="off"
            value={enterpriseInfo.name}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('name')}
          />
        </Box>
        <Box width="100%" mt={3}>
          <InputLabel shrink htmlFor="tokenName" sx={{ color: '#6F7287' }}>
            Token name
            <ToolTip title="You cannot change the Token Name">
              <IconButton size="small" color="primary">
                <HelpIcon fontSize="small" color="primary" />
              </IconButton>
            </ToolTip>
          </InputLabel>
          <Input
            name="token-name"
            autoComplete="off"
            id="tokenName"
            value={enterpriseInfo.tokenName}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('tokenName')}
          />
        </Box>
        <Box width="100%" mt={3}>
          <InputLabel shrink htmlFor="description" sx={{ color: '#6F7287' }}>
            Enterprise description
          </InputLabel>
          <Input
            name="enterprise-description"
            id="description"
            autoComplete="off"
            value={enterpriseInfo.description}
            fullWidth
            sx={{ mt: 1 }}
            multiline
            rows={4}
            onChange={handleInputChange('description')}
          />
        </Box>
        <Box width="100%" mt={3}>
          <InputLabel shrink htmlFor="isRegisterd" sx={{ color: '#6F7287' }}>
            In this existing enterprise registered with a govermment
          </InputLabel>
          <Select
            id="isRegisterd"
            value={enterpriseInfo.isRegisterd ? 'Yes' : 'No'}
            fullWidth
            sx={{ mt: 1 }}
            itemType="select"
            onChange={handleSelectChange('isRegisterd')}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </Box>
        {enterpriseInfo.isRegisterd && (
          <Box width="100%" mt={3}>
            <InputLabel shrink htmlFor="type" sx={{ color: '#6F7287' }}>
              Type of Company
            </InputLabel>
            <Select
              id="type"
              value={enterpriseInfo.type}
              fullWidth
              sx={{ mt: 1 }}
              onChange={handleSelectChange('type')}
            >
              <MenuItem value="LLC">LLC</MenuItem>
              <MenuItem value="C-Corp">C-Corp</MenuItem>
              <MenuItem value="S-Corp">S-Corp</MenuItem>
              <MenuItem value="Non-Profit">Non-Profit</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Box>
        )}

        <Box width="100%" mt={6}>
          <InputLabel shrink htmlFor="website" sx={{ color: '#6F7287' }}>
            Website (optional)
          </InputLabel>
          <Input
            name="website"
            autoComplete="off"
            id="website"
            value={enterpriseInfo.website}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('website')}
          />
        </Box>
        <Box width="100%" mt={3}>
          <InputLabel shrink htmlFor="address1" sx={{ color: '#6F7287' }}>
            Address Line 1 (optional)
          </InputLabel>
          <Box sx={{ p: 0, mt: 1, width: '100%' }}>
            <AddressInput
              address={address}
              onChange={handleAddressChange('address1')}
            />
          </Box>
        </Box>
        <Box width="100%" mt={3}>
          <InputLabel shrink htmlFor="address2" sx={{ color: '#6F7287' }}>
            Address Line 2 (optional)
          </InputLabel>
          <Input
            name="address2"
            id="address2"
            autoComplete="new-address2"
            value={enterpriseInfo.address2 || ''}
            inputRef={address2Ref}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('address2')}
          />
        </Box>
        <Box width="100%" mt={3}>
          <InputLabel shrink htmlFor="country" sx={{ color: '#6F7287' }}>
            Country (optional)
          </InputLabel>
          <Input
            name="countryLine"
            autoComplete="new-countryLine"
            id="country"
            value={enterpriseInfo.country || ''}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleSelectChange('country')}
          />
        </Box>
        <Box width="100%" mt={3}>
          <InputLabel shrink htmlFor="zip" sx={{ color: '#6F7287' }}>
            ZIP (optional)
          </InputLabel>
          <Input
            name="zipLine"
            autoComplete="new-zipLine"
            id="zip"
            value={enterpriseInfo.zip || ''}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange('zip')}
          />
        </Box>
        <Box width="100%" mt={3}>
          <InputLabel shrink htmlFor="state" sx={{ color: '#6F7287' }}>
            State (optional)
          </InputLabel>
          <Input
            name="stateLine"
            autoComplete="new-stateLine"
            id="state"
            value={enterpriseInfo.state || ''}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleSelectChange('state')}
          />
        </Box>
        <Box width="100%" mt={3}>
          <InputLabel shrink htmlFor="city" sx={{ color: '#6F7287' }}>
            City (optional)
          </InputLabel>
          <Input
            name="cityLine"
            autoComplete="new-cityLine"
            id="city"
            value={enterpriseInfo.city || ''}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleSelectChange('city')}
          />
        </Box>
        <Box
          sx={{
            marginTop: '54px',
            mx: 'auto',
            width: { xs: '100%', sm: '370px' },
            pb: 4,
            mt: 2,
            mb: 3,
            textAlign: 'center',
          }}
        >
          <WEButton
            type="primary"
            rounded="xl"
            width={220}
            onClick={() => {
              continueHandler(enterpriseInfo);
            }}
          >
            Continue
          </WEButton>
        </Box>
      </Grid>
      <PhotoEditorModal
        open={editorOpen}
        onClose={editorClose}
        image={image}
        onOK={editorOK}
      />
      <ImageEditorModal
        open={imageEditorOpen}
        onClose={editorClose}
        image={backgroundImage}
        onOK={imageEditorOK}
      />
    </>
  );
};

export default DetailCreate;
