import { Button, styled } from '@mui/material';

const BtnCancel = styled(Button)(({ disable }) => ({
  borderRadius: '10px',
  height: '53px',
  backgroundColor: '#E3E8EB',
  textAlign: 'center',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '17px',
  lineHeight: '22px',
  color: '#4B4749',
  margin: 'auto',
  ':hover': {
    backgroundColor: '#f3f6f8',
  },
  opacity: disable === 'true' ? '0.5' : '1',
}));

export default BtnCancel;
