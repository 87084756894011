import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import ShareholdersList from './ShareholdersList';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import { WEButton } from '../Button';
import { upsertDraft } from '../../apis';
import { useAutoConnect } from '../../utils/AutoConnect';

const ShareholdersView = () => {
  const { account } = useAutoConnect();
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const shareholdersState = enterprises.tempEnterprise.shareholders;
  const draftId = enterprises.tempEnterprise.draftId;
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);

  const addHandler = () => {
    if (stepState === STEP.CREATE_SHAREHOLDERS_VIEW) {
      setStep(STEP.CREATE_SHAREHOLDER_ADD);
    } else {
      setStep(STEP.SHAREHOLDER_ADD);
    }
  };

  const continueHandler = async () => {
    try {
      await upsertDraft(draftId, {
        admin_address: account,
        status: 'PROCESSING',
        shareholders: JSON.stringify(shareholdersState),
      });
      setStep(STEP.CREATE_ADMINS_VIEW);
    } catch (error) {
      console.log('Draft Save ERROR'); //eslint-disable-line no-console
      setStep(STEP.CREATE_ADMINS_VIEW);
    }
  };

  return (
    <>
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          px: 3,
        }}
      >
        <Divider flexItem />
        {shareholdersState.length === 0 ? (
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              m: 0,
              p: 0,
            }}
          >
            <Box
              component="img"
              src="/images/shareholders.svg"
              alt={'splash'}
              sx={{
                right: '0',
                my: 5,
                width: 'min(28.23vh, 53.99vw)',
                height: 'min(26.06vh, 49.83vw)',
              }}
            />
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                maxWidth: '800px',
              }}
            >
              Shareholder have equity and vote on proposals for the World
              Enterprise. Shareholders vote on and approve or reject proposals
              for the community. Each share is equal to 1 vote. Users are become
              shareholders when they buy or are granted shares.
            </Typography>
            <Box
              sx={{
                marginTop: 5,
                mx: 'auto',
                width: { xs: '100%', sm: '370px' },
                pb: 4,
                textAlign: 'center',
              }}
            >
              <WEButton type="primary" rounded="xl" onClick={addHandler}>
                Add Shareholders
              </WEButton>
            </Box>
          </Container>
        ) : (
          <>
            <ShareholdersList />
            {stepState === STEP.CREATE_SHAREHOLDERS_VIEW && (
              <Box
                sx={{
                  marginTop: 5,
                  mx: 'auto',
                  width: { xs: '100%', sm: '370px' },
                  pb: 4,
                  textAlign: 'center',
                }}
              >
                <WEButton
                  type="primary"
                  rounded="xl"
                  width={260}
                  onClick={continueHandler}
                >
                  Continue
                </WEButton>
              </Box>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default ShareholdersView;
