import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

const DonutChart = (props) => {
  const { chartSeries, chartOptions, width, height, colors } = props; //eslint-disable-line no-unused-vars
  const optionDonut = {
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 1,
        expandOnClick: false,
        donut: {
          size: '80%',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: colors,
    states: {},
  };

  return (
    <Chart
      width={width}
      height={height}
      options={{ ...chartOptions, ...optionDonut }}
      series={chartSeries}
      type="donut"
    />
  );
};

DonutChart.propTypes = {
  chartSeries: PropTypes.array,
  chartOptions: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  colors: PropTypes.array,
};

DonutChart.defaultProps = {
  chartSeries: [],
  chartOptions: {},
  width: null,
  height: null,
  colors: [],
};

export default DonutChart;
