import React from 'react';
import { Button, styled } from '@mui/material';

export const fontForSize = (size) => {
  switch (size) {
    case 'small':
      return '12px';
    case 'medium':
      return '16px';
    case 'large':
      return '20px';
  }
};

const StyledLabelButton = styled(Button)(({ theme, type, width, size }) => ({
  border: 0,
  padding: '4px 8px',
  color: type ? theme.palette[type].main : theme.palette.primary.main,
  backgroundColor: 'transparent',
  width: `${width}px`,
  fontSize: fontForSize(size),
  fontFamily: 'Montserrat',
  margin: 'auto',
  '&:hover': {
    color: type ? theme.palette[type].dark : '',
    border: 0,
    backgroundColor: 'transparent',
  },
  '&:active': {
    color: type ? theme.palette[type].dark : '',
    border: 0,
    backgroundColor: 'transparent',
  },
}));

const WELabelButton = (prop) => {
  const { children, type, width, size, ...other } = prop;

  return (
    <StyledLabelButton
      variant="text"
      type={type}
      width={width}
      size={size}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </StyledLabelButton>
  );
};

export default WELabelButton;
