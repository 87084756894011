import { Button, styled } from '@mui/material';

export const convertRound = (rounded) => {
  switch (rounded) {
    case 'xs':
      return '4px';
    case 'sm':
      return '8px';
    case 'md':
      return '12px';
    case 'lg':
      return '16px';
    case 'xl':
      return '24px';
  }
};

export const fontForSize = (size) => {
  switch (size) {
    case 'small':
      return '12px';
    case 'medium':
      return '16px';
    case 'large':
      return '20px';
  }
};

const WEButton = styled(Button)(
  ({ theme, type, variant, width, rounded, size }) => ({
    borderRadius: `${convertRound(rounded)} !important`,
    color: type
      ? variant === 'outlined'
        ? theme.palette[type].main
        : theme.palette[type].contrastText
      : '',
    backgroundColor:
      variant === 'outlined'
        ? 'transparent'
        : type
        ? theme.palette[type].main
        : '',
    borderColor: type
      ? variant === 'outlined'
        ? theme.palette[type].light
        : theme.palette[type].main
      : '',
    borderWidth: variant === 'outlined' ? '1px' : '0px',
    width: `${width}px`,
    padding: '12px 20px',
    fontSize: fontForSize(size),
    margin: 'auto',
    '&:hover': {
      color: type
        ? variant === 'outlined'
          ? theme.palette[type].dark
          : theme.palette[type].contrastText
        : '',
      backgroundColor:
        variant === 'outlined'
          ? 'transparent'
          : type
          ? theme.palette[type].dark
          : '',
      borderColor: type
        ? variant === 'outlined'
          ? theme.palette[type].dark
          : theme.palette[type].main
        : '',
    },
    '&:active': {
      color: type
        ? variant === 'outlined'
          ? theme.palette[type].dark
          : theme.palette[type].contrastText
        : '',
      backgroundColor:
        variant === 'outlined'
          ? 'transparent'
          : type
          ? theme.palette[type].dark
          : '',
      borderColor: type
        ? variant === 'outlined'
          ? theme.palette[type].dark
          : theme.palette[type].main
        : '',
    },
  })
);

export default WEButton;
