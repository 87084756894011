import React, { useEffect, useState } from 'react';
import { Container, Divider } from '@mui/material';
import OrderList from './OrderList';
import {
  WEToggleButton,
  WEToggleButtonGroup,
} from '../ToggleButton/WEToggleButton';
import PropTypes from 'prop-types';

const OrderBook = ({ category = 'BUY' }) => {
  const [categori, setCategori] = useState('BUY');
  const handleChange = (event, value) => {
    if (value !== null) {
      setCategori(value);
    }
  };

  useEffect(() => {
    if (category === 'BUY' || category === 'SELL') {
      setCategori(category);
    }
  }, [category]);

  return (
    <>
      <Divider sx={{ mt: 3, mb: 1.75 }} />
      <Container sx={{ position: 'relative', py: 2, px: 0 }}>
        <WEToggleButtonGroup
          fullWidth
          value={categori}
          exclusive
          onChange={handleChange}
          size="medium"
        >
          <WEToggleButton value="BUY">Buy Orders</WEToggleButton>
          <WEToggleButton value="SELL">Sell Orders</WEToggleButton>
        </WEToggleButtonGroup>
      </Container>
      <Divider sx={{ my: 2 }} />
      <Container sx={{ px: 0 }}>
        <OrderList type={categori} />
      </Container>
    </>
  );
};

OrderBook.propTypes = {
  category: PropTypes.string,
};

export default OrderBook;
