import { List } from '@mui/material';
import React, { useEffect, useState } from 'react';
import store from '../../constants/store';
import { STORE_KEYS } from '../../constants/store/constant';
import { OrderDetailModal } from '../Dialog';
import Item from './Item';
import PropTypes from 'prop-types';

const OrderList = ({ type }) => {
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const [orders, setOrders] = useState(
    JSON.parse(localStorage.getItem('enterprise')).orders
  );
  const [idx, setIdx] = useState(-1);
  const [dlgOpened, setDlgOpened] = useState(false);

  const onClickItem = (id) => {
    setDlgOpened(true);
    setIdx(id);
  };

  useEffect(() => {
    setOrders(JSON.parse(localStorage.getItem('enterprise')).orders);
  }, [enterprises]);

  return (
    <>
      <List dense={true} sx={{ width: '100%', pt: 2, px: 0 }}>
        {orders
          .filter((order) => order.type === type && order.status === 'ACTIVE')
          .sort((a, b) =>
            type === 'BUY'
              ? a.price >= b.price
                ? -1
                : 1
              : a.price <= b.price
              ? -1
              : 1
          )
          .map((order, idx) => {
            return (
              <Item
                key={idx}
                status={order.status}
                price={order.price}
                type={order.type}
                amount={order.amount}
                onClick={() => {
                  onClickItem(order.id);
                }}
              />
            );
          })}
      </List>
      <OrderDetailModal
        open={dlgOpened}
        onClose={() => {
          setDlgOpened(false);
        }}
        order={orders.find((order) => {
          return order.id === idx;
        })}
      />
    </>
  );
};

OrderList.propTypes = {
  type: PropTypes.string,
};

OrderList.defaultProps = {
  type: null,
};

export default OrderList;
