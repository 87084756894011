import PropTypes from 'prop-types';
import { CameraAlt, Person } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { upsertEnterprise } from '../../apis';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import { useAutoConnect } from '../../utils/AutoConnect';
import { simpleErrorMessage } from '../../utils/errorMessage';
import { ERRORS } from '../../utils/errors';
import { generateEnterpriseInfo } from '../../utils/generateEnterpriseInfo';
import { CreateAppBar, ModalHead } from '../AppBar';
import HomeAppBar from '../AppBar/HomeAppBar';
import { ImageEditorModal, PhotoEditorModal } from '../Dialog';
import { EditableInput, InputLabel, Select } from '../Input';
import { useNotification } from '../Notification/hook';
import { WELoadingButton } from '../Button';
import { useNavigate } from 'react-router-dom';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import EnterpriseABI from '../../constants/abi/enterprise.json';
import EnterpriseDevABI from '../../constants/abi/enterprise_dev.json';
import { useWeb3 } from '../../hook/web3';

const initLineState = {
  name: false,
  token: false,
  description: false,
  isRegisterd: false,
  companyType: false,
  website: false,
  address1: false,
  address2: false,
  country: false,
  zip: false,
  state: false,
  city: false,
};
const DetailCreate = (props) => {
  const { isSetting } = props;
  const web3 = useWeb3();
  const [loading, setLoading] = useState(false);
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const initEnterpriseInfo = enterprises.tempEnterprise?.info;
  const [enterprise, setEnterprise] = useState(enterprises.tempEnterprise);
  const {
    displaySuccess,
    displayWarning,
    displayConfirm,
    displayError,
    clearNotification,
  } = useNotification();
  const [step, setStep] = store.useState(STORE_KEYS.id.step);

  const [enterpriseInfo, setEnterpriseInfo] = useState({
    ...enterprises.tempEnterprise.info,
  });
  const [address, setAddress] = useState(enterprises.tempEnterprise.info.addr);

  const [editorOpen, setEditorOpen] = useState(false);
  const [image, setImage] = useState(undefined);
  const [backgroundImage, setBackgroundImage] = useState(undefined);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [imageEditorOpen, setImageEditorOpen] = useState(false);
  const theme = useTheme();
  const { account } = useAutoConnect();
  let [isLineEditable, setIsLineEditable] = useState(initLineState);
  const navigate = useNavigate();

  const isAdmin = useMemo(() => {
    if (account && enterprise && enterprise.admins) {
      const isThere = enterprise.admins.findIndex(
        (e) => e.walletAddr.toLocaleLowerCase() === account.toLocaleLowerCase()
      );
      return isThere !== -1;
    }
    return false;
  }, [enterprise, account]);

  const isEdited = useMemo(() => {
    if (updateFlag) {
      return false;
    }

    if (image || backgroundImage) {
      return true;
    }
    const fieldKeys = Object.keys(initLineState);

    let flag = false;
    fieldKeys.forEach((key) => {
      if (
        isLineEditable[key] &&
        initEnterpriseInfo[key] !== enterpriseInfo[key]
      ) {
        flag = true;
      }
    });

    return flag;
  }, [
    image,
    backgroundImage,
    enterpriseInfo,
    updateFlag,
    initEnterpriseInfo,
    isLineEditable,
  ]);

  const onClose = () => {
    if (isEdited) {
      displayConfirm({
        message: 'Please Update or edits won`t be saved.',
        timeout: 5000,
        onOk: () => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        },
        onExit: () => {
          closeHandler();
        },
      });
    } else {
      closeHandler();
    }
  };

  const closeHandler = () => {
    clearNotification();
    switch (step) {
      case STEP.SETTING_COMPANY_REVIEW:
      case STEP.SETTING_COMPANY_EDIT:
        setStep(STEP.INDEX);
        break;
      default:
        break;
    }
    navigate(-1);
  };

  const editorClose = () => {
    setEditorOpen(false);
    setImageEditorOpen(false);
  };

  const imageEditorOK = (ipfsURL) => {
    setEnterpriseInfo((prev) => {
      return {
        ...prev,
        backgroundUrl: ipfsURL,
      };
    });
  };

  const editorOK = (ipfsURL) => {
    setEnterpriseInfo((prev) => {
      return {
        ...prev,
        logo: ipfsURL,
      };
    });
  };
  const handleFileChange = async (event, type) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) return;
    if (type === 'background') {
      setImageEditorOpen(true);
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setBackgroundImage(reader.result?.toString() || '')
      );
      reader.readAsDataURL(fileObj);
    } else {
      setEditorOpen(true);
      setImage(fileObj);
    }
  };

  const handleSelectChange = (type) => (evt) => {
    const value = evt.target.value;
    let isRegistered;
    switch (type) {
      case 'isRegisterd':
        isRegistered = false;
        if (value === 'Yes') isRegistered = true;
        else isRegistered = false;
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            isRegisterd: isRegistered,
          };
        });
        break;
      case 'type':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            type: value,
          };
        });
        break;
      case 'country':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            country: value,
          };
        });
        break;
      case 'state':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            state: value,
          };
        });
        break;
      case 'city':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            city: value,
          };
        });
        break;
      default:
        break;
    }
  };

  const handleInputChange = (evt, type) => {
    if (type === 'token') {
      return displayError({
        message: 'You cannot change the Token Name',
        reason: '',
        timeout: 5000,
      });
    }
    const value = evt.currentTarget.value;
    let tempData = {};
    tempData[type] = value;
    setEnterpriseInfo((prev) => {
      return {
        ...prev,
        ...tempData,
      };
    });
  };

  const handleAddressChange = (type) => (addr) => {
    switch (type) {
      case 'address1':
        if (!addr || !addr?.value?.place_id) {
          return setAddress({
            label: '',
            value: {
              description: '',
              matched_substrings: [],
              place_id: '',
              reference: '',
              structured_formatting: {
                main_text: '',
                main_text_matched_substrings: [],
                secondary_text: '',
              },
              terms: [],
              types: [],
            },
          });
        }
        const place_id = addr?.value.place_id || ''; //eslint-disable-line no-case-declarations
        // const key = process.env.REACT_APP_GOOGLEMAP_APIKEY;
        geocodeByPlaceId(place_id).then((result) => {
          let address1 = '';
          let postcode = '';
          let city = '';
          let state = '';
          let country = '';
          result[0].address_components.map((component) => {
            const componentType = component.types[0];
            switch (componentType) {
              case 'street_number': {
                address1 = `${component.long_name} ${address1}`;
                break;
              }

              case 'route': {
                address1 += component.short_name;
                break;
              }

              case 'postal_code': {
                postcode = `${component.long_name}${postcode}`;
                break;
              }

              case 'postal_code_suffix': {
                postcode = `${postcode}-${component.long_name}`;
                break;
              }

              case 'locality':
                city = component.long_name;
                break;

              case 'administrative_area_level_1': {
                state = component.short_name;
                break;
              }

              case 'country':
                country = component.long_name;
                break;
              default:
                break;
            }
            return true;
          });
          setAddress(addr);

          setEnterpriseInfo((prev) => {
            return {
              ...prev,
              addr: addr,
              address1: addr.label,
              city: city,
              state: state,
              country: country,
              zip: postcode,
            };
          });
        });
        break;
      default:
        break;
    }
  };

  const continueHandler = async (info) => {
    if (!web3) {
      return;
    }
    if (!info?.logo) {
      return displayWarning({
        message: 'Please upload your enterprise profile picture',
        timeout: 5000,
      });
    }
    if (!info?.name) {
      return displayWarning({
        message: 'Please input enterprise name',
        timeout: 5000,
      });
    }
    if (!info?.tokenName) {
      return displayWarning({
        message: 'Please input token name',
        timeout: 5000,
      });
    }
    if (!info?.description) {
      return displayWarning({
        message: 'Please input enterprise description',
        timeout: 5000,
      });
    }

    updateEnterprises((prev) => {
      prev.tempEnterprise.info = info;
      return prev;
    });
    let tenterprise = { ...enterprise, info };
    setLoading(true);
    let ENTERPRISE_INFO = await generateEnterpriseInfo(tenterprise);

    try {
      const enterpriseHandler = new web3.eth.Contract(
        process.env.REACT_APP_ENV === 'prod' ? EnterpriseABI : EnterpriseDevABI,
        tenterprise.address
      );
      await enterpriseHandler.methods
        .updateInfo(ENTERPRISE_INFO)
        .send({ from: account });
      await upsertEnterprise(
        tenterprise.address,
        ENTERPRISE_INFO.ipfs,
        tenterprise.info.name,
        tenterprise.info.tokenName
      );
      localStorage.setItem('enterprise', JSON.stringify(tenterprise));
      setStep(STEP.SETTING_COMPANY_REVIEW);
      displaySuccess({ message: 'Company updated', timeout: 5000 });
      setUpdateFlag(true);
      setIsLineEditable(initLineState);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      displayError({
        message: ERRORS.TO_REJECT,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
      return;
    }
  };

  const editHandler = (e) => {
    let tempData = {};
    tempData[e] = !isLineEditable[e];
    setIsLineEditable({ ...isLineEditable, ...tempData });
  };

  useEffect(
    () => {
      if (enterprise.admins.length === 0) {
        const res = JSON.parse(localStorage.getItem('enterprise'));
        setEnterpriseInfo(res.info);
        setAddress(res.info.addr);
        setEnterprise(res);
        // setEnterprise()
      } else {
        localStorage.setItem('enterprise', JSON.stringify(enterprise));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Box>
        <Box
          sx={{
            display: {
              sm: 'flex',
              xs: 'flex',
            },
          }}
        >
          <HomeAppBar isEnterpriseEdited={isEdited} />
        </Box>
        <ModalHead
          title={enterpriseInfo.name}
          theme="light"
          customClose={() => onClose()}
          color="#FFDB0B"
        />
        <Box
          sx={{
            display: {
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          <CreateAppBar
            title="Review Enterprise Details"
            close={onClose}
            helpEnabled={false}
          />
        </Box>
        <Grid
          container
          component="main"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            px: 3,
            py: '40px',
            backgroundColor: '#EFEFEF',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            position="relative"
            sx={{
              height: {
                sm: '203px',
                xs: '100%',
              },
            }}
          >
            <Box
              sx={{
                display: {
                  sm: 'none',
                  xs: 'none',
                },
              }}
            >
              {enterpriseInfo.backgroundUrl ? (
                <img
                  alt="logo"
                  src={enterpriseInfo.backgroundUrl}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                  }}
                />
              ) : null}
            </Box>

            <Box
              sx={{
                display: {
                  sm: 'flex',
                  xs: 'flex',
                },
                width: {
                  xs: '100%',
                },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    xs: '15px',
                    sm: '20px',
                  },
                  borderBottom: '2px solid #E3E8EB',
                  paddingBottom: '25px',
                  width: '100%',
                }}
              >
                Review Enterprise Details
              </Typography>
            </Box>
            <IconButton
              // color="primary"
              aria-label="upload picture"
              component="label"
              sx={{
                width: '100%',
                borderRadius: 0,
              }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => {
                  handleFileChange(e, 'background');
                }}
                value={''}
              />
              <Badge
                overlap="circular"
                sx={{
                  width: '100%',
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                badgeContent={
                  <Avatar
                    sx={{
                      width: '24px',
                      height: '24px',
                      backgroundColor: theme.palette.primary.dark,
                    }}
                  >
                    <CameraAlt sx={{ width: '16px', color: '#FFFFFF' }} />
                  </Avatar>
                }
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  position="relative"
                  sx={{
                    height: '203px',
                  }}
                >
                  {enterpriseInfo.backgroundUrl ? (
                    <img
                      alt="logo"
                      src={enterpriseInfo.backgroundUrl}
                      style={{
                        width: '100%',
                        position: 'absolute',
                        height: '100%',
                        top: 0,
                        left: 0,
                        objectFit: 'cover',
                      }}
                    />
                  ) : null}

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        handleFileChange(e, 'logo');
                      }}
                      value={''}
                    />
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      badgeContent={
                        <Avatar
                          sx={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: theme.palette.primary.dark,
                          }}
                        >
                          <CameraAlt sx={{ width: '16px', color: '#FFFFFF' }} />
                        </Avatar>
                      }
                    >
                      <Avatar sx={{ width: '111px', height: '111px' }}>
                        {enterpriseInfo.logo !== '' ? (
                          <img
                            alt="logo"
                            src={enterpriseInfo.logo}
                            style={{ width: '100%' }}
                          />
                        ) : (
                          <Person
                            sx={{ width: '70px', height: '70px' }}
                            htmlColor="#4B4749"
                          />
                        )}
                      </Avatar>
                    </Badge>
                  </IconButton>
                </Box>
              </Badge>
            </IconButton>
          </Box>
          <Link
            sx={{
              alignSelf: 'end',
              width: 'auto',
              color: '#3D61B0',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '15px',
              lineHeight: '18px',
              textAlign: 'right',
              cursor: 'pointer',
              right: '0',
              textDecoration: 'underline',
              display: {
                xs: 'none',
                sm: 'none',
              },
            }}
            onClick={editHandler}
            mt={3}
          >
            Edit
            <EditIcon
              style={{ marginLeft: '6px', position: 'relative', top: '5px' }}
            />
          </Link>
          <Box
            width="100%"
            mt={6}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'Enterprise Name'}
              value={enterpriseInfo.name}
              name="name"
              field="name"
              onEdit={editHandler}
              required={true}
              editable={isLineEditable.name}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'Token Name'}
              value={enterpriseInfo.tokenName}
              field="token"
              name="token"
              onEdit={editHandler}
              required={true}
              //editable={isLineEditable.token}
              //handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'Enterprise Description'}
              value={enterpriseInfo.description}
              name="description"
              field="description"
              onEdit={editHandler}
              required={true}
              editable={isLineEditable.description}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            {!isLineEditable.isRegisterd ? (
              <>
                <Box>
                  <Typography variant="body2">
                    Is this an existing enterprise registered with a government?
                  </Typography>
                  <Typography
                    variant="h3"
                    mt={2}
                    sx={{
                      fontWeight: 500,
                      minHeight: '20px',
                      maxWidth: '100%',
                      wordBreak: 'break-all',
                    }}
                  >
                    {enterpriseInfo.isRegisterd ? 'Yes' : 'No'}
                  </Typography>
                </Box>
                <Box>
                  <Link
                    onClick={() => {
                      editHandler('isRegisterd');
                    }}
                    sx={{ display: { xs: 'flex', sm: 'flex' } }}
                  >
                    {isAdmin && (
                      <EditIcon
                        style={{
                          marginLeft: '6px',
                          position: 'relative',
                          top: '5px',
                          color: '#6F7287',
                        }}
                      />
                    )}
                  </Link>
                </Box>
              </>
            ) : (
              <Box width="100%" mt={0}>
                <InputLabel shrink htmlFor="isRegisterd">
                  Is this an existing enterprise registered with a government?
                </InputLabel>
                <Select
                  id="isRegisterd"
                  value={enterpriseInfo.isRegisterd ? 'Yes' : 'No'}
                  fullWidth
                  sx={{ mt: 1 }}
                  itemType="select"
                  onChange={handleSelectChange('isRegisterd')}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </Box>
            )}
          </Box>
          {enterpriseInfo.isRegisterd && (
            <Box
              width="100%"
              mt={3}
              display="flex"
              justifyContent="space-between"
            >
              {!isLineEditable.companyType ? (
                <>
                  <Box>
                    <Typography variant="body2">Type of Company</Typography>
                    <Typography
                      variant="h3"
                      mt={2}
                      sx={{
                        fontWeight: 500,
                        minHeight: '20px',
                        maxWidth: '100%',
                        wordBreak: 'break-all',
                      }}
                    >
                      {enterpriseInfo.type}
                    </Typography>
                  </Box>
                  <Box>
                    <Link
                      onClick={() => {
                        editHandler('companyType');
                      }}
                      sx={{ display: { xs: 'flex', sm: 'flex' } }}
                    >
                      {isAdmin && (
                        <EditIcon
                          style={{
                            marginLeft: '6px',
                            position: 'relative',
                            top: '5px',
                            color: '#6F7287',
                          }}
                        />
                      )}
                    </Link>
                  </Box>
                </>
              ) : (
                <Box width="100%" mt={0}>
                  <InputLabel shrink htmlFor="type">
                    Type of Company
                  </InputLabel>
                  <Select
                    id="type"
                    value={enterpriseInfo.type}
                    fullWidth
                    sx={{ mt: 1 }}
                    onChange={handleSelectChange('type')}
                  >
                    <MenuItem value="LLC">LLC</MenuItem>
                    <MenuItem value="C-Corp">C-Corp</MenuItem>
                    <MenuItem value="S-Corp">S-Corp</MenuItem>
                    <MenuItem value="Non-Profit">Non-Profit</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </Box>
              )}
            </Box>
          )}
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'Website'}
              value={enterpriseInfo.website}
              field="website"
              onEdit={editHandler}
              editable={isLineEditable.website}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              name="address1"
              type="address"
              label={'Address Line 1'}
              value={address}
              field="address1"
              onEdit={editHandler}
              editable={isLineEditable.address1}
              handleChange={handleAddressChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              name="address2"
              label={'Address Line 2'}
              value={enterpriseInfo.address2}
              field="address2"
              onEdit={editHandler}
              editable={isLineEditable.address2}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              name="country"
              label={'Country'}
              value={enterpriseInfo.country}
              field="country"
              onEdit={editHandler}
              editable={isLineEditable.country}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              name="zip"
              label={'ZIP'}
              value={enterpriseInfo.zip}
              field="zip"
              onEdit={editHandler}
              editable={isLineEditable.zip}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              name="state"
              label={'State'}
              value={enterpriseInfo.state}
              field="state"
              onEdit={editHandler}
              editable={isLineEditable.state}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              name="city"
              label={'City'}
              value={enterpriseInfo.city}
              field="city"
              onEdit={editHandler}
              editable={isLineEditable.city}
              handleChange={handleInputChange}
            />
          </Box>
          <WELoadingButton
            type="primary"
            loading={loading}
            rounded="xl"
            loadingPosition="center"
            loadingIndicator={<CircularProgress color="default" size={20} />}
            onClick={() => {
              continueHandler(enterpriseInfo);
            }}
            sx={{
              width: { sm: '25%', xs: '80%' },
            }}
          >
            {isSetting ? 'Update' : 'Continue'}
          </WELoadingButton>
        </Grid>
      </Box>
      <PhotoEditorModal
        open={editorOpen}
        onClose={editorClose}
        image={image}
        onOK={editorOK}
      />
      <ImageEditorModal
        open={imageEditorOpen}
        onClose={editorClose}
        image={backgroundImage}
        onOK={imageEditorOK}
      />
    </>
  );
};

DetailCreate.propTypes = {
  isSetting: PropTypes.bool,
};

DetailCreate.defaultProps = {
  isSetting: false,
};

export default DetailCreate;
