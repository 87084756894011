import { DialogContent, DialogTitle, Divider } from '@mui/material';
import React from 'react';
import { upsertComment } from '../../apis';
import store from '../../constants/store';
import { STORE_KEYS } from '../../constants/store/constant';
import { useAutoConnect } from '../../utils/AutoConnect';
import { ModalHead } from '../AppBar';
import { CommentList } from '../Comments';
import CommentInput from './CommentInput';
import { CommentDialog } from './DetailDialog';
import Transition from './Transition';
import PropTypes from 'prop-types';

const CommentModal = ({ open, onClose, id, idx, comments }) => {
  const [, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);
  const { accountInfo } = useAutoConnect();
  const [comment, setComment] = React.useState({
    username: accountInfo?.username,
    comment: '',
    created_at: Date.now() / 10 ** 3,
  });

  const handleInputChange = (type) => (evt) => {
    const value = evt.currentTarget.value;
    switch (type) {
      case 'comment':
        setComment((prev) => {
          return {
            ...prev,
            comment: value,
          };
        });
        break;
      default:
        break;
    }
  };

  const continueHandler = async () => {
    try {
      let res = await upsertComment(id, comment);
      if (res.data.success) {
        updateEnterprises((prev) => {
          if (prev.tempEnterprise.proposals[idx].comments) {
            prev.tempEnterprise.proposals[idx].comments = [
              comment,
              ...prev.tempEnterprise.proposals[idx].comments,
            ];
          } else {
            prev.tempEnterprise.proposals[idx].comments = [comment];
          }

          return prev;
        });
      }
    } catch {
      console.log('Failed to Update or Add business update'); //eslint-disable-line no-console
    }

    setComment((prev) => {
      return {
        ...prev,
        comment: '',
      };
    });
  };

  return (
    <CommentDialog
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        <ModalHead
          title={'Comments'}
          customClose={() => onClose()}
          color="#FFDB0B"
        />
      </DialogTitle>
      <DialogContent sx={{}}>
        <Divider key={`divider1`} />
        <CommentList comments={comments} />
        <Divider key={`divider2`} />
        <CommentInput
          label="Comment"
          inputMode="text"
          value={comment.comment}
          textAlign="left"
          multiline={true}
          lineNum={2}
          onChange={handleInputChange('comment')}
          onClick={continueHandler}
        />
      </DialogContent>
    </CommentDialog>
  );
};

CommentModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.any,
  idx: PropTypes.any,
  comments: PropTypes.array,
};

CommentModal.defaultProps = {
  open: null,
  onClose: null,
  id: null,
  idx: null,
  comments: [],
};

export default CommentModal;
