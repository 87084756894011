import { ToggleButtonGroup, ToggleButton, styled } from '@mui/material';

export const fontForSize = (size) => {
  switch (size) {
    case 'small':
      return '12px';
    case 'medium':
      return '16px';
    case 'large':
      return '20px';
  }
};

export const WEToggleButtonGroup = styled(ToggleButtonGroup)(({ size }) => ({
  borderRadius: '6px',
  border: '1px solid #E3E8EB',
  background: '#DDDDDD',
  fontSize: fontForSize(size),
  cursor: 'pointer',
}));

export const WEToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderRadius: '6px',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '500',
  textAlign: 'center',
  textTransform: 'unset',
  padding: '8px',
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },
  '&.Mui-selected:hover': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}));
