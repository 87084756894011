/**
 * @param price total price
 * @param amount token amount
 **/
export const calculateTokenPrice = (price, amount, decimal = 2) => {
  amount = typeof amount === 'number' ? amount : parseFloat(amount);
  amount = isNaN(amount) ? 0 : amount;

  price = typeof price === 'number' ? price : parseFloat(price);
  price = isNaN(price) ? 0 : parseFloat(price);

  decimal = typeof decimal === 'number' ? decimal : parseInt(decimal);
  decimal = isNaN(decimal) ? 2 : decimal;

  if (amount === 0) {
    return price;
  }

  return (
    Math.floor((price * Math.pow(10, decimal)) / amount) / Math.pow(10, decimal)
  );
};
