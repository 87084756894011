import { Button, styled } from '@mui/material';

const BtnOK = styled(Button)(({ disable }) => ({
  borderRadius: '10px',
  height: '53px',
  backgroundColor: '#42B03D',
  textAlign: 'center',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '17px',
  lineHeight: '22px',
  color: '#ffffff',
  margin: 'auto',
  ':hover': {
    backgroundColor: '#4bcb46',
  },
  opacity: disable === 'true' ? '0.5' : '1',
}));

export default BtnOK;
