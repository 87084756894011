import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

const ModalHead = ({
  title,
  color,
  helpIcon,
  theme = 'light',
  customClose,
}) => {
  const navigation = useNavigate();
  const navigator = useNavigate();
  //handle function for close icon.
  const handleClose = () => {
    if (customClose) {
      customClose();
    } else {
      switch (window.location.pathname) {
        case '/wepID/send':
          navigation('/wepID/dashboard', { state: { pTab: 'admins' } });
          break;
        case '/wepID/trades':
          navigation('/wepID/dashboard', { state: { pTab: 'shareholders' } });
          break;
        default:
          navigator(-1);
      }
    }
  };
  return (
    <Box sx={{ position: 'relative', minHeight: '104px' }}>
      <Box
        sx={{
          bgcolor: color,
          position: 'absolute',
          width: '100%',
          zIndex: 10,
          height: '104px',
        }}
      >
        <Box sx={{ position: 'relative', height: '104px' }}>
          <Box
            component="img"
            src={'/images/ellipse_white.svg'}
            alt="bg"
            sx={{ position: 'absolute', top: 0, left: '20%' }}
          />
          <Box
            component="img"
            src={'/images/group1.svg'}
            alt="bg"
            sx={{ position: 'absolute', bottom: 0, left: 0 }}
          />
          <Box
            component="img"
            src={'/images/group2.svg'}
            alt="bg"
            sx={{ position: 'absolute', bottom: 0, right: 0 }}
          />
          <Box
            component="img"
            src={'/images/group3.svg'}
            alt="bg"
            sx={{ position: 'absolute', top: 0, right: '20%' }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '104px',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 0,
          zIndex: 12,
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0 }}
        >
          <CloseIcon sx={{ color: theme === 'dark' ? '#ffffff' : '#28282B' }} />
        </IconButton>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            color: theme === 'dark' ? '#ffffff' : '#28282B',
          }}
        >
          {title}
        </Typography>
        {helpIcon && <HelpIcon sx={{ marginLeft: '5px' }} />}
      </Box>
    </Box>
  );
};

ModalHead.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  helpIcon: PropTypes.any,
  theme: PropTypes.string,
  customClose: PropTypes.func,
};

ModalHead.defaultProps = {
  title: null,
  color: null,
  helpIcon: null,
  theme: 'light',
  customClose: null,
};
export default ModalHead;
