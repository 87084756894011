import React from 'react';
import { ThumbDownAltOutlined, ThumbUpAltOutlined } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';

const InfoVote = ({ type, num }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        padding: '10px 20px',
        marginRight: type === 'yes' ? '7px' : '0px',
        border: '1px solid',
        borderColor: type === 'yes' ? '#5CDD5C4D' : '#FF61424D',
        borderRadius: '86px',
        width: '100%',
      }}
    >
      <Avatar
        style={{
          width: '42px',
          height: '42px',
          borderWidth: '0px',
          marginRight: '10px',
          backgroundColor: type === 'yes' ? '#42B03D1E' : '#FF2A001E',
        }}
      >
        {type === 'yes' ? (
          <ThumbUpAltOutlined htmlColor="#42B03D" />
        ) : (
          <ThumbDownAltOutlined htmlColor="#FF2A00" />
        )}
      </Avatar>
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          width: '100%',
          fontFamily: 'Montserrat',
          textAlign: 'left',
        }}
      >
        <span
          style={{
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '15px',
            paddingTop: '2px',
          }}
        >
          {type === 'yes' ? 'Yes' : 'No'} Vote
        </span>
        <span
          style={{
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '20px',
            paddingTop: '3px',
          }}
        >
          {num}
        </span>
      </div>
    </div>
  );
};

InfoVote.propTypes = {
  type: PropTypes.string,
  num: PropTypes.number,
};

export default InfoVote;
