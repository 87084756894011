import { Dialog, styled } from '@mui/material';

const DetailDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: '#ffffff',
    boxShadow: 'none',
    position: 'absolute',
    bottom: 0,
    margin: 0,
    textAlign: 'center',
    width: 'calc(100% - 32px)',
    maxWidth: '900px',
    color: '#241F21',
    overflow: 'hidden !important',
  },

  '& .MuiDialogTitle-root': {
    padding: '0',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px 16px 0px 0px',
  },

  '& .MuiDialogTitle-root span': {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    textAlign: 'center',
    color: '#000000',
  },

  '& .MuiDialogContent-root': {
    backgroundColor: '#FFFFFF',
    padding: '45px 0px 45px 0px',
    paddingLeft: '25px',
    paddingRight: '25px',
  },

  '& .MuiDialogContent-root > div': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    color: theme.palette.text.primary,
    width: '100%',
  },

  '& .MuiDialogContent-root > div span': {
    display: 'block',
  },

  '& .MuiDialogContent-root div .infoInput': {
    display: 'block!important',
    width: 'fit-content',
    border: '1px solid #E3E8EB!important',
    borderRadius: '8px!important',
    paddingRight: '8px!important',
    backgroundColor: '#ffffff!important',
  },

  '& .MuiDialogContent-root div textarea': {
    'box-sizing': 'border-box',
  },

  '& .MuiDialogContent-root div > input': {
    display: 'block',
    textAlign: 'left',
    border: '1px solid #FFFFFFFF',
    ':focusVisible': {
      border: '1px solid #FFFFFFFF',
    },
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    color: '#000000',
    outline: 'none',
  },

  '& .MuiDialogContent-root div > textarea': {
    display: 'block',
    width: 'fit-content',
    textAlign: 'right',
    border: '1px solid #FFFFFFFF',
    ':focusVisible': {
      border: '1px solid #FFFFFFFF',
    },
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    color: '#000000',
    outline: 'none',
  },

  '& .MuiDialogActions-root': {
    backgroundColor: '#FFFFFF',
    padding: '16px 0px 28px 0px',
  },

  '& .MuiDialogActions-root button': {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
  },
}));

export default DetailDialog;

//Customized DatailDialog which is located in top.
export const DetailDialogTop = styled(DetailDialog)(() => ({
  '& .MuiDialog-paper': {
    background: '#ffffff',
    boxShadow: 'none',
    position: 'absolute',
    top: '65px',
    bottom: 'auto',
    margin: 0,
    mx: 'auto',
    padding: '0 !important',
    textAlign: 'center',
    width: 'calc(100% - 32px)',
    maxWidth: '750px',
    borderRadius: '0',
    color: '#241F21',
    // width: "100%",
  },
}));

export const WEModal = styled(DetailDialog)(() => ({
  '& .MuiDialog-paper': {
    background: '#ffffff',
    boxShadow: 'none',
    position: 'absolute',
    bottom: '0',
    margin: 0,
    mx: 'auto',
    padding: '0 !important',
    textAlign: 'center',
    width: 'calc(100% - 32px)',
    maxWidth: '750px',
    borderRadius: '0',
    color: '#241F21',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
}));

export const CommentDialog = styled(WEModal)(() => ({
  '& .MuiDialog-paper': {
    position: 'relative',
    borderRadius: '16px 16px',
  },
}));
