import React from 'react';
import { Link, Chip } from '@mui/material';
import { Input } from '../Input';
import PropTypes from 'prop-types';

const Info = ({
  label,
  value,
  type,
  multiline = false,
  textAlign = 'right',
  labelColor,
  onClick,
  maxWidth,
  wordBreak = 'break-all',
  editable = false,
  editHandler,
}) => {
  const _editHandler = (e) => {
    if (typeof editHandler === 'function') {
      editHandler(e);
    }
  };

  return (
    <div
      style={{
        display: multiline ? 'block' : 'flex',
        alignItems: multiline ? 'start' : 'center',
      }}
    >
      <span
        style={{
          textAlign: 'left',
          padding: '4px 10px 4px 0',
          paddingRight: '10px',
          color: labelColor == 'grayLabel' ? '#A5A5A5' : 'black',
          marginBottom: multiline ? '10px' : '0px',
        }}
      >
        {label}
      </span>
      {(() => {
        switch (type) {
          case 'date':
            return (
              <div style={{ textAlign: 'left', color: 'black' }}>{value}</div>
            );
          case 'email':
            return (
              <span style={{ textAlign: textAlign }}>
                <Link
                  href={`mailto:${value}`}
                  sx={{
                    color: '#241F21',
                    textDecoration: 'none',
                    ':visited': {
                      color: '#241F21',
                    },
                  }}
                >
                  {value}
                </Link>
              </span>
            );
          case 'phone':
            return (
              <span style={{ textAlign: textAlign }}>
                <Link
                  href={`tel:${value}`}
                  /* target="_blank" */ sx={{
                    color: '#241F21',
                    textDecoration: 'none',
                    ':visited': {
                      color: '#241F21',
                    },
                  }}
                >
                  {value}
                </Link>
              </span>
            );
          case 'website':
            return (
              <span
                style={{
                  textAlign: textAlign === 'right' ? 'right' : 'left',
                }}
              >
                <Link
                  href={value}
                  target="_blank"
                  sx={{
                    textDecoration: 'none',
                    ':visited': {
                      color: '#241F21',
                    },
                  }}
                >
                  {value}
                </Link>
              </span>
            );
          case 'proposal_type':
            return <Chip label={value} />;
          default:
            return editable ? (
              <Input
                value={value}
                fullWidth
                sx={{ mt: 1 }}
                onChange={_editHandler}
              />
            ) : (
              <span
                style={{
                  display: maxWidth ? 'block' : 'flex',
                  textAlign: textAlign,
                  fontSize: multiline ? '12px' : '16px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  padding: '4px 0',
                  wordBreak: wordBreak,
                  maxWidth: maxWidth ? maxWidth : 'unset',
                  overflow: maxWidth ? 'hidden' : 'unset',
                  whiteSpace: maxWidth ? 'nowrap' : 'primary',
                  textOverflow: maxWidth ? 'ellipsis' : 'unset',
                }}
                onClick={onClick}
              >
                {value}
              </span>
            );
        }
      })()}
    </div>
  );
};

Info.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.any,
  multiline: PropTypes.bool,
  textAlign: PropTypes.string,
  labelColor: PropTypes.string,
  onClick: PropTypes.func,
  maxWidth: PropTypes.any,
  wordBreak: PropTypes.string,
};

export default Info;
