import axios from 'axios';

axios.defaults.baseURL = `${
  process.env.REACT_APP_API_URL ||
  'https://jgx11ie2s1.execute-api.us-east-1.amazonaws.com'
}`;
axios.defaults.headers = {
  'Content-Type': 'application/json',
};

export const getUserAccount = (address) => {
  return axios.get(`/user/${address}`).then((response) => {
    if (response.data?.error)
      // eslint-disable-next-line
      throw response.data?.msg;
    return response;
  });
};

export const upsertUserAccount = ({
  address,
  username,
  name,
  image_url,
  job_title,
  join_splash,
}) => {
  return axios
    .post(`/user/${address}`, {
      username,
      name,
      image_url,
      job_title,
      join_splash,
    })
    .then((response) => {
      if (response.data?.error)
        // eslint-disable-next-line
        throw response.data?.msg;
      return response;
    });
};

export const getEnterprises = () => {
  return axios.get(`/enterprises`).then((response) => {
    if (response.data?.error)
      // eslint-disable-next-line
      throw response.data?.msg;
    return response;
  });
};

export const getProposalsForUser = (wallet_address) => {
  return axios
    .get(`/proposals/u/${wallet_address.toUpperCase()}`)
    .then((response) => {
      if (response.data?.error)
        // eslint-disable-next-line
        throw response.data?.msg;
      return response;
    });
};

export const getProposalsForEnterprise = (enterprise_address) => {
  return axios
    .get(`/proposals/e/${enterprise_address.toUpperCase()}`)
    .then((response) => {
      if (response.data?.error)
        // eslint-disable-next-line
        throw response.data?.msg;
      return response;
    });
};

export const upsertBusinessUpdate = ({
  id,
  title,
  content,
  enterprise_address,
  creator,
}) => {
  return axios
    .post(`/business-update/${id}`, {
      title,
      content,
      enterprise_address,
      creator,
    })
    .then((response) => {
      if (response.data?.error)
        // eslint-disable-next-line
        throw response.data?.msg;
      return response;
    });
};

export const upsertForum = ({
  id,
  title,
  content,
  enterprise_address,
  creator,
  logo,
  username,
  comments,
}) => {
  return axios
    .post(`/forum/${id}`, {
      title,
      content,
      enterprise_address,
      creator,
      logo,
      username,
      comments,
    })
    .then((response) => {
      if (response.data?.error)
        // eslint-disable-next-line
        throw response.data?.msg;
      return response;
    });
};

export const upsertEnterprise = (
  enterprise_address,
  metadata,
  token_name,
  token_symbol
) => {
  return axios
    .post(`/enterprise/${enterprise_address}`, {
      metadata,
      token_name,
      token_symbol,
    })
    .then((response) => {
      if (response.data?.error)
        // eslint-disable-next-line
        throw response.data?.msg;
      return response;
    });
};

export const getEnterpriseItem = (enterprise_address) => {
  return axios.get(`/enterprise/${enterprise_address}`).then((response) => {
    if (response.data?.error)
      // eslint-disable-next-line
      throw response.data?.msg;
    return response;
  });
};

export const getBusinessUpdatesForEnterprise = (enterprise_address) => {
  return axios
    .get(`/business-updates/${enterprise_address}`)
    .then((response) => {
      if (response.data?.error)
        // eslint-disable-next-line
        throw response.data?.msg;
      return response;
    });
};

export const getForumsForEnterprise = (enterprise_address) => {
  return axios.get(`/forums/${enterprise_address}`).then((response) => {
    if (response.data?.error)
      // eslint-disable-next-line
      throw response.data?.msg;
    return response;
  });
};

export const getOrdersForEnterprise = (enterprise_address) => {
  return axios.get(`/orders/${enterprise_address}`).then((response) => {
    if (response.data?.error)
      // eslint-disable-next-line
      throw response.data?.msg;
    return response;
  });
};

export const getTransactionsForEnterprise = (enterprise_address) => {
  return axios.get(`/transactions/${enterprise_address}`).then((response) => {
    if (response.data?.error)
      // eslint-disable-next-line
      throw response.data?.msg;
    return response;
  });
};

export const deleteBusinessUpdate = (id) => {
  return axios.delete(`/business-update/${id}`).then((response) => {
    if (response.data?.error)
      // eslint-disable-next-line
      throw response.data?.msg;
    return response;
  });
};

export const upsertComment = (id, { username, comment }) => {
  return axios
    .post(`/proposals/c/${id}`, { username, comment })
    .then((response) => {
      if (response.data?.error)
        // eslint-disable-next-line
        throw response.data?.msg;
      return response;
    });
};

export const getCommentsForProposal = (id) => {
  return axios.get(`/proposals/c/${id}`).then((response) => {
    if (response.data?.error)
      // eslint-disable-next-line
      throw response.data?.msg;
    return response;
  });
};

export const getDraftsForUser = (wallet_address) => {
  return axios.get(`/drafts/${wallet_address}`).then((response) => {
    if (response.data?.error)
      // eslint-disable-next-line
      throw response.data?.msg;
    return response;
  });
};

export const upsertDraft = (
  id,
  { admin_address, status, metadata, shareholders, admins }
) => {
  return axios
    .post(`/draft/${id}`, {
      admin_address,
      status,
      metadata,
      shareholders,
      admins,
    })
    .then((response) => {
      if (response.data?.error)
        // eslint-disable-next-line
        throw response.data?.msg;
      return response;
    });
};

export const getTokenPrice = (token = 'matic-network') => {
  return axios
    .get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${token}&vs_currencies=usd`
    )
    .then((response) => {
      if (response.data) {
        return response.data[token]['usd'];
      }
      return;
    });
};

export const getCoinPrice = (token = 'MATIC') => {
  return axios
    .get(`https://api.coinbase.com/v2/exchange-rates`)
    .then((response) => {
      if (response.data) {
        return Number(response.data.data.rates[token]);
      }
      return;
    });
};

export const sendMails = async (data) => {
  axios.defaults.baseURL = `https://isvs5jszyopdln5opnab2o3qa40sgqtj.lambda-url.us-east-1.on.aws`;
  axios.defaults.headers = {
    'Content-Type': 'application/json',
  };
  return await axios
    .post('/sendWEmails', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
