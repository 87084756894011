export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function getLowerCaseAddress(addr) {
  if (typeof addr === 'string') {
    return addr.toLocaleLowerCase();
  }
  return '';
}
