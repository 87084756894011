import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeAppBar, ModalHead } from '../../components/AppBar';
import { ForumEdit, ForumView } from '../../components/Forum';
import Header from '../../components/Header';
import ScrollToTop from '../../components/ScrollToTop';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';

const Forum = () => {
  const location = useLocation();
  const state = location.state;
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  // const [updateableForumId] = useState(
  //   enterprises.tempEnterprise.updateableForumId
  // );
  const navigation = useNavigate();

  useEffect(
    () => {
      setStep(STEP.DASHBOARD_FORUM);
      // if (
      //   updateableForumId === null ||
      //   updateableForumId === undefined ||
      //   updateableForumId === -1
      // ) {
      //   setStep(STEP.DASHBOARD_FORUM);
      // } else {
      //   setStep(STEP.DASHBOARD_FORUM_EDIT);
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClose = () => {
    switch (stepState) {
      case STEP.DASHBOARD_FORUM:
        if (state.pTab) {
          navigation('/wepID/dashboard', { state: { pTab: state.pTab } });
        } else {
          navigation(-1);
        }
        break;
      case STEP.DASHBOARD_FORUM_ADD:
        setStep(STEP.DASHBOARD_FORUM);
        break;
      case STEP.DASHBOARD_FORUM_EDIT:
        setStep(STEP.DASHBOARD_FORUM);
        break;
      default:
        navigation(-1);
        break;
    }
  };
  return (
    <>
      <ScrollToTop />
      <Header pageTitle="Forum" />
      <HomeAppBar />
      <ModalHead
        color="#FFDB0B"
        title={enterprises.tempEnterprise.info.name}
        customClose={() => handleClose()}
      />
      {stepState === STEP.DASHBOARD_FORUM && <ForumView />}
      {(stepState === STEP.DASHBOARD_FORUM_ADD ||
        stepState === STEP.DASHBOARD_FORUM_EDIT) && <ForumEdit />}
    </>
  );
};

export default Forum;
