import React from 'react';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { PropagateLoader } from 'react-spinners';

const Confirm = () => {
  return (
    <Container
      sx={{
        padding: '20px',
      }}
    >
      <Box
        component="main"
        sx={{
          width: '100%',
          position: 'fixed',
          direction: 'row',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Container
          sx={{
            width: '100%',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '125px',
              height: '125px',
              justifyContent: 'center',
              alignItems: 'center',
              mx: 'auto',
              border: '2px solid #04C9F2',
              borderRadius: '50%',
            }}
          >
            <Box
              component="img"
              src="/images/Briefcase.svg"
              sx={{
                display: 'block',
                width: '68px',
              }}
            />
          </Box>
        </Container>
        <Container
          sx={{
            mt: 3,
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <PropagateLoader
            color="#FFDB0A"
            loading
            style={{
              display: 'block',
              height: '10px',
              width: '14px',
              margin: 'auto',
              paddingLeft: '-20px',
            }}
          />
        </Container>
        <Container
          sx={{
            mt: 3,
            alignContent: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            Creating <i>World Enterprise</i> on Blockchain
          </Typography>
        </Container>
      </Box>
    </Container>
  );
};

export default Confirm;
