import { RocketLaunchOutlined } from '@mui/icons-material';
import { Avatar, Badge, Box, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { mobileCheck } from '../../utils/mobileCheck';
import { getEtherFromWei } from '../../utils/unit';
import { SharePriceTooltip } from '../Tooltips';
import ListItem from './ListItem';

const ShareItem = ({
  view,
  share,
  activity,
  proposal,
  origLocation,
  viewOnly,
  enterprise,
}) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const navigation = useNavigate();

  console.log('==========>', enterprise);
  useEffect(() => {
    setImageLoaded(true);
  }, []);

  const viewHandle = (wepID) => async () => {
    if (viewOnly) {
      return;
    }
    if (activity != null) {
      await view(activity.walletAddr);
      return navigation(`/wepID/dashboard`, {
        state: { wepID: activity.walletAddr, weBack: origLocation },
      });
    }
    await view(wepID);
    navigation(`/wepID/dashboard`, {
      state: { wepID, weBack: origLocation },
    });
  };

  return (
    <ListItem
      onClick={viewHandle(
        share != null ? share.walletAddr : proposal?.walletAddr
      )}
      sx={{ width: '100%' }}
    >
      <Badge
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        badgeContent={
          <>
            {share.mine && (
              <Box
                sx={{
                  bgcolor: '#FFDB0A',
                  borderRadius: '50%',
                  width: 26,
                  height: 26,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <RocketLaunchOutlined
                  sx={{
                    width: 20,
                    height: 20,
                  }}
                />
              </Box>
            )}
          </>
        }
        overlap="circular"
        sx={{
          borderRadius: 10,
          '.MuiBadge-badge': {
            top: 'auto!important',
          },
        }}
      >
        <Avatar
          sx={{
            display: !imageLoaded ? 'none' : 'inline',
            width: '72px',
            height: '72px',
          }}
        >
          {share.img !== '' && share.img !== undefined && imageLoaded && (
            <Box
              component="img"
              src={`${share.img}`}
              alt=""
              sx={{
                width: '100%',
                mr: imageLoaded ? '12px' : '0px',
              }}
            />
          )}
        </Avatar>
        {!imageLoaded && (
          <Skeleton
            variant="square"
            width={72}
            height={72}
            sx={{
              width: mobileCheck() ? '72px' : '80px',
              mr: '12px',
            }}
          />
        )}
      </Badge>
      <Box sx={{ width: 'calc(100% - 100px)' }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '20px',
            color: '#193065',
            mb: '6px',
          }}
        >
          {share.name}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: mobileCheck() ? '14px' : '18px',
                fontWeight: '500',
                lineHeight: '17px',
                color: '#A5A5A5',
                mb: mobileCheck() ? '6px' : '12px',
              }}
            >
              My Shares
            </Typography>
            <Typography
              sx={{
                fontSize: mobileCheck() ? '16px' : '20px',
                lineHeight: '20px',
                fontWeight: '600',
                color: '#5CDD5C',
              }}
            >
              {getEtherFromWei(share.amount)}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: mobileCheck() ? '14px' : '18px',
                fontWeight: '500',
                lineHeight: '17px',
                color: '#A5A5A5',
                mb: mobileCheck() ? '6px' : '12px',
              }}
            >
              Price Per Share
            </Typography>
            <SharePriceTooltip>
              <Typography
                sx={{
                  fontSize: mobileCheck() ? '16px' : '20px',
                  lineHeight: '20px',
                  fontWeight: '600',
                  color: '#5CDD5C',
                }}
              >
                ${share.price}
              </Typography>
            </SharePriceTooltip>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

ShareItem.propTypes = {
  view: PropTypes.func,
  share: PropTypes.any,
  activity: PropTypes.any,
  proposal: PropTypes.any,
  origLocation: PropTypes.any,
  viewOnly: PropTypes.bool,
  enterprise: PropTypes.any,
};

ShareItem.defaultProps = {
  view: null,
  share: null,
  activity: null,
  proposal: null,
  origLocation: null,
  viewOnly: false,
  enterprise: null,
};

export default ShareItem;
