import { AppBar, styled } from '@mui/material';

export const fontForSize = (size) => {
  switch (size) {
    case 'small':
      return '12px';
    case 'medium':
      return '16px';
    case 'large':
      return '20px';
  }
};

const WEAppBar = styled(AppBar)(({ theme, size }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  fontSize: fontForSize(size),
  fontFamily: 'Montserrat',
  zIndex: '100',
  padding: '16px',
  '& a': {
    color: theme.palette.primary.main,
  },
}));

export default WEAppBar;
