import { ContentCopy } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getLowerCaseAddress } from '../../utils';
import { useAutoConnect } from '../../utils/AutoConnect';
import { getProposalStatusColor } from '../../utils/getProposalStatusColor';
import {
  getProposalContent,
  getProposalTypeName,
} from '../../utils/getProposalTypeName';
import { mobileCheck } from '../../utils/mobileCheck';
import { shortAddress } from '../../utils/shortAddress';
import { getEtherFromWei } from '../../utils/unit';
import { WEButton } from '../Button';
import WECard from '../Card/WECard';
import { DonutChart } from '../Chart';

const dashboardTheme = {
  primary: '#FFDB0A',
  secondary: '#3D61B0',
  black: '#28282B',
  gray: '#6F7287',
  fontSize: { xs: '14px', md: '18px' },
  lineHeight: { xs: '17px', md: '180.5%' },
};

const ProposalItem = ({ proposal, index, openModal, isMie }) => {
  const theme = useTheme();
  console.log('proposal', proposal);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [copied, setCopied] = useState(false);

  const { account } = useAutoConnect();

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);
  useEffect(() => {
    setImageLoaded(true);
  }, []);

  const calcVoteYesPercent = () => {
    const total = proposal.votesNo + proposal.votesYes;
    if (total !== 0 && !isNaN(Number(total)))
      return Math.round((Number(proposal.votesYes) * 100) / Number(total));
    else return 0;
  };

  const getSeries = (no, yes) => {
    if (isNaN(Number(no)) || isNaN(Number(yes))) {
      return [0, 100];
    }
    if (yes + no === 0) {
      return [0, 100];
    } else {
      return [yes, no];
    }
  };

  const getColors = (votes) => {
    if (isNaN(Number(votes?.votesNo)) || isNaN(Number(votes?.votesYes))) {
      return [theme.palette.info.main, theme.palette.info.main];
    } else if (votes.votesNo + votes.votesYes === 0) {
      return [theme.palette.info.main, theme.palette.info.main];
    } else {
      return [theme.palette.success.main, theme.palette.error.main];
    }
  };
  return (
    <Box key={index} sx={{ pb: 2 }}>
      <WECard key={index} bRadius="20px">
        <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
          <Box>
            {imageLoaded && (
              <Box
                component="img"
                src={
                  proposal.logoImg
                    ? proposal.logoImg
                    : 'https://www.gravatar.com/avatar/3934130150b2cc095497714e42244087?s=100&d=identicon&r=PG'
                }
                alt=""
                sx={{
                  width: '68px',
                  height: '68px',
                  borderRadius: '6px',
                  mr: imageLoaded ? '12px' : '0px',
                }}
              />
            )}
            {!imageLoaded && (
              <Skeleton
                variant="square"
                width={68}
                height={68}
                sx={{
                  width: mobileCheck() ? '68px' : '80px',
                  mr: '12px',
                }}
              />
            )}
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '26px',
                color: '#193065',
                fontWeight: '600',
                mb: '8px',
              }}
            >
              {getProposalTypeName(proposal?.type)}
            </Typography>
            <Chip
              label={proposal?.status}
              color={getProposalStatusColor(proposal?.status)}
            />
          </Box>
        </Stack>
        <Box>
          <Typography
            sx={{
              fontSize: '13x',
              lineHeight: '15px',
              fontWeight: '400',
              color: dashboardTheme.gray,
              mb: 1.75,
            }}
          >
            {shortAddress(proposal.walletAddr)}{' '}
            {getProposalContent(proposal?.type)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Tooltip open={copied} title="copied!" arrow>
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '15px',
                      color: '#FF6142',
                    }}
                  >
                    {shortAddress(proposal.enterpriseAddr)}
                    <IconButton
                      size="small"
                      edge="end"
                      color="primary"
                      aria-label="menu"
                      sx={{ p: 0, pl: 1 }}
                      onClick={() => {
                        copy(getLowerCaseAddress(proposal.enterpriseAddr));
                        setCopied(true);
                      }}
                    >
                      <ContentCopy fontSize="small" htmlColor="#FF6142" />
                    </IconButton>
                  </Typography>
                </Tooltip>
                <Divider orientation="vertical" />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{ width: '26px', height: '26px' }}
                    component="img"
                    src="/images/pie-chart-Icon-colored.svg"
                  ></Box>
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '16px',
                      color: '#193065',
                    }}
                  >
                    {getEtherFromWei(proposal.amount)}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: '18px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'flex-start',
                }}
              >
                {isMie ? (
                  <WEButton
                    type="secondary"
                    width={100}
                    onClick={() => openModal(proposal)}
                    rounded="xl"
                  >
                    View
                  </WEButton>
                ) : (
                  <WEButton
                    type="secondary"
                    width={100}
                    onClick={() => openModal(proposal)}
                    rounded="xl"
                  >
                    Vote Now
                  </WEButton>
                )}
                {isMie &&
                  getLowerCaseAddress(proposal?.walletAddr) ===
                    getLowerCaseAddress(account) &&
                  proposal?.status === 'ACTIVE' &&
                  proposal?.votesYes === 0 &&
                  proposal?.votesNo === 0 && (
                    <Box sx={{ marginLeft: 1 }}>
                      <WEButton
                        type="secondary"
                        width={100}
                        onClick={() => openModal(proposal, true)}
                        rounded="xl"
                      >
                        Edit
                      </WEButton>
                    </Box>
                  )}
              </Box>
            </Box>
            {/* yes % graph */}
            <Box
              sx={{ width: 'fit-content', position: 'relative', height: 100 }}
            >
              <Box
                sx={{
                  width: '125px',
                  height: '125px',
                  position: 'absolute',
                  top: 0,
                  left: '-100px',
                }}
              >
                <DonutChart
                  width={125}
                  height={125}
                  chartSeries={getSeries(proposal.votesNo, proposal.votesYes)}
                  colors={getColors(proposal)}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#193065',
                      textAlign: 'center',
                    }}
                  >
                    Yes
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '20px',
                      lineHeight: '27px',
                      color: '#193065',
                      textAlign: 'center',
                    }}
                  >
                    {calcVoteYesPercent()}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </WECard>
    </Box>
  );
};

ProposalItem.propTypes = {
  proposal: PropTypes.any,
  index: PropTypes.string,
  isMie: PropTypes.bool,
  openModal: PropTypes.bool,
};

ProposalItem.defaultProps = {
  proposal: null,
  index: '',
  isMie: true,
  openModal: true,
};

export default ProposalItem;
