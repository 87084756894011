import { List } from '@mui/material';
import React, { useState } from 'react';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import store from '../../constants/store';
import { AdminDetailModal } from '../Dialog';
import Item from './Item';
import { useAutoConnect } from '../../utils/AutoConnect';
import { useNotification } from '../Notification/hook';
import { useEnterprise } from '../../hook/weEnterprise';
import { useWeb3 } from '../../hook/web3';

const AdminsList = () => {
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const { account } = useAutoConnect();
  const { displayError, displaySuccess } = useNotification();
  const admins = enterprises.tempEnterprise.admins;
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [idx, setIdx] = useState(-1);
  const [dlgOpened, setDlgOpened] = useState(false);

  const enterpriseHandler = useEnterprise(enterprises.tempEnterprise.address);
  const web3 = useWeb3();

  const replaceHandler = (idx) => () => {
    updateEnterprises((prev) => {
      prev.toEditAdmin = idx;
      return prev;
    });
    switch (stepState) {
      case STEP.CREATE_ADMINS_VIEW:
        setStep(STEP.CREATE_ADMIN_EDIT);
        break;

      case STEP.DASHBOARD_MEMBERS:
        setStep(STEP.DASHBOARD_MEMBERS_ADMIN_EDIT);
        break;

      default:
        setStep(STEP.ADMIN_EDIT);
        break;
    }
    setDlgOpened(false);
  };

  const removeHandler = (idx) => async () => {
    if (admins.length < 2) {
      return displayError({
        message:
          'You are the only Admin for this Enterprise, You could not resign Admin role.',
        reason: '',
        timeout: 5000,
      });
    }

    try {
      const balanceInfo = await enterpriseHandler.methods
        .balanceOf(account)
        .call();
      const balance = Number(balanceInfo);
      if (!(balance > 0)) {
        return displayError({
          message: 'Only share holder can propose to resign admin',
          reason: '',
          timeout: 5000,
        });
      }
      await enterpriseHandler.methods
        .proposeForAdminRequest(
          1,
          enterprises.tempEnterprise.admins[idx].walletAddr,
          '0x0000000000000000000000000000000000000000'
        )
        .send({
          from: account,
          gasLimit: web3.eth.getBlock('latest').gasLimit,
        });
      displaySuccess({
        message: 'Create a new propose to remove admin',
        timeout: 5000,
      });
    } catch (error) {
      console.log(`Error ${error}`); // eslint-disable-line no-console
      displayError({
        message: `Error ${error.message}`,
        reason: '',
        timeout: 5000,
      });
    }

    // updateEnterprises((prev) => {
    //   prev.tempEnterprise.admins.splice(idx, 1);
    //   return prev;
    // });
    setDlgOpened(false);
  };

  return (
    <>
      <List dense={true} sx={{ width: '100%', pt: 0, px: 0 }}>
        {admins.map((s, idx) => {
          return (
            <Item
              key={idx}
              name={s.fullName || ''}
              walletAddr={s.walletAddr}
              isActive={s.isActive}
              onClick={() => {
                setIdx(idx);
                setDlgOpened(true);
              }}
            />
          );
        })}
      </List>
      <AdminDetailModal
        open={dlgOpened}
        onClose={() => {
          setDlgOpened(false);
        }}
        isAdmin={
          admins.filter(
            (admin) => admin.walletAddr.toUpperCase() === account.toUpperCase()
          ).length > 0
        }
        account={account}
        email={(admins[idx] && admins[idx].email) || ''}
        phone={(admins[idx] && admins[idx].phone) || ''}
        walletAddr={(admins[idx] && admins[idx].walletAddr) || ''}
        replace={replaceHandler(idx)}
        remove={removeHandler(idx)}
      />
    </>
  );
};

export default AdminsList;
