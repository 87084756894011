import React from 'react';
import BtnOK from './BtnOK';
import PropTypes from 'prop-types';

export const InfoInput = ({
  label,
  value,
  textAlign = 'right',
  multiline = false,
  lineNum = 1,
  placeholder,
  onChange,
  inputMode = 'text',
  onClick,
}) => {
  return (
    <div
      style={{
        display: multiline ? 'block' : 'flex',
      }}
    >
      <span
        style={{
          textAlign: 'left',
          width: '100%',
        }}
      >
        {label}
      </span>
      {!multiline ? (
        <input
          className="infoInput"
          value={value}
          onChange={(eve) => {
            onChange(eve);
          }}
          inputMode={inputMode}
          placeholder={placeholder}
          style={{
            textAlign: textAlign === 'right' ? 'right' : 'left',
          }}
        />
      ) : (
        <div style={{ display: 'flex' }}>
          <textarea
            className="commentInput"
            value={value}
            onChange={(eve) => {
              onChange(eve);
            }}
            inputMode={inputMode}
            placeholder={placeholder}
            rows={lineNum}
            style={{
              width: '100%',
              resize: 'none',
              marginRight: '15px',
              textAlign: textAlign === 'right' ? 'right' : 'left',
            }}
          />
          <BtnOK onClick={onClick}>Send</BtnOK>
        </div>
      )}
    </div>
  );
};

InfoInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  textAlign: PropTypes.string,
  multiline: PropTypes.bool,
  lineNum: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  inputMode: PropTypes.string,
  onClick: PropTypes.func,
};

InfoInput.defaultProps = {
  label: null,
  value: null,
  textAlign: 'right',
  multiline: false,
  lineNum: 1,
  placeholder: null,
  onChange: null,
  inputMode: 'text',
  onClick: null,
};

export default InfoInput;
