import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeAppBar, ModalHead } from '../../components/AppBar';
import { BsUpdateEdit, BsUpdatesView } from '../../components/BsUpdates';
import Header from '../../components/Header';
import ScrollToTop from '../../components/ScrollToTop';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';

const BusinessUpdates = () => {
  const location = useLocation();
  const state = location.state;
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const [updateableBusinessUpdateId] = useState(
    enterprises.tempEnterprise.updateableBusinessUpdateId
  );
  const navigation = useNavigate();

  useEffect(
    () => {
      if (enterprises.tempEnterprise.admins?.length === 0) {
        setStep(STEP.DASHBOARD_BSUPDATE);
      } else {
        if (
          updateableBusinessUpdateId === null ||
          updateableBusinessUpdateId === undefined ||
          updateableBusinessUpdateId === -1
        ) {
          setStep(STEP.DASHBOARD_BSUPDATE);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClose = () => {
    switch (stepState) {
      case STEP.DASHBOARD_BSUPDATE:
      case STEP.DASHBOARD_BSUPDATE_DIRECT_EDIT:
        if (state.pTab) {
          navigation('/wepID/dashboard', { state: { pTab: state.pTab } });
        } else {
          navigation(-1);
        }
        break;
      case STEP.DASHBOARD_BSUPDATE_ADD:
        setStep(STEP.DASHBOARD_BSUPDATE);
        break;
      case STEP.DASHBOARD_BSUPDATE_EDIT:
        setStep(STEP.DASHBOARD_BSUPDATE);
        break;
      default:
        navigation(-1);
        break;
    }
  };
  return (
    <>
      <ScrollToTop />
      <Header pageTitle="Business Updates" />
      <HomeAppBar />
      <ModalHead
        color="#FFDB0B"
        title={enterprises.tempEnterprise.info.name}
        customClose={() => handleClose()}
      />
      {stepState === STEP.DASHBOARD_BSUPDATE && <BsUpdatesView />}
      {(stepState === STEP.DASHBOARD_BSUPDATE_ADD ||
        stepState === STEP.DASHBOARD_BSUPDATE_EDIT ||
        stepState === STEP.DASHBOARD_BSUPDATE_DIRECT_EDIT) && <BsUpdateEdit />}
    </>
  );
};

export default BusinessUpdates;
