import React from 'react';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { HomeAppBar } from '../components/AppBar';
import FaqItem from '../components/Faq';
import Header from '../components/Header/index';

const landingTheme = {
  primary: '#FFDB0A',
  secondary: '#3D61B0',
  black: '#0D141E',
  fontSize: { xs: '14px', md: '18px' },
  lineHeight: { xs: '17px', md: '180.5%' },
};

const Faq = () => {
  return (
    <>
      <Header pageTitle={'FAQ'} />
      <HomeAppBar position="absolute" />
      <Box
        sx={{
          bgcolor: landingTheme.primary,
          width: 1,
          pt: '100px',
          minHeight: '100vh',
        }}
      >
        <Typography variant="h2" mb="25px">
          {'FAQ'}
        </Typography>
        <Stack
          sx={{
            mx: 3,
            mt: 3,
            maxWidth: '800px',
            margin: 'auto',
          }}
        >
          <FaqItem
            title={(() => (
              <>
                What is a <i>World Enterprise</i>?
              </>
            ))()}
          >
            <Typography>
              A <i>World Enterprise</i> is a company that is governed by
              shareholders using a smart contract on the blockchain.
            </Typography>
          </FaqItem>
        </Stack>
      </Box>
    </>
  );
};

export default Faq;
