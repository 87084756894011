import React from 'react';
import EnterpriseABI from '../constants/abi/enterprise.json';
import EnterpriseDevABI from '../constants/abi/enterprise_dev.json';
import { useWeb3 } from './web3';

export function useEnterprise(address) {
  const web3 = useWeb3();
  const [weEnterprise, setWeEnterprise] = React.useState();
  React.useEffect(() => {
    try {
      if (address && web3) {
        setWeEnterprise(
          new web3.eth.Contract(
            process.env.REACT_APP_ENV === 'prod'
              ? EnterpriseABI
              : EnterpriseDevABI,
            address.toLowerCase()
          )
        );
      }
    } catch (e) {
      console.log(e, 'error'); //eslint-disable-line no-console
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3]);
  return weEnterprise;
}
