import store from '../../constants/store';
import { STORE_KEYS } from '../../constants/store/constant';

export const useNotification = () => {
  const [, , updateNotification] = store.useState(STORE_KEYS.id.notification);

  const displayNotification = ({
    type,
    message,
    timeout,
    detail,
    onOk,
    onExit,
  }) => {
    updateNotification((prev) => {
      prev.open = true;
      prev.type = type;
      prev.message = message;
      prev.timeout = timeout;
      prev.detail = detail;
      prev.onOk = onOk;
      prev.onExit = onExit;
      return prev;
    });
  };

  const displaySuccess = ({ message, detail, timeout }) => {
    displayNotification({ type: 'success', message, detail, timeout });
  };

  const displayError = ({ message, detail, timeout }) => {
    displayNotification({ type: 'error', message, detail, timeout });
  };

  const displayWarning = ({ message, detail, timeout }) => {
    displayNotification({ type: 'warning', message, detail, timeout });
  };

  const displayConfirm = ({ message, timeout, onOk, onExit }) => {
    displayNotification({ type: 'confirm', message, timeout, onOk, onExit });
  };

  const clearNotification = () => {
    updateNotification((prev) => {
      prev.open = false;
      return prev;
    });
  };

  return {
    displaySuccess,
    displayError,
    displayWarning,
    displayConfirm,
    displayNotification,
    clearNotification,
  };
};
