import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useResizeDetector } from 'react-resize-detector';
import Web3 from 'web3';
import { getCoinPrice, getProposalsForEnterprise } from '../../apis';
import { useEnterprise } from '../../hook/weEnterprise';
import { useWeb3 } from '../../hook/web3';
import { useAutoConnect } from '../../utils/AutoConnect';
import { uploadFile } from '../../utils/couldfront';
import { convertUSDToMatic } from '../../utils/unit';
import { WELoadingButton } from '../Button';
import { ModalHead } from '../AppBar';
import { useNotification } from '../Notification/hook';
import { WEModal } from './DetailDialog';
import Info from './Info';
import {
  WEToggleButton,
  WEToggleButtonGroup,
} from '../ToggleButton/WEToggleButton';
import PropTypes from 'prop-types';
import InfoInput from './InfoInput';
import Transition from './Transition';

const JoinModal = ({
  enterprise,
  open,
  onClose,
  name,
  offerPrice,
  request,
  enterpriseAddress,
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { ref } = useResizeDetector();
  const [isRequest, setIsRequest] = useState(true);
  const [joinType, setJoinType] = useState('BUY');
  const [toBuy, setToBuy] = useState(0);
  const [price, setPrice] = useState(
    offerPrice === 0 ? '' : offerPrice.toString()
  );
  const [maticValue, setMaticValue] = useState('0');
  const [comment, setComment] = useState('');
  const { displaySuccess, displayWarning, displayError } = useNotification();
  const enterpriseHandler = useEnterprise(enterpriseAddress);
  const { account } = useAutoConnect();
  const web3 = useWeb3();

  const handleChange = (event, value) => {
    if (value === 'BUY') {
      setJoinType(value);
      setIsRequest(true);
    } else if (value === 'REQUEST') {
      setJoinType(value);
      setIsRequest(false);
    }
  };

  const handleInputChange = (type) => (eve) => {
    let value = eve.currentTarget.value.replace('$', '');
    const temp = Number(value);
    const regExp = /[0-9]*([.])?[0-9]?[0-9]?/;
    if (regExp.exec(value)?.[0] === value || value === '') {
      if (/[0][0-9]+[.]?[0-9]*/.exec(value)?.[0] === value)
        value = value.slice(1, value.length);
      if (/[.][0-9]*/.exec(value)?.[0] === value) value = `0${value}`;
      switch (type) {
        case 'toBuy':
          if (!isNaN(Number(temp))) {
            setToBuy(temp);
          }
          break;
        case 'price':
          setPrice(value);
          break;
        default:
          break;
      }
    }
  };

  const handleCommentInput = (eve) => {
    let value = eve.currentTarget.value;
    setComment(value);
  };

  const requestHandler = async () => {
    if (!(toBuy > 0)) {
      return displayWarning({
        message: 'Buy amount should be greater than 0',
        timeout: 5000,
      });
    }

    let tokenPrice = 0;
    try {
      let rate = await getCoinPrice();
      tokenPrice = 1 / rate;
    } catch (e) {
      return displayError({
        message: 'Get token price error, please try again after few minutes',
        timeout: 5000,
      });
    }

    if (!(tokenPrice > 0)) {
      return displayError({
        message: 'Token price error, please check your network status',
        timeout: 5000,
      });
    }

    if (comment === '') {
      return displayError({
        message: 'Please input comment',
        timeout: 5000,
      });
    }
    try {
      const _proposalInfo = await getProposalsForEnterprise(enterprise.address);
      if (_proposalInfo?.data?.data) {
        const _proposals = _proposalInfo.data.data;
        const _idx = _proposals.findIndex((proposal) => {
          return (
            (proposal.proposer || '').toUpperCase() ===
            (account || '').toUpperCase()
          );
        });
        if (_idx >= 0) {
          return displayError({
            message: 'You already sent the join request.',
            timeout: 5000,
          });
        }
      }
    } catch (e) {
      return displayError({
        message: 'Get enterprise info error',
        timeout: 5000,
      });
    }
    setLoading(true);

    try {
      const _totalPrice = price > 0 ? toBuy * Number(price) * tokenPrice : 0;
      const _totalWei = Web3.utils.toWei(_totalPrice.toString());
      const value = _totalWei > 0 ? _totalWei : undefined;
      const _toBuy = Web3.utils.toWei(toBuy.toString());

      let commentUrl = await uploadFile(comment);

      await enterpriseHandler.methods
        .joinWorldEnterprise(_toBuy, _totalWei, commentUrl)
        .send({
          from: account,
          value,
          gasLimit: web3.eth.getBlock('latest').gasLimit,
        });

      request({ comment });
      displaySuccess({
        message:
          'Request submitted. Other members will vote to approve your proposal!',
        timeout: 5000,
      });
      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
      console.error('Request submit error', e); //eslint-disable-line no-console
      return displayError({
        message: 'Request submit error',
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    setPrice(offerPrice === 0 ? '' : offerPrice.toString());
  }, [open, offerPrice]);

  useEffect(() => {
    if (toBuy === '' || price === '') return;
    convertUSDToMatic(toBuy * Number(price)).then(setMaticValue);
  }, [toBuy, price]);
  return (
    <WEModal
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <ModalHead
        title={`Join to ${name}`}
        theme="light"
        color="#FFDB0A"
        customClose={onClose}
      />
      <DialogContent
        ref={ref}
        sx={{
          width: { xs: '90%', sm: '65%' },
          scrollBehavior: 'smooth',
          minHeight: '200px',
          px: '16px !important',
          py: '0 !important',
          color: theme.palette.primary.dark,
          margin: 'auto',
        }}
      >
        <Box>
          <WEToggleButtonGroup
            fullWidth
            value={joinType}
            exclusive
            onChange={handleChange}
            size="medium"
          >
            <WEToggleButton value="BUY">Buy Shares</WEToggleButton>
            <WEToggleButton value="REQUEST">Request Shares</WEToggleButton>
          </WEToggleButtonGroup>
        </Box>
        <Info label="Enterprise Name" value={name} />
        <InfoInput
          label={isRequest ? 'Shares To Buy' : 'Amount of Shares'}
          inputMode="numeric"
          type="string"
          placeholder="0"
          value={toBuy === 0 ? '' : toBuy.toString()}
          onChange={handleInputChange('toBuy')}
        />
        {isRequest && (
          <>
            <InfoInput
              label="Offer Price in USD"
              inputMode="string"
              placeholder="0.00"
              value={price === '' ? '' : `${price}`}
              type="string"
              onChange={handleInputChange('price')}
            />
            <Info
              label="Total"
              value={`$${(toBuy * Number(price)).toFixed(
                2
              )} or ${maticValue} Matic`}
            />
          </>
        )}
        <InfoInput
          label="Comment"
          inputMode="text"
          value={comment}
          textAlign="left"
          multiline={true}
          lineNum={3}
          onChange={handleCommentInput}
        />
      </DialogContent>
      <Box sx={{ px: 2 }}>
        <Divider sx={{ boxShadow: '11px 1px 6px rgba(0, 0, 0, 0.25)' }} />
      </Box>
      {isRequest && (
        <Typography
          sx={{
            pt: 1,
            px: 2,
            maxWidth: '800px',
          }}
        >
          If you request is not approved you will get $
          {(toBuy * Number(price)).toFixed(2)} or {maticValue} Matic back to
          your wallet.
        </Typography>
      )}
      <DialogActions sx={{ px: '16px !important' }}>
        <WELoadingButton
          type="primary"
          loading={loading}
          rounded="xl"
          loadingPosition="center"
          loadingIndicator={<CircularProgress color="default" size={20} />}
          onClick={requestHandler}
          sx={{
            width: { sm: '25%', xs: '80%' },
            margin: 'auto',
          }}
        >
          Send Request
        </WELoadingButton>
      </DialogActions>
    </WEModal>
  );
};

JoinModal.propTypes = {
  enterprise: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  offerPrice: PropTypes.any,
  request: PropTypes.func,
  enterpriseAddress: PropTypes.string,
};

export default JoinModal;
