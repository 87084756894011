import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  Unstable_Grid2 as Grid2,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useWeb3React } from '@web3-react/core';
import PropTypes from 'prop-types';
import { default as React, useEffect, useMemo, useState } from 'react';
import { useNotification } from '../../components/Notification/hook';
import store from '../../constants/store';
import { EmptyVote, STORE_KEYS } from '../../constants/store/constant';
import { useEnterprise } from '../../hook/weEnterprise';
import { useWeb3 } from '../../hook/web3';
import { useAutoConnect } from '../../utils/AutoConnect';
import { getDaysAndHours } from '../../utils/date';
import { getProposalDescription } from '../../utils/getProposalDescription';
import { getProposalTypeName } from '../../utils/getProposalTypeName';
import { WELoadingButton } from '../Button';
import { DonutChart } from '../Chart';

const ProposalCard = ({ proposal, totalMembers }) => {
  const theme = useTheme();
  const web3 = useWeb3();
  const [activity, setActivity] = useState(proposal);
  const { account } = useAutoConnect();
  const { displaySuccess, displayError } = useNotification();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [address, setAddress] = useState(enterprises.tempEnterprise.address);
  const [agreeLoading, setAgreeLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const enterpriseHandler = useEnterprise(address);

  const remainigTime = useMemo(() => {
    if (activity) {
      return getDaysAndHours(activity?.endTime);
    }
  }, [activity]);
  const valuesInPercent = useMemo(() => {
    let total = activity?.votesYes + activity?.votesNo;
    return {
      yes:
        activity?.votesYes === 0
          ? 0
          : Math.round((activity?.votesYes / total) * 100),
      no:
        activity?.votesNo === 0
          ? 0
          : Math.round((activity?.votesNo / total) * 100),
      abstain: 0,
      notVoted: Math.round(
        ((totalMembers - (activity?.votesNo + activity?.votesYes)) /
          totalMembers) *
          100
      ),
    };
  }, [activity]);
  useEffect(() => {
    if (enterprises.tempEnterprise.admins?.length === 0) {
      const getEnterprise = JSON.parse(localStorage.getItem('enterprise'));
      setAddress(getEnterprise.address);
    } else {
      localStorage.setItem(
        'enterprise',
        JSON.stringify(enterprises.tempEnterprise)
      );
    }
    //eslint-disable-next-line
  }, []);
  console.log('address:', address);
  const isChartDataExist = useMemo(() => {
    return (
      !isNaN(valuesInPercent.yes) &&
      !isNaN(valuesInPercent.no) &&
      !isNaN(valuesInPercent.abstain)
    );
  }, [valuesInPercent]);

  const getSeries = (no, yes) => {
    if (isNaN(Number(no)) || isNaN(Number(yes))) {
      return [0, 100];
    }
    if (yes + no === 0) {
      return [0, 100];
    } else {
      return [yes, no];
    }
  };

  const getColors = (no, yes) => {
    if (isNaN(Number(no)) || isNaN(Number(yes))) {
      return [theme.palette.info.main, theme.palette.info.main];
    } else if (no + yes === 0) {
      return [theme.palette.info.main, theme.palette.info.main];
    } else {
      return [theme.palette.success.main, theme.palette.error.main];
    }
  };

  const proposalReject = async () => {
    if (
      activity.type !== 'MINT' &&
      activity.walletAddr.toLowerCase() === account.toLowerCase()
    ) {
      return displayError({
        message: "Proposal owner can't vote for this proposal",
        reason: '',
        timeout: 5000,
      });
    }
    setRejectLoading(true);
    try {
      await enterpriseHandler.methods.vote(parseInt(activity.idx), false).send({
        from: account,
        gasLimit: web3.eth.getBlock('latest').gasLimit,
      });
      const vote = EmptyVote('NO');
      let votes = { ...activity.votes };
      votes[account.toUpperCase()] = vote;
      setActivity({
        ...activity,
        isApproved: true,
        votesNo: activity.votesNo + 1,
        votes: votes,
      });
      await updateEnterprises((prev) => {
        prev.tempEnterprise.proposals.forEach((p, idx) => {
          if (p.id === activity.id) {
            const temp = prev.tempEnterprise.proposals[idx];
            temp.votesNo += 1;
            temp.isApproved = true;
            let tvotes = { ...prev.votes };
            tvotes[account.toUpperCase()] = vote;
            temp.votes = tvotes;
            console.log(temp);
            prev.tempEnterprise.proposals[idx] = temp;
          }
        });
        console.log('temp reject', prev.tempEnterprise);
        localStorage.setItem('enterprise', JSON.stringify(prev.tempEnterprise));
      });
      displaySuccess({ message: 'Proposal rejected', timeout: 5000 });
    } catch (e) {
      displayError({
        message: 'There is an error to reject',
        detail: e.message,
        timeout: 5000,
      });
    }
    setRejectLoading(false);
  };

  const proposalAgree = async () => {
    if (
      activity.type !== 'MINT' &&
      activity.walletAddr.toLowerCase() === account.toLowerCase()
    ) {
      return displayError({
        message: "Proposal owner can't vote for this proposal",
        reason: '',
        timeout: 5000,
      });
    }
    setAgreeLoading(true);
    try {
      await enterpriseHandler.methods.vote(parseInt(activity.idx), true).send({
        from: account,
        gasLimit: web3.eth.getBlock('latest').gasLimit,
      });
      const vote = EmptyVote('YES');
      let votes = { ...activity.votes };
      votes[account.toUpperCase()] = vote;
      setActivity({
        ...activity,
        isApproved: true,
        votesYes: activity.votesYes + 1,
        votes: votes,
      });
      await updateEnterprises((prev) => {
        prev.tempEnterprise.proposals.forEach((p, idx) => {
          if (p.id === activity.id) {
            const temp = prev.tempEnterprise.proposals[idx];
            temp.votesYes += 1;
            temp.isApproved = true;
            prev.votes[account.toUpperCase()] = vote;
            console.log(temp);
            prev.tempEnterprise.proposals[idx] = temp;
          }
        });
        console.log('temp reject', prev.tempEnterprise);
        localStorage.setItem('enterprise', JSON.stringify(prev.tempEnterprise));
      });
      displaySuccess({ message: 'Proposal accepted', timeout: 5000 });
    } catch (e) {
      displayError({
        message: 'There is an error to agree',
        detail: e.message,
        timeout: 5000,
      });
    }
    setAgreeLoading(false);
  };
  return (
    <Grid2 sx={{ borderRadius: '8px', background: '#FFDB0A', padding: '12px' }}>
      <Grid2 display="flex" sx={{ columnGap: '25px' }}>
        <Box display="flex" flexDirection="column" sx={{ rowGap: '6px' }}>
          <Typography
            variant="secondary"
            sx={{ color: '#28282B', opacity: '40%' }}
          >
            Status
          </Typography>
          <Typography
            variant="secondary"
            sx={{ fontWeight: 600, color: '#28282B' }}
          >
            Vote Now!
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" sx={{ rowGap: '6px' }}>
          <Typography
            variant="secondary"
            sx={{ color: '#28282B', opacity: '40%' }}
          >
            Ends
          </Typography>
          <Typography
            variant="secondary"
            sx={{ fontWeight: 600, color: '#28282B' }}
          >
            {remainigTime}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" sx={{ rowGap: '6px' }}>
          <Typography
            variant="secondary"
            sx={{ color: '#28282B', opacity: '40%' }}
          >
            Members Voted
          </Typography>
          <Typography
            variant="secondary"
            sx={{ fontWeight: 600, color: '#28282B' }}
          >
            {proposal?.votesYes + proposal?.votesNo}
          </Typography>
        </Box>
      </Grid2>
      <Grid2
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ py: '12px', px: 0 }}
      >
        {isChartDataExist ? (
          <Grid2 position="relative">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DonutChart
                width={125}
                height={125}
                chartSeries={getSeries(valuesInPercent.no, valuesInPercent.yes)}
                colors={getColors(valuesInPercent.no, valuesInPercent.yes)}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  <Typography variant="secondary" sx={{ color: '#193065' }}>
                    Yes
                  </Typography>
                  <Typography
                    variant="secondary"
                    sx={{
                      fontWeight: 600,
                      fontSize: '18px',
                      lineHeight: '22px',
                      color: '#193065',
                    }}
                  >
                    {valuesInPercent.yes}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid2>
        ) : null}
        <Grid2 display="flex" sx={{ columnGap: '18px', my: '20px', ml: 2 }}>
          <Box display="flex" flexDirection="column" sx={{ rowGap: '6px' }}>
            <Typography
              variant="secondary"
              sx={{
                lineHeight: '15px',
                fontSize: 12,
                fontWeight: 500,
                color: '#5CDD5C',
              }}
            >
              Yes
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#5CDD5C',
              }}
            >
              {valuesInPercent.yes}%
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" sx={{ rowGap: '6px' }}>
            <Typography
              variant="secondary"
              sx={{ lineHeight: '15px', fontSize: 12, color: '#FF0000' }}
            >
              No
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#FF0000',
              }}
            >
              {valuesInPercent.no}%
            </Typography>
          </Box>
        </Grid2>
      </Grid2>
      <Grid2 display="flex" flexDirection="column" sx={{ rowGap: '12px' }}>
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            color: '#28282B',
          }}
        >
          Your Vote:{' '}
          {activity.isApproved && activity.votes[account.toUpperCase()].approve}
        </Typography>
        {activity.isApproved ? (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '20px',
              color: '#18282B',
            }}
          >
            You already voted!
          </Typography>
        ) : (
          <Grid2 display="flex" justifyContent="center">
            <Grid2
              display="flex"
              justifyContent="center"
              sx={{ columnGap: '10px' }}
            >
              <WELoadingButton
                loading={agreeLoading}
                type="success"
                rounded="xl"
                width={80}
                onClick={proposalAgree}
                disabled={agreeLoading || rejectLoading}
              >
                Yes
              </WELoadingButton>
              <WELoadingButton
                loading={rejectLoading}
                type="error"
                rounded="xl"
                width={80}
                onClick={proposalReject}
                disabled={agreeLoading || rejectLoading}
              >
                No
              </WELoadingButton>
            </Grid2>
          </Grid2>
        )}
      </Grid2>
    </Grid2>
  );
};

const ProposalContent = ({ proposals, totalMembers, tokenName, see }) => {
  console.log(proposals);
  const { account } = useWeb3React();

  return (
    <Grid2
      display="flex"
      flexDirection="column"
      sx={{ width: '100%', backgroundColor: 'white', py: 2 }}
    >
      {proposals.map((proposal, index) => (
        <Grid2
          display="flex"
          flexDirection="column"
          sx={{ width: '100%', rowGap: '12px', mb: 3 }}
          key={`proposal_${index}`}
        >
          <Grid2
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', px: 1 }}
          >
            <Typography
              variant="secondary"
              sx={{ fontWeight: 600, color: '#28282B' }}
            >
              {getProposalTypeName(proposal?.type)}
            </Typography>
            <IconButton
              onClick={() => {
                see(proposal?.id);
              }}
            >
              <ChevronRight htmlColor="#000000" />
            </IconButton>
          </Grid2>
          <Typography
            variant="secondary"
            sx={{ fontWeight: 400, color: '#6F7287', wordBreak: 'break-all' }}
            dangerouslySetInnerHTML={{
              __html: getProposalDescription(proposal, account, tokenName),
            }}
          />
          <ProposalCard proposal={proposal} totalMembers={totalMembers} />
        </Grid2>
      ))}
    </Grid2>
  );
};

ProposalCard.propTypes = {
  proposal: PropTypes.any,
  totalMembers: PropTypes.number,
};

ProposalContent.propTypes = {
  proposals: PropTypes.array,
  totalMembers: PropTypes.number,
  enterpriseName: PropTypes.string,
  tokenName: PropTypes.string,
  see: PropTypes.func,
};

export default ProposalContent;
