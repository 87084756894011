export const ERRORS = {
  UNKNOWN: 'Unexpected error.',

  WE_CREATE: 'Failed to create a new enterprise.',
  WE_GET_INFO: 'Get enterprise info error.',

  TOKEN_GET_PRICE: 'Get token price error, please try again after few minutes.',
  TOKEN_WRONG_PRICE: 'Token price error, please check your network status.',

  MULTIPLE_JOIN_REQUEST: 'You already sent the join request.',
  REQUEST_SUBMIT: 'Request submit error.',

  ORDER_INACTIVE: 'This order is not active.',
  ORDER_CREATE: 'Failed to create order.',

  SHARE_NOT_ENOUGH: 'There are not enough shares in your account.',

  NATIVE_TOKEN_NOT_ENOUGH: 'There is not enough native token in your account.',
  NATIVE_TOKEN_SEND: 'Error is occurred to send native token.',

  TRADE: 'Trade is failed.',
  CANCEL: 'Cancel is failed.',
  UPLOAD_IMAGE: 'Failed to upload image.',

  AMOUNT: 'Amount error.',
  PRICE: 'Price error.',

  PROFILE_UPDATE: 'Failed to update profile.',
  PROFILE_NO_CHANGE: 'You did not edit anything.',
  TO_REJECT: 'There is an error to reject.',
  TO_CANCEL: 'There is an error to cancel.',
  TO_AGREE: 'There is an error to agree.',
  TO_EXECUTE: 'There is an error to execute.',

  EMPTY_INPUT: 'Empty input field:',
  EMPTY_NOTE: 'Note is empty. You have to fill it.',
  INVALID_TOADDRESS: 'To address is not valid.',
};
