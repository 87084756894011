import React, { useEffect } from 'react';
import Header from '../../components/Header';
import { ShareholdersView } from '../../components/Shareholders';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import store from '../../constants/store';

const ShareHolders = () => {
  const [, setStep] = store.useState(STORE_KEYS.id.step);

  useEffect(() => {
    setStep(STEP.CREATE_ENTERPRISE_INPUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header pageTitle={'Create'} />
      <ShareholdersView />
    </>
  );
};

export default ShareHolders;
