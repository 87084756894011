import {
  AddCircle,
  ArrowForward,
  KeyboardBackspace,
} from '@mui/icons-material';
import {
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminAdd, AdminsList } from '../../components/Admins';
import { HomeAppBar, ModalHead } from '../../components/AppBar';
import {
  BtnCancel,
  BtnOption,
  Transition,
  WEModal,
} from '../../components/Dialog';
import Header from '../../components/Header';
import ScrollToTop from '../../components/ScrollToTop';
import {
  ShareholderAdd,
  ShareholdersList,
} from '../../components/Shareholders';
import {
  WEToggleButton,
  WEToggleButtonGroup,
} from '../../components/ToggleButton/WEToggleButton';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import { useAutoConnect } from '../../utils/AutoConnect';

const Members = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { account } = useAutoConnect();
  const state = location.state;
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [enterprise, setEnterprise] = useState(enterprises.tempEnterprise);
  const { wepID, weBack } = state;
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);

  const [category, setCategory] = useState('shareholders');
  const [newOpen, setNewOpen] = useState(false);

  const backHandler = () => {
    switch (stepState) {
      case STEP.DASHBOARD_MEMBERS:
        navigation(`/wepID/dashboard`, { state: { wepID, weBack } });
      // eslint-disable-next-line no-fallthrough
      case STEP.DASHBOARD_MEMBERS_ADMIN_EDIT:
        setStep(STEP.DASHBOARD_MEMBERS);
        break;
      case STEP.DASHBOARD_MEMBERS_SHAREHOLDER_EDIT:
        setStep(STEP.DASHBOARD_MEMBERS);
        break;
      case STEP.DASHBOARD_MEMBERS_ADMIN_ADD:
        setStep(STEP.DASHBOARD_MEMBERS);
        break;
      default:
        setStep(STEP.DASHBOARD_MEMBERS);
        break;
    }
  };

  const isAdmin = useMemo(() => {
    const { admins } = enterprise;
    let isCurrentUserAdmin = false;

    if (admins && admins.length > 0) {
      admins.forEach((admin) => {
        if (admin.walletAddr.toUpperCase() === account.toUpperCase()) {
          isCurrentUserAdmin = true;
        }
      });
    }

    return isCurrentUserAdmin;
  }, [enterprise, account]);

  const handleChange = (event, value) => {
    if (value !== null) {
      setCategory(value);
    }
  };

  const newOpenClose = () => {
    setNewOpen(false);
  };

  const adminHandler = () => {
    setNewOpen(false);
    setStep(STEP.DASHBOARD_MEMBERS_ADMIN_ADD);
  };

  const shareholderHandler = () => {
    setNewOpen(false);
    setStep(STEP.DASHBOARD_MEMBERS_SHAREHOLDER_ADD);
  };

  useEffect(
    () => {
      if (stepState === STEP.DASHBOARD_MEMBERS) {
        localStorage.setItem(
          'enterprise',
          JSON.stringify(enterprises.tempEnterprise)
        );
      } else {
        setStep(STEP.DASHBOARD_MEMBERS);
        const twe = JSON.parse(localStorage.getItem('enterprise'));
        setEnterprise(twe);
        updateEnterprises((prev) => {
          prev.tempEnterprise = twe;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <ScrollToTop />
      <Header pageTitle={'Members'} />
      <HomeAppBar position="absolute" />
      <Box sx={{ bgcolor: 'white', minHeight: 'calc(100vh - 66px)' }}>
        <ModalHead
          title={enterprise?.info?.name}
          close={backHandler}
          theme="light"
          color="#FFDB0B"
        />
        <Box sx={{ px: 3, py: 3.3 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton
              onClick={() => {
                backHandler();
              }}
            >
              <KeyboardBackspace color="primary" sx={{ fontSize: 30 }} />
            </IconButton>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                marginRight:
                  stepState === STEP.DASHBOARD_MEMBERS ? '0px' : '46px',
              }}
            >
              <Typography
                sx={{ fontSize: '16px', fontWeight: '600', margin: '0 auto' }}
              >
                {stepState === STEP.DASHBOARD_MEMBERS && 'Members'}
                {stepState === STEP.DASHBOARD_MEMBERS_ADMIN_ADD && 'Add Admin'}
                {stepState === STEP.DASHBOARD_MEMBERS_ADMIN_EDIT &&
                  'Replace Admin'}
                {stepState === STEP.DASHBOARD_MEMBERS_SHAREHOLDER_ADD &&
                  'Add Shareholder'}
                {stepState === STEP.DASHBOARD_MEMBERS_SHAREHOLDER_EDIT &&
                  'Replace Shareholder'}
              </Typography>
            </Box>
            {stepState === STEP.DASHBOARD_MEMBERS && (
              <IconButton
                onClick={() => {
                  setNewOpen(true);
                }}
              >
                <AddCircle color="primary" sx={{ fontSize: 30 }} />
              </IconButton>
            )}
          </Stack>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          {stepState === STEP.DASHBOARD_MEMBERS && (
            <>
              <Container sx={{ display: 'flex', position: 'relative', py: 2 }}>
                <WEToggleButtonGroup
                  fullWidth
                  value={category}
                  exclusive
                  onChange={handleChange}
                  size="medium"
                >
                  <WEToggleButton value="shareholders">
                    Shareholders
                  </WEToggleButton>
                  <WEToggleButton value="admins" disabled={!isAdmin}>
                    Admins
                  </WEToggleButton>
                </WEToggleButtonGroup>
              </Container>
              <Grid
                container
                component="main"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  px: 1,
                  py: 0,
                  m: 0,
                }}
              >
                {category === 'shareholders' && <ShareholdersList />}
                {category === 'admins' && <AdminsList />}
              </Grid>
              <WEModal
                open={newOpen}
                onClose={newOpenClose}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="xl"
              >
                <DialogTitle id="alert-dialog-title">
                  <ModalHead
                    title={'Add member'}
                    customClose={() => newOpenClose()}
                    color="#FFDB0B"
                    theme="light"
                  />
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <BtnOption onClick={adminHandler}>
                    <span>Propose Admin</span>
                    <ArrowForward htmlColor="#BCC0C4" />
                  </BtnOption>
                  <BtnOption onClick={shareholderHandler}>
                    <span>Propose Shareholder</span>
                    <ArrowForward htmlColor="#BCC0C4" />
                  </BtnOption>
                </DialogContent>
                <Divider />
                <DialogActions>
                  <BtnCancel onClick={newOpenClose}>Cancel</BtnCancel>
                </DialogActions>
              </WEModal>
            </>
          )}
          {(stepState === STEP.DASHBOARD_MEMBERS_SHAREHOLDER_EDIT ||
            stepState === STEP.DASHBOARD_MEMBERS_SHAREHOLDER_ADD) && (
            <ShareholderAdd />
          )}
          {(stepState === STEP.DASHBOARD_MEMBERS_ADMIN_EDIT ||
            stepState === STEP.DASHBOARD_MEMBERS_ADMIN_ADD) && <AdminAdd />}
        </Box>
      </Box>
    </>
  );
};

export default Members;
