import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import { useWeb3React } from '@web3-react/core';
import { useLocation, useNavigate } from 'react-router-dom';
import EnterpriseABI from '../../constants/abi/enterprise.json';
import EnterpriseDevABI from '../../constants/abi/enterprise_dev.json';
import store from '../../constants/store';

import {
  EmptyOrder,
  EmptyTransaction,
  OrderCategory,
  OrderStatus,
  STORE_KEYS,
  TXTYPE,
} from '../../constants/store/constant';

import { useMemo } from 'react';
import { useWeb3 } from '../../hook/web3';
import { convertMaticToUSD, getEtherFromWei } from '../../utils/unit';
import { HomeAppBar, ModalHead } from '../AppBar';
import { WELoadingButton } from '../Button';
import { Input, InputLabel } from '../Input';
import { useNotification } from '../Notification/hook';
import {
  WEToggleButton,
  WEToggleButtonGroup,
} from '../ToggleButton/WEToggleButton';
import OrderBook from './OrderBook';

const CATEGORIES = {
  BUY: OrderCategory.BUY,
  SELL: OrderCategory.SELL,
  OrderList: 'Orders',
};

const NewOrder = ({ weBack }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log(state);

  const web3 = useWeb3();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [enterprise, setEnterprise] = useState(enterprises.tempEnterprise);
  const [isLoaded, setLoaded] = useState(false);
  const { account } = useWeb3React();
  const { displaySuccess, displayError } = useNotification();

  // price in USD (token to USD)
  const [price, setPrice] = useState('');
  const [amount, setAmount] = useState('');
  const [usdValue, setUsdValue] = useState('0');
  const [balance, setBalance] = useState(
    getEtherFromWei(enterprises.tempEnterprise.numOfShare)
  );

  const [category, setCategory] = useState();
  const [buttonStr, setButtonStr] = useState('Submit Order');
  const [orderbookCategory, setOrderBookCategory] = useState('BUY');

  const handleChange = (event, value) => {
    if (value !== null) {
      setCategory(value);
    }
  };

  const isMember = useMemo(() => {
    const { admins, shareholders } = enterprise;
    let isCurrentUserMeber = false;

    admins.forEach((admin) => {
      if (admin.walletAddr.toUpperCase() === account.toUpperCase()) {
        isCurrentUserMeber = true;
      }
    });

    shareholders.forEach((shareholder) => {
      if (shareholder.walletAddr.toUpperCase() === account.toUpperCase()) {
        isCurrentUserMeber = true;
      }
    });

    if (!isCurrentUserMeber) {
      setCategory(CATEGORIES.OrderList);
    }

    return isCurrentUserMeber;
  }, [enterprise, account]);

  useEffect(() => {
    if (state !== null) {
      setCategory(state.tabId);
    } else {
      setCategory(CATEGORIES.BUY);
    }
  }, [state]);

  useEffect(
    () => {
      setLoaded(false);
      if (enterprises?.enterprises?.length > 0) {
        const res = JSON.parse(localStorage.getItem('enterprise'));
        setEnterprise(res);
        try {
          setBalance(getEtherFromWei(res.numOfShare));
        } catch (e) {
          console.error(e);
        }

        if (!isLoaded) {
          updateEnterprises((prev) => {
            prev = res;
            setLoaded(true);
            return prev;
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enterprises]
  );

  const handleInputChange = (type) => (evt) => {
    let value = evt.currentTarget.value;
    let regExp = /[0-9]+/;
    switch (type) {
      case 'price':
        regExp = /[0-9]*([.])?[0-9]*/;
        if (regExp.exec(value)?.[0] === value || value === '') {
          if (/[.][0-9]*/.exec(value)?.[0] === value) value = `0${value}`;
          setPrice(value);
        }
        break;
      case 'amount':
        regExp = /[0-9]+/;
        if (regExp.exec(value)?.[0] === value || value === '') {
          if (/[0][0-9]+[.]?[0-9]*/.exec(value)?.[0] === value)
            value = value.slice(1, value.length);

          setAmount(value);
        }
        break;
      default:
        break;
    }
  };

  const closeHandler = () => {
    navigation(`/wepID/dashboard`, { state: { weBack } });
  };

  const newOrderHandler = async () => {
    if (category !== CATEGORIES.BUY && category !== CATEGORIES.SELL) return;
    if (buttonStr !== 'Submit Order') return;

    try {
      const enterpriseHandler = new web3.eth.Contract(
        process.env.REACT_APP_ENV === 'prod' ? EnterpriseABI : EnterpriseDevABI,
        enterprise.address
      );

      if (!(parseFloat(amount) > 0)) {
        return displayError({
          message: 'Amount error',
          timeout: 5000,
        });
      }

      if (!(parseFloat(price) > 0)) {
        return displayError({
          message: 'Price error',
          timeout: 5000,
        });
      }

      let priceAmount = parseFloat(amount) * parseFloat(price);
      priceAmount = web3.utils.toWei(priceAmount.toString());
      const _amount = web3.utils.toWei(amount);
      let _price = web3.utils.toWei(price);

      if (category === CATEGORIES.BUY) {
        let _balance = await web3.eth.getBalance(account);
        if (parseFloat(priceAmount) > parseFloat(_balance)) {
          return displayError({
            message: 'There is not enough native token in your account',
            timeout: 5000,
          });
        }
        setButtonStr('Confirming');
        await enterpriseHandler.methods
          .createBuyOrder(_amount, priceAmount)
          .send({
            from: account,
            value: priceAmount,
            gasLimit: web3.eth.getBlock('latest').gasLimit,
          });

        setButtonStr('Submit Order');
      } else if (category === CATEGORIES.SELL) {
        if (!(parseFloat(amount) <= balance)) {
          return displayError({
            message: 'Amount exceed balance error',
            timeout: 5000,
          });
        }

        setButtonStr('Approving');

        await enterpriseHandler.methods
          .approve(enterprise.address.toLowerCase(), _amount)
          .send({
            from: account,
            gasLimit: web3.eth.getBlock('latest').gasLimit,
          });
        displaySuccess({
          message: 'Approve success',
          timeout: 5000,
        });

        setButtonStr('Confirming');
        await enterpriseHandler.methods
          .createSellOrder(_amount, priceAmount)
          .send({
            from: account,
            gasLimit: web3.eth.getBlock('latest').gasLimit,
          });

        setButtonStr('Submit Order');
      }

      const type = category;

      const order = EmptyOrder();
      order.id = Date.now();
      order.type = type;
      order.amount = getEtherFromWei(_amount);
      order.price = getEtherFromWei(_price);
      order.date = new Date();
      order.owner_address = account || '';
      order.created_at = Date.now();
      order.status = OrderStatus.ACTIVE;
      order.enterprise_address = enterprise.address;
      order.order_id = enterprise.orders.length;

      if (type === CATEGORIES.SELL) {
        let newTrx = EmptyTransaction();
        newTrx.amount = getEtherFromWei(_amount);
        newTrx.asset = enterprise.info.tokenName;
        newTrx.category = '';
        newTrx.created_at = Date.now();
        newTrx.enterprise_address = enterprise.address;
        newTrx.from = account.toUpperCase();
        newTrx.to = enterprise.address;
        newTrx.id = Date.now();
        newTrx.note = '';
        newTrx.token = enterprise.address;
        newTrx.type = TXTYPE.SHARE_OUT;
        updateEnterprises((prev) => {
          const index = prev.enterprises.findIndex(
            (_enterprise) => _enterprise.address === enterprise.address
          );
          prev.enterprises[index].transactions = [
            ...prev.tempEnterprise.transactions,
            newTrx,
          ];
          prev.tempEnterprise.transactions = [
            ...prev.tempEnterprise.transactions,
            newTrx,
          ];
          prev.enterprises[index].numOfShare -= parseInt(_amount);
          prev.tempEnterprise.numOfShare -= parseInt(_amount);
          const idx = prev.tempEnterprise.shareholders.findIndex(
            (sh) =>
              (sh.walletAddr || '').toUpperCase() ===
              (account || '').toUpperCase()
          );
          if (idx >= 0) {
            if (_amount >= enterprise.shareholders[idx].numOfShare) {
              let temp = [...enterprise.shareholders];
              temp.splice(idx, 1);
              prev.tempEnterprise.shareholders = temp;
              prev.tempEnterprise.isShareholder = false;
              prev.tempEnterprise.info.memNum = temp.length;
            } else {
              prev.tempEnterprise.shareholders[idx].numOfShare -=
                parseInt(_amount);
            }
          }
          return prev;
        });
      }

      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.address === enterprise.address
        );
        prev.enterprises[index].orders = [order, ...prev.tempEnterprise.orders];
        prev.tempEnterprise.orders = [order, ...prev.tempEnterprise.orders];
        localStorage.setItem('enterprise', JSON.stringify(prev.tempEnterprise));
        return prev;
      });

      setPrice('');
      setAmount('');
      // updateEnterprise(enterprise, account, updateEnterprises)
      if (category === CATEGORIES.BUY) setOrderBookCategory('BUY');
      else if (category === CATEGORIES.SELL) setOrderBookCategory('SELL');
      setCategory(CATEGORIES.OrderList);
      displaySuccess({ message: 'Trade submitted!', timeout: 5000 });
    } catch (e) {
      setButtonStr('Submit Order');
      console.log(e); //eslint-disable-line no-console
      displayError({ message: 'Failed to create order', timeout: 5000 });
    }
  };

  useEffect(() => {
    if (amount === '' || price === '') return;
    convertMaticToUSD(Number(amount) * Number(price)).then(setUsdValue);
  }, [amount, price]);

  return (
    <>
      <HomeAppBar position="absolute" />
      <Box
        component={'main'}
        sx={{ bgcolor: '#E5E5E5', minHeight: 'calc(100vh - 66px)' }}
      >
        <ModalHead
          color="#FF6142"
          close={closeHandler}
          title={'Trade Shares'}
          theme="dark"
        />
        <Box sx={{ px: '27px' }}>
          {isMember && (
            <Container sx={{ position: 'relative', py: 1, px: 0, pt: 3 }}>
              <WEToggleButtonGroup
                fullWidth
                value={category}
                exclusive
                onChange={handleChange}
                size="medium"
              >
                <WEToggleButton value={CATEGORIES.BUY}>Buy</WEToggleButton>
                <WEToggleButton value={CATEGORIES.SELL}>Sell</WEToggleButton>
                <WEToggleButton value={CATEGORIES.OrderList}>
                  Order Book
                </WEToggleButton>
              </WEToggleButtonGroup>
            </Container>
          )}
          {(category === CATEGORIES.BUY || category === CATEGORIES.SELL) && (
            <>
              <Grid item xs={12} mt={2}>
                <InputLabel
                  shrink
                  htmlFor="amount"
                  sx={{ color: '#6F7287', lineHeight: 17 }}
                >
                  Amount of Shares to{' '}
                  {category === CATEGORIES.BUY ? 'BUY' : 'SELL'}
                </InputLabel>
                <Input
                  id="amount"
                  fullWidth
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  onChange={handleInputChange('amount')}
                  value={amount}
                  placeholder="0"
                  sx={{
                    '& input': {
                      py: 1,
                      my: 1.2,
                      fontSize: '24px !important',
                      color: '#A5A5A5',
                      bgColor: '#ffffff !important',
                      borderRadius: '6px !important',
                      borderColor: '#E8E8EA !important',
                    },
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: '500',
                    fontSize: '14px',
                    color: '#28282B',
                  }}
                >
                  {`My Shares: ${
                    category === CATEGORIES.SELL
                      ? balance - Number(amount)
                      : balance
                  } ${enterprise?.info.tokenName}`}
                </Typography>
              </Grid>
              <Grid item xs={12} mt={3.25}>
                <InputLabel
                  shrink
                  htmlFor="amount"
                  sx={{ color: '#6F7287', lineHeight: 17 }}
                >
                  Price in Matic
                </InputLabel>
                <Input
                  id="amount"
                  fullWidth
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  onChange={handleInputChange('price')}
                  value={price}
                  placeholder="0.00"
                  sx={{
                    '& input': {
                      py: 1,
                      my: 1.2,
                      fontSize: '24px !important',
                      color: '#A5A5A5',
                      bgcolor: '#ffffff',
                      borderRadius: '6px !important',
                      borderColor: '#E8E8EA !important',
                    },
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: '500',
                    fontSize: '14px',
                    color: '#28282B',
                  }}
                >
                  {category === CATEGORIES.BUY
                    ? 'Purchase Amount'
                    : 'Total to Receive'}
                  : {Number(amount) * Number(price)} MATIC{` ($${usdValue})`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  mt: 7,
                  mb: 3,
                }}
              >
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                  <WELoadingButton
                    loading={buttonStr !== 'Submit Order'}
                    onClick={newOrderHandler}
                    type="primary"
                    width={320}
                    rounded="xl"
                    loadingPosition="center"
                    loadingIndicator={
                      <CircularProgress color="default" size={20} />
                    }
                  >
                    {buttonStr}
                  </WELoadingButton>
                </Box>
              </Grid>
            </>
          )}
          {category === CATEGORIES.OrderList && (
            <OrderBook category={orderbookCategory} />
          )}
        </Box>
      </Box>
    </>
  );
};

NewOrder.propTypes = {
  wepID: PropTypes.string,
  weBack: PropTypes.string,
};

export default NewOrder;
