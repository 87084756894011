import { Close } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const CreateEnterpriseAppBar = ({
  title,
  close,
  back,
  helpEnabled,
  bgColor = '#FFFFFF',
  color = '#FFFFFF',
}) => {
  const location = useLocation();

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: bgColor,
        boxShadow: 'none',
        borderBottom: '2px solid #E3E8EB',
      }}
    >
      <Toolbar sx={{ px: 3, color: color, position: 'relative' }}>
        <Typography
          variant="h2"
          component="div"
          sx={{
            flexGrow: 1,
            py: '46px',
            color: color,
          }}
        >
          {title}
        </Typography>
        {helpEnabled && (
          <IconButton>
            <HelpIcon />
          </IconButton>
        )}
        {close && (
          <IconButton
            size="large"
            edge="end"
            color="primary"
            aria-label="menu"
            onClick={
              location.pathname === '/create'
                ? () => {
                    back();
                  }
                : () => {
                    close();
                  }
            }
            sx={{
              p: 0,
              position: 'absolute',
              top: '12px',
              right: '16px',
            }}
          >
            <Close fontSize="medium" htmlColor={color} />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

CreateEnterpriseAppBar.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func,
  back: PropTypes.func,
  helpEnabled: PropTypes.bool,
  bgColor: PropTypes.string,
  color: PropTypes.string,
};

CreateEnterpriseAppBar.defaultProps = {
  title: null,
  close: null,
  back: null,
  helpEnabled: false,
  bgColor: '#FFFFFF',
  color: '#FFFFFF',
};

export default CreateEnterpriseAppBar;
