import React, { useState } from 'react';
import { ContentCopy, Done } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';

const InfoAddr = ({ label, value, addr }) => {
  const [copyed, setCopyed] = useState(false);

  return (
    <>
      <Stack direction={'row'} gap={2}>
        <span style={{ textAlign: 'left', color: '#6F7287' }}>{label}</span>
        <span
          style={{
            justifyContent: 'flex-end',
            display: 'flex',
            textAlign: 'left',
            maxWidth: '13ch',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#FF6142',
          }}
        >
          {value}
          <IconButton
            onClick={() => {
              copy(addr);
              setCopyed(true);
            }}
            sx={{
              zIndex: '1000',
              float: 'right',
              width: '19px',
              height: '19px',
              marginLeft: '9px',
              color: '#FF6142',
            }}
          >
            {!copyed ? (
              <ContentCopy sx={{ width: '19px', height: '19px' }} />
            ) : (
              <Done
                sx={{ width: '19px', height: '19px' }}
                htmlColor="#FF6142"
              />
            )}
          </IconButton>
        </span>
      </Stack>
    </>
  );
};

InfoAddr.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  addr: PropTypes.string,
};

export default InfoAddr;
