import React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import { Stack } from '@mui/system';
import HelpIcon from '@mui/icons-material/Help';
import { shortAddress } from '../../utils/shortAddress';
import { ModalHead } from '../AppBar';
import BtnCancel from './BtnCancel';
import { WEModal } from './DetailDialog';
import Info from './Info';
import InfoAddr from './InfoAddr';
import Transition from './Transition';
import { ToolTip } from '../Dialog';
import PropTypes from 'prop-types';

const AdminDetailModal = ({
  open,
  onClose,
  isAdmin,
  email,
  phone,
  walletAddr,
  account,
  replace,
  remove,
}) => {
  return (
    <WEModal
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle sx={{ p: '0px !important' }} id="alert-dialog-title">
        <ModalHead
          title={'Admin'}
          customClose={() => onClose()}
          color="#FFDB0B"
          theme="light"
        />
        <Box
          display={'flex'}
          justifyContent={'center'}
          width={'100%'}
          sx={{
            position: 'absolute',
            top: '37px',
            zIndex: 100,
          }}
        >
          <Box ml={10}>
            <ToolTip
              title={'Admins can send money and provide business updates'}
            >
              <IconButton size="small" color="primary">
                <HelpIcon fontSize="small" color="primary" />
              </IconButton>
            </ToolTip>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: '0 16px !important' }}>
        <Stack direction={'column'} gap={7}>
          <Info
            label="Email"
            labelColor="grayLabel"
            value={email}
            type="email"
          />
          <Info
            label="Phone"
            labelColor="grayLabel"
            value={phone}
            type="phone"
          />
          <InfoAddr
            label="Wallet"
            labelColor="grayLabel"
            value={shortAddress(walletAddr)}
            addr={walletAddr}
          />
        </Stack>
        {isAdmin && (
          <DialogActions>
            <BtnCancel
              sx={{
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '24px',
                border: '1px solid #28282B',
              }}
              onClick={() => replace()}
            >
              Replace
            </BtnCancel>
            <BtnCancel
              sx={{
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '24px',
                border: '1px solid #28282B',
              }}
              onClick={() => remove()}
            >
              {account?.toUpperCase() === walletAddr?.toUpperCase()
                ? 'Resign'
                : 'Remove'}
            </BtnCancel>
          </DialogActions>
        )}
      </DialogContent>
    </WEModal>
  );
};

AdminDetailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isAdmin: PropTypes.bool,
  email: PropTypes.string,
  phone: PropTypes.string,
  walletAddr: PropTypes.string,
  account: PropTypes.string,
  replace: PropTypes.func,
  remove: PropTypes.func,
};

AdminDetailModal.defaultProps = {
  open: null,
  onClose: null,
  isAdmin: false,
  email: null,
  phone: null,
  walletAddr: null,
  replace: null,
  remove: null,
  account: null,
};

export default AdminDetailModal;
