import { createTheme } from '@mui/material/styles';

// const neutral = {
//   100: '#F3F4F6',
//   200: '#E5E7EB',
//   300: '#D1D5DB',
//   400: '#9CA3AF',
//   500: '#6B7280',
//   600: '#4B5563',
//   700: '#374151',
//   800: '#1F2937',
//   900: '#111827'
// };

export const theme = createTheme({
  palette: {
    primary: {
      light: '#39393C',
      main: '#28282B',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#FCDF39',
      main: '#FFDB0B',
      dark: '#E8C605',
      contrastText: '#000000',
    },
    success: {
      light: '#7AE57A',
      main: '#38CE38',
      dark: '#1EB61E',
      contrastText: '#FFFFFF',
    },
    warning: {
      light: '#FE7B61',
      main: '#FF6142',
      dark: '#FF4C28',
      contrastText: '#FFFFFF',
    },
    error: {
      light: '#FF3939',
      main: '#FF0000',
      dark: '#D00505',
      contrastText: '#FFFFFF',
    },
    default: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#28282B',
      secondary: '#6F7287',
    },
    red: {
      main: '#eb0014',
    },
    active: {
      light: '',
      main: '#FFFFFF',
      dark: '#FFFFFF',
      contrastText: '#000000',
    },
    // action: {
    //   active: '#39393C',
    //   focus: 'rgba(55, 65, 81, 0.12)',
    //   hover: '#39393C',
    //   selected: 'rgba(55, 65, 81, 0.08)',
    //   disabledBackground: 'rgba(55, 65, 81, 0.12)',
    //   disabled: 'rgba(55, 65, 81, 0.26)'
    // },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat',
          color: '#ffffff',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19.5px',
          textTransform: 'none',
          paddingTop: '15px',
          paddingBottom: '15px',
          backgroundColor: '#3D61B0',
          ':hover': {
            backgroundColor: '#2F4B87',
          },
        },
        outlined: {
          color: '#3D61B0',
          backgroundColor: '#ffffff',
          border: 'solid 1px #3D61B0',
          ':hover': {
            borderColor: '#2F4B87',
            backgroundColor: '#ffffff',
          },
        },
      },
      defaultProps: {
        variant: 'contained',
        size: 'medium',
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h3',
          subtitle2: 'h3',
          body1: 'p',
          body2: 'p',
          primary: 'p',
          secondary: 'p',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#ffffff',
          color: '#000000',
          width: '55px',
          height: '55px',
          border: '1px solid #E3E8EB',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#FAFBFC',
          borderColor: '#FFFFFFFF',
          border: '0px',
        },
        // backgroundColor: "#FAFBFC"
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#4B4749',
          // transform: "translate(0, -1.5px) scale(1)",
          whiteSpace: 'normal',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        itemType: 'select',
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // color: "#4B4749"
        },
      },
    },
  },
  typography: {
    h1: {
      color: '#FF6142',
      fontFamily: 'Anurati',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '26.4px',
      textTransform: 'uppercase',
    },
    // h2: {
    //   color: "#241F21",
    //   fontFamily: 'Montserrat',
    //   fontWeight: 600,
    //   fontSize: "18px",
    //   lineHeight: "22px",
    //   textAlign: "center",
    // },
    h2: {
      color: '#241F21',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24.38px',
      textAlign: 'center',
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '18.29px',
    },
    h4: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '20px',
      color: '#4B4749',
    },
    h5: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '17px',
    },
    h6: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#28282B',
    },
    body1: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.63px',
      color: '#241F21',
    },
    body2: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#4B4749',
    },
    primary: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#28282B',
    },
    secondary: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '17px',
      color: '#A5A5A5',
    },
  },
});
