import React from 'react';
import store from '../../constants/store';
import { STORE_KEYS } from '../../constants/store/constant';
import { useAutoConnect } from '../../utils/AutoConnect';
import { WEMenu, WEMenuItem } from '../Menu/WEMenu';
import PropTypes from 'prop-types';

const UserMenu = ({ anchorEl, handleClose, handleNav }) => {
  const { account } = useAutoConnect();
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);

  return (
    <WEMenu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {!account && <WEMenuItem onClick={handleNav('login')}>Login</WEMenuItem>}
      {account && enterprises.registeredUser && (
        <WEMenuItem onClick={handleNav('dashboard')}>Dashboard</WEMenuItem>
      )}
      {account && enterprises.registeredUser && (
        <WEMenuItem onClick={handleNav('setting')}>Setting</WEMenuItem>
      )}
      {account && (
        <WEMenuItem onClick={handleNav('disconnect')}>Disconnect</WEMenuItem>
      )}
    </WEMenu>
  );
};

UserMenu.propTypes = {
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func,
  handleNav: PropTypes.func,
};

UserMenu.defaultProps = {
  anchorEl: null,
  handleClose: null,
  handleNav: null,
};

export default UserMenu;
