import {
  getBusinessUpdatesForEnterprise,
  getForumsForEnterprise,
  getOrdersForEnterprise,
  getProposalsForEnterprise,
  getTransactionsForEnterprise,
} from '../apis';
import { EmptyProposal, TXTYPE } from '../constants/store/constant';
import { calculateTokenPrice } from '../utils/price';
import { getEtherFromWei } from './unit';

export const updateEnterprise = async (
  enterprise,
  account,
  updateEnterprises
) => {
  Promise.all([
    getProposalsForEnterprise(enterprise.address),
    getBusinessUpdatesForEnterprise(enterprise.address),
    getTransactionsForEnterprise(enterprise.address),
    getOrdersForEnterprise(enterprise.address),
    getForumsForEnterprise(enterprise.address),
  ])
    .then((res) => {
      let tProposals = [];
      let tBusinessUpdates = [];
      let ttransactions = [];
      let torders = [];
      let tforums = [];
      if (res[0]?.data?.exists) {
        try {
          const data = res[0].data.data;
          for (let proposal of data) {
            let votesYes = 0;
            let votesNo = 0;
            let isApproved = false;
            let votes = {};
            if (proposal?.votes) {
              try {
                votes = JSON.parse(proposal?.votes);
                const votesKey = Object.keys(votes);
                for (let key of votesKey) {
                  if (key.toUpperCase() === account.toUpperCase()) {
                    isApproved = true;
                  }
                  if (votes[key]?.approve === 'NO') {
                    votesNo++;
                  } else if (votes[key]?.approve === 'YES') {
                    votesYes++;
                  }
                }
              } catch (e) {
                console.error(e); //eslint-disable-line no-console
              }
            }
            const tProposal = EmptyProposal({
              idx: proposal?.proposalIndex,
              id: proposal?.id,
              amount: proposal?.amount,
              name: 'Join World Enterprise Request',
              type: proposal?.pType,
              votesYes: votesYes,
              votesNo: votesNo,
              votes: votes,
              walletAddr: proposal?.proposer,
              isApproved: isApproved,
              commentUrl: proposal?.commentUrl,
              status: proposal?.status,
              startTime: Number(proposal?.startTime),
              endTime: Number(proposal?.endTime),
              comments: proposal?.comments,
              logoImg: proposal?.logoImg,
              candidate: proposal?.candidate,
              admin: proposal?.admin,
              enterpriseAddr: enterprise.address,
            });
            tProposals.push(tProposal);
          }
        } catch (e) {
          console.error(e); //eslint-disable-line no-console
        }
      }

      if (res[1]?.data?.exists) {
        try {
          const data = res[1].data.data;
          for (let businessUpdate of data) {
            tBusinessUpdates.push(businessUpdate);
          }
          tBusinessUpdates = tBusinessUpdates.reverse();
        } catch (e) {
          console.error(e); //eslint-disable-line no-console
        }
      }

      if (res[2]?.data?.exists) {
        try {
          const data = res[2].data.data;
          for (let transaction of data) {
            transaction.created_at = Number(transaction.created_at) * 10 ** 3;
            transaction.category = transaction.category
              ? transaction.category
              : '';
            transaction.asset = transaction.asset ? transaction.asset : '';
            transaction.note = transaction.note ? transaction.note : '';
            if (
              transaction.token.toUpperCase() ===
              transaction.enterprise_address.toUpperCase()
            ) {
              transaction.type = 'Share Transfer';
              transaction.asset = enterprise.info.tokenName;
            } else {
              if (
                transaction.from.toUpperCase() ===
                transaction.enterprise_address.toUpperCase()
              ) {
                transaction.type = TXTYPE.MONEY_OUT;
              } else {
                transaction.type = TXTYPE.MONEY_IN;
              }
            }
            if (
              transaction.type !== 'Share Transfer' ||
              transaction.amount.length >= 6
            )
              transaction.amount = getEtherFromWei(transaction.amount);
            ttransactions.push(transaction);
          }
        } catch (e) {
          console.error(e); //eslint-disable-line no-console
        }
      }

      if (res[3]?.data?.exists) {
        try {
          const data = res[3].data.data;
          for (let order of data) {
            let torder = { ...order };
            torder.amount = parseFloat(getEtherFromWei(torder.amount));
            torder.price = parseFloat(
              getEtherFromWei(calculateTokenPrice(torder.price, torder.amount))
            );
            torders.push(torder);
          }
        } catch (error) {
          console.log('Error', error); //eslint-disable-line no-console
        }
      }

      if (res[4]?.data?.exists) {
        try {
          const data = res[4].data.data;
          for (let forum of data) {
            tforums.push(forum);
          }
          tforums = tforums.reverse();
        } catch (e) {
          console.error(e); //eslint-disable-line no-console
        }
      }

      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.address === enterprise.address
        );
        prev.enterprises[index].proposals = tProposals;
        prev.tempEnterprise.proposals = tProposals;
        prev.enterprises[index].businessUpdates = tBusinessUpdates;
        prev.tempEnterprise.businessUpdates = tBusinessUpdates;
        prev.enterprises[index].transactions = ttransactions;
        prev.tempEnterprise.transactions = ttransactions;
        prev.enterprises[index].proposalFetched = true;
        prev.tempEnterprise.proposalFetched = true;
        prev.enterprises[index].orders = torders;
        prev.tempEnterprise.orders = torders;
        prev.enterprises[index].forums = tforums;
        prev.tempEnterprise.forums = tforums;
        const res = localStorage.getItem('enterprise');
        let enterpriseData = JSON.parse(res);
        enterpriseData.proposals = tProposals;
        enterpriseData.businessUpdates = tBusinessUpdates;
        enterpriseData.transactions = ttransactions;
        enterpriseData.proposalFetched = true;
        enterpriseData.orders = torders;
        enterpriseData.forums = tforums;
        localStorage.setItem('enterprise', JSON.stringify(enterpriseData));

        return prev;
      });

      // setIsLoading(false);
    })
    .catch((reason) => {
      console.error(
        //eslint-disable-line no-console
        `Failed to load proposals for account: ${enterprise.address}`,
        reason
      );
      // setIsLoading(false);
    });
};
