import { Box, ListItemText, Typography } from '@mui/material';
import md5 from 'md5';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import store from '../../constants/store';
import { STORE_KEYS } from '../../constants/store/constant';
import { BtnCancel, ForumModal } from '../Dialog';

const ForumList = () => {
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const forums = enterprises.tempEnterprise.forums;
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showComments, setShowComments] = useState([]);

  const [updateIdx, setUpdateIdx] = useState(-1);
  const [updateId, setUpdateId] = useState(-1);

  const onReply = (id, idx) => {
    setUpdateIdx(idx);
    setUpdateId(id);
    setShowCommentModal(true);
  };

  const onShowComments = (i) => {
    let temp = [...showComments];
    temp[i] = !showComments[i];
    setShowComments(temp);
  };
  // const editHandler = (id) => {
  //   updateEnterprises((prev) => {
  //     prev.tempEnterprise.updateableForumId = id;
  //     return prev;
  //   });
  //   setStep(STEP.DASHBOARD_BSUPDATE_EDIT);
  // };

  // const deleteHandler = (id, idx) => {
  //   deleteforum(id)
  //     .then(() => {
  //       //eslint-disable-line no-unused-vars
  //       updateEnterprises((prev) => {
  //         prev.tempEnterprise.forums.splice(idx, 1);
  //         return prev;
  //       });
  //     })
  //     .catch(
  //       (error) =>
  //         console.log(`Failed to delete business update ${id} ${error}`) //eslint-disable-line no-console
  //     );
  // };
  useEffect(() => {
    if (enterprises.tempEnterprise.admins?.length === 0) {
      const getEnterprise = JSON.parse(localStorage.getItem('enterprise'));
      updateEnterprises((prev) => {
        prev.tempEnterprise = getEnterprise;
        return prev;
      });
    } else {
      localStorage.setItem(
        'enterprise',
        JSON.stringify(enterprises.tempEnterprise)
      );
    }

    //eslint-disable-next-line
  }, [enterprises.tempEnterprise.forums]);

  return (
    <>
      {forums?.map((forum, i) => {
        return (
          <React.Fragment key={i}>
            <Box
              key={i}
              component="div"
              sx={{
                background: '#F2F2F2',
                borderRadius: '8px',
                my: 1,
                py: 1,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  padding: 4,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <img
                      height={40}
                      src={
                        forum.logo
                          ? forum.logo
                          : `https://www.gravatar.com/avatar/${md5(
                              forum.creator
                            )}?s=${40}&d=identicon&r=PG`
                      }
                      alt={'logo'}
                    />
                  </Box>
                  <ListItemText
                    primary={forum.username ? forum.username : forum.creator}
                    secondary={new Date(
                      parseInt(forum.created_at) * 10 ** 3
                    ).toLocaleString('en-us', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                    primaryTypographyProps={{
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '15px',
                      lineHeight: '18px',
                      color: '#241F21',
                      textAlign: 'left',
                    }}
                    secondaryTypographyProps={{
                      mt: 1,
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '15px',
                      color: '#4B4749',
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    sx={{
                      ml: 1,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Typography
                    variant="secondary"
                    sx={{ fontWeight: 600, fontSize: '18px', color: '#28282B' }}
                  >
                    {forum.title}
                  </Typography>
                  <Typography
                    variant="secondary"
                    sx={{
                      fontWeight: 400,
                      mt: 1,
                      fontSize: '16px',
                      color: '#28282B',
                    }}
                  >
                    {forum.content}
                  </Typography>
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <BtnCancel
                      sx={{
                        py: 0,
                        px: 1,
                        height: 'auto',
                        borderRadius: 2,
                        fontSize: 12,
                        fontWeight: 500,
                        position: 'absolute',
                        right: 100,
                        bottom: 8,
                      }}
                      onClick={() => onShowComments(i)}
                    >
                      {showComments[i] ? 'Hidden Comments' : 'Show Comments'}
                    </BtnCancel>
                    <BtnCancel
                      sx={{
                        py: 0,
                        px: 1,
                        height: 'auto',
                        borderRadius: 2,
                        fontSize: 12,
                        fontWeight: 500,
                        position: 'absolute',
                        right: 24,
                        bottom: 8,
                      }}
                      onClick={() => onReply(forum.id, i)}
                    >
                      Reply
                    </BtnCancel>
                  </Box>
                </Box>
              </Box>
              {showComments[i] && (
                <Box>
                  {forum.comments?.map((comment) => (
                    <Comment
                      key={comment.id}
                      comment={comment}
                      onReply={onReply}
                      idx={i}
                      depth={0}
                    />
                  ))}
                </Box>
              )}

              <ForumModal
                idx={updateIdx}
                id={updateId}
                open={showCommentModal}
                closeHandler={() => setShowCommentModal(false)}
              />
            </Box>
          </React.Fragment>
        );
      })}
    </>
  );
};

ForumList.propTypes = {
  isUserDash: PropTypes.bool,
};

ForumList.defaultProps = {
  isUserDash: false,
};

const Comment = ({ comment, onReply, idx, depth }) => {
  return (
    <Box
      sx={{
        background: depth % 2 === 0 ? '#ffffff' : '#F2F2F2',
        my: 2,
        ml: 2,
        py: 1,
        borderRadius: '8px 0 0 8px',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <Box>
            {comment.logo && (
              <img height={40} src={comment.logo} alt={'logo'} />
            )}
          </Box>
          <ListItemText
            primary={comment.username ? comment.username : comment.creator}
            secondary={new Date(parseInt(comment.created_at)).toLocaleString(
              'en-us',
              {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }
            )}
            primaryTypographyProps={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '15px',
              lineHeight: '18px',
              color: '#241F21',
              textAlign: 'left',
            }}
            secondaryTypographyProps={{
              mt: 1,
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '15px',
              color: '#4B4749',
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            sx={{ ml: comment.logo ? 2 : 0 }}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Typography
            variant="secondary"
            sx={{ fontWeight: 400, mt: 1, fontSize: '16px', color: '#28282B' }}
          >
            {comment.content}
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <BtnCancel
            sx={{
              py: 0,
              px: 1,
              height: 'auto',
              borderRadius: 2,
              fontSize: 12,
              fontWeight: 500,
              position: 'absolute',
              right: 24,
              bottom: 8,
            }}
            onClick={() => onReply(comment.id, idx)}
          >
            Reply
          </BtnCancel>
        </Box>
      </Box>
      {comment?.comments?.map((cmt) => (
        <Comment
          key={cmt.id}
          comment={cmt}
          onReply={onReply}
          depth={depth + 1}
          idx={idx}
        />
      ))}
    </Box>
  );
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  onReply: PropTypes.any,
  depth: PropTypes.number,
  idx: PropTypes.number,
};

export default ForumList;
